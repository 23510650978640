import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
  ClipboardDocumentCheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  BuildingOffice2Icon,
  ExclamationTriangleIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'
import { Modal } from '../Modal'
import { Tooltip } from '../Tooltip'

// Images
import logo from '../../assets/images/logo.svg'

// Service
import { getClient } from '../../services/clients.service'
import { getLatestClientImport, getLatestUserImport } from '../../services/import.service'
import { logout } from '../../services/user.service'

// Stores
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'
import { RootStoreContext } from '../../stores/RootStore'

// Utils
import { UserStoreContext } from '../../stores/UserStore'
import { insert, joinClassNames } from '../../utils/helpers'

const NESTED_CLIENT_ROUTE = /^\/clients\/.*/

const NAVIGATION_OPTIONS = [
  { label: 'Clients', href: '/clients', icon: BuildingOffice2Icon },
  { label: 'Global Benchmarks', href: '/global-benchmarks', icon: ClipboardDocumentCheckIcon },
  { label: 'Users', href: '/users', icon: UserGroupIcon },
  // { label: 'Settings', href: '/settings', icon: AdjustmentsHorizontalIcon },
]

/**
 *
 * NavBar
 *
 */
const NavBar = observer(({ children }) => {
  // Context
  const location = useLocation()
  const { clearStore } = useContext(RootStoreContext)
  const { client, setClient } = useContext(ClientDashboardStoreContext)
  const { isAdminUser, isAuthenticated, isLimitedUser, user, getUpdatedUser } =
    useContext(UserStoreContext)

  // State
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [latestClientImport, setLatestClientImport] = useState(null)
  const [latestUserImport, setLatestUserImport] = useState(null)
  const [reviewImport, setReviewImport] = useState(null)

  useEffect(() => {
    if (isAuthenticated) {
      const getRecentData = async () => {
        const [, clientImport, userImport] = await Promise.all([
          getUpdatedUser(),
          getLatestClientImport(),
          getLatestUserImport(),
        ])

        setLatestClientImport(clientImport)
        setLatestUserImport(userImport)
      }

      getRecentData()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (NESTED_CLIENT_ROUTE.test(location.pathname)) {
      const clientId = location.pathname.split('/')[2]

      const getUpdatedClient = async () => {
        const updatedClient = await getClient(
          clientId,
          () => {},
          () => {},
        )

        setClient(updatedClient)
      }
      getUpdatedClient()
    }
  }, [location.pathname])

  // If the user is not authenticated, render without a nav bar
  if (!isAuthenticated) {
    return <div className="size-full bg-blue">{children}</div>
  }

  const options = isLimitedUser
    ? NAVIGATION_OPTIONS.filter((option) => option.href !== '/users')
    : NAVIGATION_OPTIONS

  const renderSecondaryNav = () => {
    const clientId = location.pathname.split('/')[2]
    let secondaryNavOptions = [
      {
        label: 'Client Dashboard',
        href: `/clients/${clientId}/dashboard`,
        regex: /^\/clients\/[A-Za-z0-9-]*\/dashboard/,
      },
      {
        label: 'Data Imports',
        href: `/clients/${clientId}/imports`,
        regex: /^\/clients\/[A-Za-z0-9-]*\/imports/,
        displayIndicator: client?.portfolioDataStatus === 'Import in Progress',
      },
      {
        label: 'Activity Log',
        href: `/clients/${clientId}/activity-log`,
        regex: /^\/clients\/[A-Za-z0-9-]*\/activity-log/,
      },
      // {
      //   label: 'Rules',
      //   href: `/clients/${clientId}/rules`,
      //   regex: /^\/clients\/[A-Za-z0-9-]*\/rules/,
      // },)
    ]

    if (client?.latestSuccessfulImport !== null) {
      secondaryNavOptions = insert(secondaryNavOptions, 1, [
        {
          label: 'Portfolio Data',
          href: `/clients/${clientId}/portfolio-data`,
          regex: /^\/clients\/[A-Za-z0-9-]*\/portfolio-data/,
        },
        {
          label: 'Benchmark Data',
          href: `/clients/${clientId}/benchmark-data`,
          regex: /^\/clients\/[A-Za-z0-9-]*\/benchmark-data/,
        },
        {
          label: 'AUM Data',
          href: `/clients/${clientId}/aum-data`,
          regex: /^\/clients\/[A-Za-z0-9-]*\/aum-data/,
        },
        {
          label: 'Settings',
          href: `/clients/${clientId}/settings`,
          regex: /^\/clients\/[A-Za-z0-9-]*\/settings/,
        },
      ])
    }

    return _.map(secondaryNavOptions, (option) => (
      <li key={option.label}>
        <Link
          to={option.href}
          reloadDocument
          className={joinClassNames(
            option.regex.test(location.pathname)
              ? 'text-white hover:text-blue-200/80'
              : 'text-blue-200/80 hover:text-white',
            'group relative flex gap-x-3 rounded-md p-2',
          )}
        >
          {option.label}

          {option.displayIndicator && (
            <span className="absolute right-0 top-3 size-1.5 rounded-full bg-orange-400" />
          )}
        </Link>
      </li>
    ))
  }

  /**
   * Renders the import status if there are warnings or errors.
   * @param {string} label
   * @param {object} importData
   */
  const renderImportStatus = (label, importData, collapsed = false) => {
    if (!importData || !importData?.warnings || !importData?.errors) return null
    if (importData.warnings.length === 0 && importData.errors.length === 0) return null

    if (collapsed) {
      return (
        <Tooltip
          content={
            <div className="rounded-lg bg-white p-4 shadow-lg ring-1 ring-black/5">
              <span className="text-sm font-medium">{`${label} Import Issue`}</span>
            </div>
          }
        >
          <Button
            icon={<ExclamationTriangleIcon className="size-6 fill-inherit" />}
            className="rounded-md fill-orange-400 p-3 hover:bg-blue-light hover:fill-white"
            onClick={() => setReviewImport(importData)}
            iconOnly
          />
        </Tooltip>
      )
    }

    return (
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition data-[closed]:data-[enter]:translate-y-2 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <ExclamationTriangleIcon aria-hidden="true" className="size-6 fill-orange-400" />
            </div>

            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{label} Import Issues</p>
              <p className="mt-1 text-xs text-gray-500">
                Please review the import log for more details.
              </p>
            </div>
          </div>

          <div className="mt-1 flex justify-end gap-2">
            <Button label="View" onClick={() => setReviewImport(importData)} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="size-full">
      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-800/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <Button
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                  iconOnly
                  icon={<XMarkIcon aria-hidden="true" className="size-6 text-white" />}
                  ariaLabel="Close sidebar"
                />
              </div>
            </TransitionChild>

            {/* Sidebar */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue px-6 ring-1 ring-white/10">
              <div className="flex h-16 shrink-0 items-center">
                <img className="h-10 w-auto sm:h-12" src={logo} alt="Logo" />
              </div>

              <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col justify-between">
                  <li>
                    <ul className="-mx-2 space-y-1">
                      {options.map((item) => (
                        <li key={item.label}>
                          <Link
                            to={item.href}
                            reloadDocument
                            className={joinClassNames(
                              location.pathname.startsWith(item.href)
                                ? 'bg-blue-light text-white'
                                : 'text-blue-200 hover:bg-blue-light hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 leading-6 tracking-wide',
                            )}
                          >
                            <item.icon aria-hidden="true" className="size-6 shrink-0" />
                            {item.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li className="-mx-2 mb-2 space-y-1">
                    {isAdminUser && renderImportStatus('Client', latestClientImport)}
                    {isAdminUser && renderImportStatus('User', latestUserImport)}

                    <ul className="-mx-2 space-y-1">
                      <Link
                        to="/profile"
                        reloadDocument
                        className="flex items-center gap-x-4 rounded-md px-3 py-1 tracking-wide text-blue-200 hover:bg-blue-light hover:text-white"
                      >
                        <span className="sr-only">Open user menu</span>
                        <UserCircleIcon className="block size-8 fill-white" aria-hidden="true" />
                        {user?.firstName} {user?.lastName}
                      </Link>
                    </ul>

                    <ul className="-mx-2 space-y-1">
                      <Button
                        type="button"
                        onClick={() => logout(user.refreshToken, clearStore)}
                        className="flex w-full items-center rounded-md border-none px-3 py-1 tracking-wide text-blue-200 hover:bg-blue-light hover:text-white"
                        icon={
                          <ArrowLeftStartOnRectangleIcon
                            className="block size-8 stroke-white"
                            aria-hidden="true"
                          />
                        }
                        label="Logout"
                        alignLeft
                        labelClassName="text-[16px] font-normal"
                        plain
                        size="sm"
                        flex
                      />
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-blue bg-blue px-4 shadow-sm sm:px-6">
        {/* Menu */}
        <Button
          type="button"
          onClick={() => setSidebarOpen(true)}
          className="-m-2.5 p-2.5 text-white"
          icon={<Bars3Icon aria-hidden="true" className="size-6" />}
          iconOnly
          ariaLabel="Open sidebar"
        />

        {/* Secondary Navigation */}
        {NESTED_CLIENT_ROUTE.test(location.pathname) && (
          <nav className="flex overflow-x-auto border-b border-white/10 py-4">
            <ul className="flex min-w-full flex-none gap-x-6 sm:px-2 lg:px-4">
              {renderSecondaryNav()}
            </ul>
          </nav>
        )}
      </div>

      <main className="flex h-[calc(100vh-65px)] w-screen flex-row">
        {/* Collapsed Sidebar */}
        <div
          className={mergeClassNames(
            'hidden md:left-0 md:z-50 md:flex md:w-20 md:shrink-0 md:flex-col md:overflow-y-auto md:bg-blue md:pb-4',
          )}
        >
          <nav className="mt-4 flex h-full justify-center">
            <ul className="flex flex-col justify-between">
              <li>
                <ul className="flex flex-col items-center space-y-1">
                  {options.map((item) => (
                    <li key={`${item.label}:collapsed`}>
                      <Link
                        to={item.href}
                        className={mergeClassNames(
                          location.pathname.startsWith(item.href)
                            ? 'bg-blue-light text-white'
                            : 'text-blue-200 hover:bg-blue-light hover:text-white',
                          'group flex gap-x-3 rounded-md p-3',
                        )}
                      >
                        <item.icon aria-hidden="true" className="size-6 shrink-0" />
                        <span className="sr-only">{item.label}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="-mx-2 mb-2 space-y-1">
                <ul className="flex flex-col items-center space-y-1">
                  {isAdminUser && renderImportStatus('Client', latestClientImport, true)}
                  {isAdminUser && renderImportStatus('User', latestUserImport, true)}

                  <Link
                    to="/profile"
                    reloadDocument
                    className={mergeClassNames(
                      location.pathname.startsWith('/profile')
                        ? 'bg-blue-light fill-white'
                        : 'fill-blue-200 hover:bg-blue-light hover:fill-white',
                      'group flex gap-x-3 rounded-md p-3',
                    )}
                  >
                    <UserCircleIcon className="block h-6 fill-inherit" aria-hidden="true" />
                    <span className="sr-only">Open user menu</span>
                  </Link>

                  <Button
                    onClick={() => logout(user.refreshToken, clearStore)}
                    className="rounded-md stroke-blue-200 p-3 hover:bg-blue-light hover:stroke-white"
                    icon={
                      <ArrowLeftStartOnRectangleIcon
                        className="block size-6 stroke-inherit"
                        aria-hidden="true"
                      />
                    }
                    iconOnly
                  />
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        {/* Page content */}
        <div className="size-full overflow-hidden">{children}</div>
      </main>

      {reviewImport && (
        <Modal
          icon={<ExclamationTriangleIcon className="h-6 fill-white" />}
          open
          title={`${
            reviewImport.numClientsArchived !== undefined ? 'Client' : 'User'
          } Import Issues`}
          onClose={() => setReviewImport(null)}
          content={
            <div className="mt-6 w-full">
              <dl className="space-y-3 divide-y divide-gray-100">
                {reviewImport.warnings.length > 0 && (
                  <div className="px-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                    <dt className="text-sm/6 font-medium text-gray-900">Warnings</dt>

                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-4 sm:mt-0">
                      <ul className="max-h-40 overflow-y-auto sm:pl-4">
                        {reviewImport.warnings.map((warning, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={`${warning}:${i}`}>{warning}</li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                )}

                {reviewImport.errors.length > 0 && (
                  <div className="px-4 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                    <dt className="text-sm/6 font-medium text-gray-900">Errors</dt>

                    <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-4 sm:mt-0">
                      <ul className="max-h-40 overflow-y-auto sm:pl-4">
                        {reviewImport.errors.map((error, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li key={`${error}:${i}`}>{error}</li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          }
          actions={[{ type: 'cancel', label: 'Dismiss', onClick: () => setReviewImport(null) }]}
        />
      )}
    </div>
  )
})

export default NavBar
