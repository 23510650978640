import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CircleDashed } from 'tabler-icons-react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
// eslint-disable-next-line import/no-cycle
import { Dialog } from '../Dialog'

const Button = ({
  ariaLabel = null,
  background = 'bg-blue',
  borderless = false,
  className = null,
  confirm = null,
  dataTestId = null,
  disabled = false,
  fullWidth = false,
  icon = null,
  id = null,
  label,
  loading = false,
  onClick,
  outlined = false,
  plain = false,
  size = 'md',
  type = 'submit',
  iconOnly = false,
  alignLeft = false,
  labelClassName = null,
  flex = false,
}) => {
  // State
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  let loadingColor = 'white'

  const configureBackground = () => {
    if (iconOnly) return ''
    let baseStyles =
      'border-none rounded-md px-2.5 py-1.5 px-2.5 py-1.5 text-sm font-semibold shadow-sm '
    let hoverStyles = ''

    if (background === 'bg-blue') {
      baseStyles += 'text-blue-700 bg-blue-50'
      hoverStyles = 'hover:bg-blue-100'
      loadingColor = '#0b5f99'
    } else if (background === 'bg-white') {
      baseStyles += 'text-gray-700 bg-gray-50'
      hoverStyles = 'hover:bg-gray-100'
      loadingColor = '#4d4e51'
    } else if (background === 'bg-red') {
      baseStyles += 'text-red-700 bg-red-50'
      hoverStyles = 'hover:bg-red-100'
      loadingColor = '#c22113'
    } else if (background === 'bg-gray') {
      baseStyles += 'text-gray-700 bg-gray-50'
      hoverStyles = 'hover:bg-gray-100'
      loadingColor = '#4d4e51'
    } else if (background === 'bg-green') {
      baseStyles += 'text-green-700 bg-green-50'
      hoverStyles = 'hover:bg-green-100'
      loadingColor = '#116b51'
    } else if (background === 'bg-transparent') {
      baseStyles += 'bg-transparent text-blue-200'
      hoverStyles = 'hover:bg-blue hover:text-white'
      loadingColor = '#0b5f99'
    } else {
      baseStyles += 'text-blue-700 bg-blue-50'
      hoverStyles = 'hover:bg-blue-100'
      loadingColor = '#0b5f99'
    }

    if (disabled) hoverStyles = ''

    return `${background} ${baseStyles} ${hoverStyles}`
  }

  const configureClass = () => {
    let base = `group relative flex border font-medium disabled:opacity-50 border-2 disabled:cursor-not-allowed ${
      alignLeft ? 'justify-start' : 'justify-center'
    } items-center`
    let updates = ''

    // Configure border radius, padding and text size
    switch (size) {
      case 'xs':
        base = `${base} rounded py-1.5 px-2.5 text-xs`

        if ((icon || loading) && !plain) base = `${base} pl-8 pr-2`
        break
      case 'sm':
        base = `${base} rounded-md py-2 px-3 text-sm leading-4`

        if ((icon || loading) && !plain) base = `${base} pl-8 pr-2.5`
        break
      case 'md':
        base = `${base} rounded-md py-2 px-4 text-sm leading-5`

        if ((icon || loading) && !plain) base = `${base} sm:pl-9 sm:pr-3.5 pl-7 pr-2`
        break
      case 'lg':
        base = `${base} rounded-md py-2 px-4 text-base`

        if ((icon || loading) && !plain) base = `${base} pl-9 pr-3.5`
        break
      case 'xl':
        base = `${base} rounded-md py-3 px-5 text-base`

        if ((icon || loading) && !plain) base = `${base} pl-9 pr-3.5`
        break
      default:
        base = `${base} rounded-md py-2 px-4 text-xs`

        if ((icon || loading) && !plain) base = `${base} pl-8 pr-2.5`
    }

    if (fullWidth) {
      updates = `${updates} w-full`
    }

    if (borderless) {
      updates = `${updates} border-transparent hover:border-transparent`
    }

    if (outlined) {
      updates = `${updates} border-black hover:border-black-hover`
    }

    return mergeClassNames(base, updates)
  }

  const confirmDialog = () => (
    <Dialog
      {...confirm}
      onConfirm={() => {
        setShowConfirmDialog(false)
        onClick()
      }}
      onCancel={() => setShowConfirmDialog(false)}
    />
  )

  if (iconOnly) {
    return (
      <>
        <button
          aria-label={ariaLabel}
          data-testid={dataTestId}
          className={className}
          disabled={disabled || loading}
          // eslint-disable-next-line react/button-has-type
          type={type || 'button'}
          onClick={(e) => {
            if (confirm) {
              setShowConfirmDialog(true)
            } else if (onClick) {
              onClick(e)
            }
          }}
        >
          {icon}
        </button>

        {showConfirmDialog && confirmDialog()}
      </>
    )
  }

  return (
    <>
      <button
        id={id}
        aria-label={ariaLabel}
        data-testid={dataTestId}
        onClick={() => {
          if (confirm) {
            setShowConfirmDialog(true)
          } else if (onClick) {
            onClick()
          }
        }}
        className={mergeClassNames(
          configureClass(),
          plain ? 'border-0 p-0 text-blue hover:text-blue-700' : configureBackground(),
          className,
          flex && 'flex items-center gap-x-4',
        )}
        disabled={disabled || loading}
        // eslint-disable-next-line react/button-has-type
        type={type || 'submit'}
      >
        {icon && (
          <span className={flex ? '' : 'absolute inset-y-0 left-0 flex items-center pl-[10px]'}>
            {icon}
          </span>
        )}
        <span className={labelClassName}>{label}</span>
        {loading && (
          <span
            className={mergeClassNames(
              'absolute inset-y-0 left-0 flex items-center pl-2',
              plain && '-left-6',
            )}
          >
            <div className="size-6">
              <svg className="mr-3 size-6 motion-safe:animate-spin-slow" viewBox="0 0 24 24">
                <CircleDashed size={24} strokeWidth={2} color={plain ? '#114469' : loadingColor} />
              </svg>
            </div>
          </span>
        )}
      </button>

      {showConfirmDialog && confirmDialog()}
    </>
  )
}

Button.propTypes = {
  alignLeft: PropTypes.bool,
  ariaLabel: PropTypes.string,
  background: PropTypes.string,
  borderless: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  confirm: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.element,
  }),
  disabled: PropTypes.bool,
  flex: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.element,
  iconOnly: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClassName: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  plain: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  type: PropTypes.string,
}

export default Button
