import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { PasswordInput } from '../../components/PasswordInput'
import { TextInput } from '../../components/TextInput'

// Images
import logo from '../../assets/images/logo.svg'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Service & Utils
import { resetPassword, validateResetToken } from '../../services/user.service'
import { toast, verifyPassword } from '../../utils/helpers'

/**
 *
 * PasswordReset
 *
 */
const PasswordReset = () => {
  // Context
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { getUpdatedUser, isAuthenticated, setCurrentTokens } = useContext(UserStoreContext)

  const token = searchParams.get('token')
  const username = searchParams.get('username')
  const newUser = location.pathname === '/set-password'

  // State
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [staticError, setStaticError] = useState(null)

  const handleErrors = (m) => toast(m, 'error')

  useEffect(() => {
    if (token && !isAuthenticated) {
      validateResetToken(token, (err) => {
        if (err.includes('expired') && newUser) {
          setStaticError(err)
        } else if (err.includes('expired')) {
          setRedirect('/password-reset-request')
        } else {
          handleErrors(err)
        }
      })
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  /**
   * Helper to set the redirect parameters based on whether or not the user is
   * setting or RE-setting a password
   */
  const getRedirectParams = () => {
    if (newUser) return '?created=true'
    if (redirect === '/password-reset-request') return '?tokenExpired=true'
    return '?reset=true'
  }

  /**
   * Handles form submission
   * - Logs the user in if successful (via setting tokens in the store)
   * @param {object} data
   */
  const onSubmit = async (data) => {
    await resetPassword(
      { ...data, token },
      handleErrors,
      setLoading,
      setCurrentTokens,
      getUpdatedUser,
    )
  }

  return (
    <>
      {redirect && <Navigate to={{ pathname: '/login', search: getRedirectParams() }} />}

      <div className="flex min-h-full flex-1 flex-col justify-center py-2 sm:px-6 lg:px-8">
        <img alt="Longs Peak Advisory" src={logo} className="mx-auto h-24 w-auto" />
        <FormHeader title={newUser ? 'Set your password' : 'Reset your password'} />

        <div className="mx-4 mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="rounded-lg bg-white px-4 py-10 shadow sm:px-12">
            {staticError ? (
              <div className="flex w-full flex-col items-center space-y-2">
                <span className="text-error">{staticError}</span>

                <Button fullWidth label="Back to Login" onClick={() => navigate('/login')} />
              </div>
            ) : (
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <p className="text-center text-sm text-gray-dark">
                  Or
                  <Link
                    to="/login"
                    className="ml-1 font-medium text-blue hover:text-blue-dark hover:underline"
                  >
                    Log In
                  </Link>
                </p>

                <div className="space-y-1">
                  {newUser && (
                    <TextInput
                      data-testid="username"
                      disabled
                      id="username"
                      placeholder="Username"
                      value={username}
                    />
                  )}

                  <Controller
                    error={errors.password && 'This field is required'}
                    name="password"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => {
                        const err = verifyPassword(value)
                        if (err) return err
                        return undefined
                      },
                    }}
                    render={({ field }) => (
                      <PasswordInput
                        error={
                          errors.password && (errors.password.message || 'This field is required')
                        }
                        id="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        showTooltip
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value, values) => {
                        const err = verifyPassword(value)
                        if (err) return err
                        if (value && value !== values.confirmPassword)
                          return 'Passwords do not match'
                        return undefined
                      },
                    }}
                    render={({ field }) => (
                      <PasswordInput
                        error={
                          errors.confirmPassword &&
                          (errors.confirmPassword.message || 'This field is required')
                        }
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        autoComplete="new-password"
                        showTooltip
                        {...field}
                      />
                    )}
                  />
                </div>

                <Button fullWidth label="Submit" loading={loading} />
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordReset
