import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import {
  DateEditor,
  PercentageEditor,
  PriceEditor,
  StatusEditor,
  TextAreaEditor,
  TextEditor,
} from '../../components/CustomEditor'
import { DataTable } from '../../components/DataTable'
import { ImportDataChanges } from '../../components/ImportDataChanges'
import { FileUploader } from '../../components/FileUploader'
import { MultiSelectDropdown } from '../../components/MultiSelectDropdown'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import { StatusTag } from '../../components/StatusTag'
import { Step } from '../../components/Step'
import { StepProgress } from '../../components/StepProgress'
import { FINISHED_STATUSES } from '../../components/TaskProgressList'
import { Toggle } from '../../components/Toggle'
import ImportDetails from './ImportDetails'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'
import { TaskStoreContext } from '../../stores/TaskStore'

// Services
import { getClient, getClientPortfolioDataPeriods } from '../../services/clients.service'
import {
  createPortfolioDataImport,
  getPendingPortfolioData,
  getPortfolioDataImport,
  updatePendingPortfolioData,
  updatePortfolioDataImport,
} from '../../services/portfolio.service'

// Utils & Hooks
import { toast } from '../../utils/helpers'
import { formatCurrency } from '../../utils/formatters'
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

const FILE_NAME_PREFIX = /imports\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/g

const DEFAULT_PENDING_COLUMNS = [
  {
    field: 'compositeName',
    header: 'Composite',
    sortField: 'composite_name',
    sortable: true,
    style: { minWidth: '200px' },
  },
  {
    field: 'accountName',
    header: 'Account Name',
    sortField: 'account_name',
    sortable: true,
    style: { minWidth: '200px' },
  },
  {
    field: 'accountNumber',
    header: 'Account Number',
    sortField: 'account_number',
    sortable: true,
    style: { minWidth: '200px' },
  },
  {
    field: 'period',
    header: 'Period',
    body: (row) => dayjs(row.period).format('MM/DD/YYYY'),
    sortable: true,
    editor: DateEditor,
    style: { minWidth: '150px' },
  },
  {
    field: 'beginningValue',
    header: 'Beginning Value',
    sortable: true,
    editor: PriceEditor,
    body: (row) => formatCurrency(row.beginningValue),
    style: { minWidth: '150px' },
  },
  {
    field: 'endingValue',
    header: 'Ending Value',
    sortable: true,
    editor: PriceEditor,
    body: (row) => formatCurrency(row.endingValue),
    style: { minWidth: '150px' },
  },
  {
    field: 'grossReturn',
    header: 'Gross Return',
    sortable: true,
    editor: PercentageEditor,
    body: (row) => (row.grossReturn !== null ? `${(row.grossReturn * 100).toFixed(2)}%` : '-'),
    style: { minWidth: '140px' },
  },
  {
    field: 'netReturn',
    header: 'Net Return',
    sortable: true,
    editor: PercentageEditor,
    body: (row) => (row.netReturn !== null ? `${(row.netReturn * 100).toFixed(2)}%` : '-'),
    style: { minWidth: '140px' },
  },
  {
    field: 'isIncluded',
    header: 'Included',
    sortable: true,
    editor: StatusEditor,
    body: (row) => (
      <StatusTag
        value={row.isIncluded ? 'Yes' : 'No'}
        severity={row.isIncluded ? 'success' : 'danger'}
      />
    ),
    style: { minWidth: '130px' },
  },
  {
    field: 'isFeePaying',
    header: 'Fee Paying',
    sortable: true,
    editor: StatusEditor,
    body: (row) => (
      <StatusTag
        value={row.isFeePaying ? 'Yes' : 'No'}
        severity={row.isFeePaying ? 'success' : 'danger'}
      />
    ),
    style: { minWidth: '130px' },
  },
  {
    field: 'hasBundledFee',
    header: 'Bundled Fee',
    sortable: true,
    editor: StatusEditor,
    body: (row) => (
      <StatusTag
        value={row.hasBundledFee ? 'Yes' : 'No'}
        severity={row.hasBundledFee ? 'success' : 'danger'}
      />
    ),
    style: { minWidth: '130px' },
  },
  {
    field: 'comments',
    header: 'Comments',
    editor: TextAreaEditor,
    style: { minWidth: '250px' },
  },
]
const HOUSEHOLD_COLUMNS = _.cloneDeep(DEFAULT_PENDING_COLUMNS)
HOUSEHOLD_COLUMNS.splice(1, 0, {
  field: 'portfolioName',
  header: 'Portfolio Name',
  sortField: 'portfolio_name',
  sortable: true,
  style: { minWidth: '230px' },
})
HOUSEHOLD_COLUMNS.splice(2, 0, {
  field: 'portfolioNumber',
  header: 'Portfolio Number',
  sortField: 'portfolio_number',
  sortable: true,
  style: { minWidth: '230px' },
})

const ClientPortfolioDataImport = () => {
  // Context
  const { clientId } = useParams()
  const navigate = useNavigate()
  const { client, setClient } = useContext(ClientDashboardStoreContext)
  const { addTask, updateTask } = useContext(TaskStoreContext)

  const PENDING_PORTFOLIO_DATA_BASE_URL = `/clients/${clientId}/pending-portfolio-data`

  // State
  const [loadingClient, setLoadingClient] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)
  const [error, setError] = useState(null)
  const [periods, setPeriods] = useState([])
  const [activeImport, setActiveImport] = useState(null)
  const [processingImport, setProcessingImport] = useState(false)
  const [columns, setColumns] = useState(null)
  const [loadedAdditionalColumns, setLoadedAdditionalColumns] = useState(false)
  const [pendingData, setPendingData] = useState([])
  const [loadingPending, setLoadingPending] = useState(false)
  const [loadingApprove, setLoadingApprove] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)

  // Pagination & Sorting
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination
  const { sorting } = useSorting('period')
  const { sortedColumn } = sorting

  const fileName = activeImport?.fileName.replace(FILE_NAME_PREFIX, '') || ''

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  const resetPage = () => {
    setActiveIndex(0)
    setError(null)
    setActiveImport(null)
    setProcessingImport(false)
    setLoadingApprove(false)
    setLoadingCancel(false)
  }

  const UPLOAD_STEPS = [
    { id: '01', enabled: false, name: 'Upload Data', onClick: () => setActiveIndex(0) },
    {
      id: '02',
      enabled: activeImport && activeImport.status !== 'Pending',
      name: 'Review Changes',
      onClick: () => setActiveIndex(1),
    },
    {
      id: '03',
      enabled: activeImport && activeImport.status === 'Ready for Review',
      name: 'Modify Pending Data',
      onClick: () => {
        setActiveIndex(2)
        getUpdatedPendingPortfolioData(
          `${PENDING_PORTFOLIO_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
        )
      },
    },
    {
      id: '04',
      enabled:
        activeImport &&
        (activeImport.status === 'Approved' ||
          activeImport.status === 'Importing' ||
          activeImport.status === 'Imported'),
      name: 'Run CTV6',
      onClick: () => setActiveIndex(3),
    },
  ]

  const {
    control,
    getValues,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      uploadedFile: null,
      overrideExistingData: false,
      periodOverrides: [],
    },
  })

  /**
   * Get updated client details. Optionally updating loading state.
   * @param {bool} firstLoad
   */
  const getUpdatedClient = async (firstLoad = false) => {
    if (firstLoad) setLoadingClient(true)

    const [updatedClient, clientPeriods] = await Promise.all([
      getClient(clientId, setError),
      getClientPortfolioDataPeriods(clientId, setError),
    ])

    if (clientPeriods) setPeriods(_.map(clientPeriods, (p) => ({ id: p, label: p })))

    if (updatedClient) {
      setClient(updatedClient)

      // Load the pending data import if there is one
      if (
        updatedClient.pendingDataImport ||
        (updatedClient?.status === 'Import in Progress' && updatedClient?.latestSuccessfulImport)
      ) {
        const portfolioDataImport =
          updatedClient.pendingDataImport || updatedClient.latestSuccessfulImport
        const dataImport = await getPortfolioDataImport(
          clientId,
          portfolioDataImport.id,
          handleErrors,
        )

        setActiveImport(dataImport)

        // Determine what step we're on and set the active index
        if (dataImport?.status === 'Processing' || dataImport?.status === 'Ready for Review') {
          setActiveIndex(1)
        } else if (
          dataImport?.status === 'Approved' ||
          dataImport?.status === 'Importing' ||
          dataImport?.status === 'Imported'
        ) {
          setActiveIndex(3)
        }

        // If the import is still processing, set back up the polling
        if (
          !processingImport &&
          (dataImport?.status === 'Processing' || dataImport?.status === 'Importing')
        ) {
          setProcessingImport(true)
        }
      }
    }

    if (firstLoad) setLoadingClient(false)
  }

  useEffect(() => {
    if (clientId) {
      getUpdatedClient(true)
    }
  }, [clientId])

  useEffect(() => {
    if (
      activeImport &&
      (!columns ||
        (activeImport.portfolioDataAdditionalColumns?.length > 0 && !loadedAdditionalColumns))
    ) {
      let updatedColumns = activeImport.isHouseholdDataImport
        ? HOUSEHOLD_COLUMNS
        : DEFAULT_PENDING_COLUMNS

      // Check for additional columns on the client and dynamically update the columns for display
      if (activeImport.portfolioDataAdditionalColumns) {
        const additionalColumns = activeImport.portfolioDataAdditionalColumns.map((column) => ({
          field: `editableAdditionalData.${column}`,
          body: (row) => (row.additionalData ? row.additionalData[column] : ''),
          header: column,
          editor: TextEditor,
          style: { minWidth: '200px' },
        }))

        updatedColumns = [...updatedColumns, ...additionalColumns]
        setColumns(updatedColumns)
        setLoadedAdditionalColumns(true)
      } else {
        setColumns(updatedColumns)
      }
    }
  }, [activeImport])

  /**
   * Polls the active import for updates.
   */
  useEffect(() => {
    let interval = null

    if (activeImport && processingImport) {
      if (!interval) {
        interval = setInterval(async () => {
          let additionalExpand = ''
          if (activeImport?.status === 'Processing') {
            additionalExpand = ',portfolio_data_changes'
          }
          const updatedDataImport = await getPortfolioDataImport(
            clientId,
            activeImport.id,
            () => {},
            () => {},
            additionalExpand,
          )
          setActiveImport((prev) => ({
            ...prev,
            ...updatedDataImport,
          }))
        }, 3000)
      }

      if (FINISHED_STATUSES.includes(activeImport?.status)) {
        setProcessingImport(false)
        getUpdatedClient()
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [activeImport])

  /**
   * Gets the updated list of pending portfolio data; updates pagination.
   * @param {string} url
   */
  const getUpdatedPendingPortfolioData = async (url) => {
    const response = await getPendingPortfolioData(url, handleErrors, setLoadingPending)

    if (response) {
      setTotalRecords(response.count)
      setPendingData(
        _.map(response.results, (d) => ({
          ...d,
          editableAdditionalData: d.additionalData ? { ...d.additionalData } : null,
        })),
      )
    }
  }

  /**
   * When the sort, current page, or row count changes, get the updated list of portfolio data.
   */
  useEffect(() => {
    if (clientId) {
      getUpdatedPendingPortfolioData(
        `${PENDING_PORTFOLIO_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage])

  /**
   * Handles uploading the data import file.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const { file } = data.uploadedFile
    // eslint-disable-next-line no-param-reassign
    delete data.uploadedFile
    const payload = { ...data, periodOverrides: JSON.stringify(_.map(data.periodOverrides, 'id')) }
    payload.file = file

    const response = await createPortfolioDataImport(
      clientId,
      payload,
      handleErrors,
      setLoadingApprove,
      () => {},
    )

    getUpdatedClient()

    if (response) {
      addTask({ type: 'import', id: response.id, data: response })
      setProcessingImport(true)
      setActiveIndex(activeIndex + 1) // Move to the next step
    }
    reset()
  }

  /**
   * Handles moving forward in the stepper component.
   * - If `nextStep` is provided, goes to that index
   * - Otherwise, goes to `activeIndex + 1`
   * @param {number} nextStep
   */
  const handleGoForward = (nextStep = activeIndex + 1) => {
    if (nextStep - 1 < UPLOAD_STEPS.length - 1) {
      setActiveIndex(nextStep)
    }
  }

  /**
   * Handles going backwards in the stepper component.
   */
  const handleGoBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const renderLoading = (label) => (
    <div className="flex size-full flex-col items-center justify-center space-y-2">
      <span className="text-xl font-semibold text-gray-900">{label}</span>

      <span className="flex items-center pr-3">
        <div className="size-10">
          <svg className="size-10 text-gray-900 motion-safe:animate-spin-slow" viewBox="0 0 40 40">
            <ArrowPathIcon className="size-10" aria-hidden="true" />
          </svg>
        </div>
      </span>
    </div>
  )

  const renderStepContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <Step
            nextLabel={activeImport ? 'Continue' : 'Process File'}
            backLabel="Cancel Import"
            onNext={activeImport ? handleGoForward : handleSubmit(onSubmit)}
            loadingNext={loadingApprove}
            loadingBack={loadingCancel}
          >
            <form onSubmit={onSubmit} className="w-full px-8 py-6">
              {activeImport?.overrideExistingData && (
                <span className="text-sm">Overriding existing data</span>
              )}
              {activeImport?.periodOverrides?.length > 0 && (
                <span className="text-sm">
                  Overriding data for periods:{' '}
                  {_.map(
                    activeImport?.periodOverrides || watch('periodOverrides'),
                    (p) => p.label || p,
                  ).join(', ')}
                </span>
              )}
              {!activeImport && client?.latestSuccessfulImport !== null && (
                <div className="mb-2 flex flex-col">
                  <span className="font-semibold">Optional Overrides</span>
                  <span className="text-sm italic text-gray-600">
                    When importing data, the system will assume all uploads are either updating
                    prior data or adding new data. Any omissions will not be deleted.
                    <br />
                    <br />
                    If you would like to completely replace the data for a month, select that month
                    in the period overrides and only upload months with matching data.
                    Alternatively, select “Override All Data” and upload the entire history.
                    <br />
                    <br />
                    Please note, anything not included in an override upload will be deleted from
                    the database and this option should only be used when deleting entries is your
                    intention
                  </span>

                  <div className="mt-6 flex w-full flex-row space-x-6">
                    <Controller
                      name="overrideExistingData"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Toggle
                          disabled={loadingApprove || watch('periodOverrides').length > 0}
                          label="Override All Data"
                          labelClassName="font-medium text-gray-700 text-sm"
                          name="overrideExistingData"
                          onChange={onChange}
                          checked={value}
                          ref={ref}
                        />
                      )}
                    />

                    <Controller
                      name="periodOverrides"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MultiSelectDropdown
                          containerClassName="w-60"
                          disabled={loadingApprove || watch('overrideExistingData')}
                          label="Period Overrides"
                          onChange={onChange}
                          options={periods}
                          search
                          value={value}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              {activeImport || watch('uploadedFile') ? (
                <>
                  <span className="font-semibold">File</span>
                  <div
                    className="mt-3 flex flex-row gap-2 rounded-lg bg-blue-100 p-7 text-sm"
                    style={{ backgroundColor: '#f1f0ef' }}
                  >
                    <span>{fileName?.replace(/_/g, ' ') || getValues('uploadedFile')?.name}</span>
                  </div>
                </>
              ) : (
                <>
                  <span className="font-semibold">File</span>
                  <Controller
                    name="uploadedFile"
                    control={control}
                    render={() => (
                      <div className="mt-2">
                        <FileUploader
                          autoSave
                          acceptedFileTypes={[
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          ]}
                          handleUploadToServer={async (file) => {
                            setValue('uploadedFile', file)
                          }}
                          id="uploadedFile"
                          maxFiles={1}
                          type="manual"
                        />

                        {errors.uploadedFile && (
                          <div className="mt-1 w-full bg-transparent px-2 py-1 text-center">
                            <span className="text-sm font-medium text-error">
                              This field is required
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    rules={{ required: true }}
                  />
                </>
              )}
            </form>
          </Step>
        )
      case 1:
        return (
          <Step
            nextLabel="View & Modify Data"
            skipLabel="Approve Data"
            enabledNext={UPLOAD_STEPS[1].enabled && activeImport?.status === 'Ready for Review'}
            enabledSkip={client.portfolioDataStatus !== 'Running CTV6'}
            onNext={() => {
              handleGoForward()
              getUpdatedPendingPortfolioData(
                `${PENDING_PORTFOLIO_DATA_BASE_URL}?order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
              )
            }}
            onSkip={() =>
              updatePortfolioDataImport(
                clientId,
                activeImport.id,
                { markAsApproved: true },
                handleErrors,
                setLoadingApprove,
                (updatedImport) => {
                  handleGoForward(3)
                  setProcessingImport(true)
                  getUpdatedClient()
                  updateTask({ type: 'import', id: updatedImport.id, data: updatedImport })
                },
              )
            }
            loadingNext={loadingApprove}
          >
            <div className="mt-4 flex size-full flex-col items-center justify-center overflow-y-auto bg-background">
              <div className="flex size-full max-w-[70rem] flex-col items-center">
                <div className="border-primary flex size-full max-h-[65vh] overflow-y-scroll rounded-lg bg-gray-100/50 p-8">
                  {processingImport ? (
                    renderLoading('Processing Import and Computing Preview...')
                  ) : (
                    <ImportDataChanges client={client} dataImport={activeImport} />
                  )}
                </div>
              </div>
            </div>
          </Step>
        )
      case 2:
        return (
          <Step
            nextLabel="Approve & Import"
            enabledNext={
              UPLOAD_STEPS[2].enabled &&
              activeImport?.status === 'Ready for Review' &&
              client.portfolioDataStatus !== 'Running CTV6'
            }
            onNext={() =>
              updatePortfolioDataImport(
                clientId,
                activeImport.id,
                { markAsApproved: true },
                handleErrors,
                setLoadingApprove,
                (updatedImport) => {
                  handleGoForward(3)
                  setProcessingImport(true)
                  getUpdatedClient()
                  updateTask({ type: 'import', id: updatedImport.id, data: updatedImport })
                },
              )
            }
            onBack={handleGoBack}
            loadingNext={loadingApprove}
          >
            <div className="mt-4 flex size-full flex-col items-center justify-center overflow-y-auto bg-background">
              <span className="text-sm italic text-gray-600">
                To add new Composites {activeImport?.isHouseholdDataImport && ', Portfolios'} or
                Accounts, re-import.
              </span>

              <div className="mt-2 flex size-full max-w-[70rem] flex-col items-center">
                <DataTable
                  data={pendingData}
                  columns={columns}
                  loading={loadingPending}
                  rowsPerPageOptions={[50, 100, 500, 1000]}
                  pagination={pagination}
                  sorting={sorting}
                  onRowEditComplete={async ({ newData, index }) => {
                    const updatedData = [...pendingData]

                    if (newData.period instanceof Date) {
                      const [date] = newData.period.toISOString().split('T')
                      // eslint-disable-next-line no-param-reassign
                      newData.period = date
                    }
                    updatedData[index] = {
                      ...newData,
                      additionalData: newData.editableAdditionalData,
                    }

                    await updatePendingPortfolioData(
                      clientId,
                      newData.id,
                      {
                        beginningValue: newData.beginningValue,
                        endingValue: newData.endingValue,
                        comments: newData.comments,
                        grossReturn: newData.grossReturn,
                        hasBundledFee: newData.hasBundledFee,
                        isFeePaying: newData.isFeePaying,
                        isIncluded: newData.isIncluded,
                        netReturn: newData.netReturn,
                        period: newData.period,
                        additionalData: newData.editableAdditionalData,
                      },
                      (message) => {
                        handleSuccess(message)
                        setPendingData(updatedData)
                      },
                      handleErrors,
                      () => {},
                    )
                  }}
                  areRowsEditable
                  hoverEffect={false}
                />
              </div>
            </div>
          </Step>
        )
      case 3:
        return (
          <Step onBack={() => setActiveIndex(1)}>
            <div className="mt-4 flex size-full flex-col items-center justify-center overflow-y-auto bg-background">
              <div className="flex size-full max-w-[800px] flex-col items-center">
                <div className="border-primary flex size-full max-h-[350px] overflow-y-scroll rounded-lg bg-gray-100/50 p-4">
                  {processingImport ? (
                    <div className="flex size-full flex-col items-center justify-center space-y-2">
                      <span className="text-xl font-semibold text-gray-900">
                        Importing new data...
                      </span>

                      <span className="flex items-center pr-3">
                        <div className="size-10">
                          <svg
                            className="size-10 text-gray-900 motion-safe:animate-spin-slow"
                            viewBox="0 0 40 40"
                          >
                            <ArrowPathIcon className="size-10" aria-hidden="true" />
                          </svg>
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="flex w-full flex-col items-center justify-center space-y-4 text-center">
                      {activeImport?.status === 'Failed' ? (
                        <>
                          <span className="font-semibold">Data import failed.</span>
                          {activeImport?.errors?.length > 0 && (
                            <div>
                              <ul>
                                {_.map(activeImport.errors, (e) => (
                                  <li key={e}>{e}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          <div className="flex flex-row space-x-4 pt-4">
                            <Button label="Retry Import" onClick={resetPage} />
                          </div>
                        </>
                      ) : (
                        <>
                          <span className="font-semibold">
                            Data successfully imported. You can view your Portfolio Data or Create
                            a New Report.
                          </span>

                          <div className="flex flex-row space-x-4">
                            <Button
                              label="View Portfolio Data"
                              onClick={() => navigate(`/clients/${clientId}/portfolio-data`)}
                            />
                            <Button
                              label="Create New Report"
                              onClick={() => navigate(`/clients/${clientId}/new-report`)}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Step>
        )
      default:
        return null
    }
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="relative flex size-full flex-col bg-background">
          {client && <ClientHeader client={client} />}

          <div className="flex flex-col-reverse overflow-y-auto md:grid md:grid-cols-4">
            {/* Progress Steps */}
            <div
              className={mergeClassNames(
                'justify-center px-4 sm:px-6 md:px-8',
                activeImport
                  ? 'flex w-full sm:col-span-3'
                  : 'flex w-full items-center sm:col-span-4',
              )}
            >
              <div className="mt-2 flex w-full max-w-[70rem] flex-col items-center">
                <StepProgress
                  steps={UPLOAD_STEPS}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />

                {renderStepContent()}
              </div>
            </div>

            {activeImport && (
              <div className="flex">
                <ImportDetails
                  activeImport={activeImport}
                  fileName={fileName}
                  handleCancelSuccess={() => {
                    setActiveImport(null)
                    setProcessingImport(false)
                    setActiveIndex(0)
                    getUpdatedClient()
                    handleSuccess('Data import canceled.')
                  }}
                  handleErrors={handleErrors}
                  setLoadingCancel={setLoadingCancel}
                />
              </div>
            )}
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
}

export default ClientPortfolioDataImport
