import { PencilIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { UserStoreContext } from '../../stores/UserStore'

// Components
import { Modal } from '../Modal'
import { MultiSelectDropdown } from '../MultiSelectDropdown'
import { TextInput } from '../TextInput'

const DEFAULT = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  jobTitle: '',
  assignedClients: [],
}

const UserModal = ({
  clientsList = [],
  editUser = null,
  endOfFormContent = null,
  loadingUser,
  onSubmit,
  setEditUser,
  showUserModal,
  setShowUserModal,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT,
  })

  const { isAdminUser } = useContext(UserStoreContext)

  useEffect(() => {
    if (editUser) {
      reset(editUser)
    }
  }, [editUser])

  const actions = [
    {
      type: 'cancel',
      label: 'Cancel',
      onClick: () => {
        setTimeout(() => {
          reset(DEFAULT)
          setEditUser(false)
        }, 500)
      },
    },
  ].concat(isAdminUser ? { type: 'submit', label: 'Save', onClick: handleSubmit(onSubmit) } : [])

  return (
    <Modal
      actions={actions}
      icon={<PencilIcon aria-hidden="true" className="h-5 fill-white sm:h-6" />}
      content={
        <form className="mt-3 flex flex-col text-center sm:mt-5">
          <div className="mb-4 flex flex-col gap-4">
            <TextInput
              data-testid="firstName"
              disabled
              error={errors.firstName && 'This field is required'}
              fullWidth
              id="firstName"
              name="firstName"
              nunito
              label="First Name"
              placeholder="First Name"
              {...register('firstName', { required: true })}
            />

            <TextInput
              data-testid="lastName"
              disabled
              error={errors.lastName && 'This field is required'}
              fullWidth
              id="lastName"
              name="lastName"
              nunito
              label="Last Name"
              placeholder="Last Name"
              {...register('lastName', { required: true })}
            />
            <TextInput
              data-testid="email"
              disabled
              error={errors.email && 'This field is required'}
              fullWidth
              id="email"
              name="email"
              nunito
              label="Email"
              placeholder="Email"
              {...register('email', { required: true })}
            />
            <TextInput
              data-testid="role"
              disabled
              error={errors.role && 'This field is required'}
              fullWidth
              id="role"
              name="role"
              nunito
              label="Role"
              placeholder="Role"
              {...register('role', { required: true })}
            />
            <TextInput
              data-testid="jobTitle"
              disabled
              error={errors.jobTitle && 'This field is required'}
              fullWidth
              id="jobTitle"
              name="jobTitle"
              nunito
              label="Job Title"
              placeholder="Job Title"
              {...register('jobTitle', { required: true })}
            />
            {isAdminUser && editUser?.role === 'Limited User' && (
              <div>
                <Controller
                  name="assignedClients"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelectDropdown
                      className="size-full min-h-[40px] rounded-md border-2 border-gray-light bg-white py-2.5 pr-4 focus-within:border-blue"
                      containerClassName="flex flex-col items-start w-full"
                      disabled={loadingUser || !isAdminUser}
                      id="assignedClients"
                      onChange={onChange}
                      options={clientsList}
                      placeholder="Select Clients"
                      search
                      label="Clients"
                      value={value}
                    />
                  )}
                />
              </div>
            )}
            {!isAdminUser && (
              <div className="mb-1 text-left sm:mb-4">
                <p className="block pb-1 text-sm font-medium text-gray-700">Clients</p>
                {editUser?.assignedClients.length > 0 && (
                  <ul className="max-h-[200px] overflow-y-scroll">
                    {editUser?.assignedClients.map((client) => (
                      <li key={client.id} className="text-sm text-gray-900">
                        {client.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <TextInput
              data-testid="phoneNumber"
              disabled
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              nunito
              label="Phone Number"
              placeholder="Phone Number"
              value={editUser.phoneNumber}
            />

            <TextInput
              data-testid="mfaDevice"
              disabled
              fullWidth
              id="mfaDevice"
              name="mfaDevice"
              nunito
              label="Multifactor Authentication Settings"
              placeholder="Authentication"
              value={editUser.mfaDevice}
            />
          </div>

          {endOfFormContent && (
            <span className="mt-9 text-left text-xs text-gray">{endOfFormContent}</span>
          )}
        </form>
      }
      loading={loadingUser}
      onClose={() => {
        reset(DEFAULT)
        setEditUser(false)
        setShowUserModal(false)
      }}
      open={showUserModal}
      setOpen={setShowUserModal}
      title="Edit"
    />
  )
}

UserModal.propTypes = {
  editUser: PropTypes.object,
  endOfFormContent: PropTypes.string,
  loadingUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  roles: PropTypes.object,
  showUserModal: PropTypes.bool.isRequired,
  setEditUser: PropTypes.func.isRequired,
  setShowUserModal: PropTypes.func.isRequired,
  clientsList: PropTypes.array,
}

export default UserModal
