import * as Sentry from '@sentry/browser'
import { RouterStore } from '@superwf/mobx-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'
import { PrimeReactProvider } from 'primereact/api'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Routes
import { Routes } from './pages/Routes'

// Theme
import { CustomTheme } from './utils/themes'

// The env variable is not converted to a bool - must check for the explicit string
if (import.meta.env.VITE_SENTRY_ENABLED === 'true')
  Sentry.init({
    release: import.meta.env.VITE_SENTRY_RELEASE_GIT_HASH,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  })

// Create mobx store with history
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore(browserHistory)
const stores = {
  routing: routingStore,
}

const ONE_HOUR = 3600000 // milliseconds

const RefreshBrowserRouter = ({ ...rest }) => {
  /**
   * Reloads the window after ONE_HOUR has elapsed
   */
  useEffect(() => {
    const id = setInterval(() => {
      window.location.reload(true)
    }, ONE_HOUR)

    return () => clearInterval(id)
  }, [])

  return <BrowserRouter {...rest} />
}

function App() {
  // State
  const [history, setHistory] = useState({
    action: routingStore.history.action,
    location: routingStore.history.location,
  })

  useLayoutEffect(() => routingStore.subscribe(setHistory), [])

  return (
    <PrimeReactProvider
      value={{
        unstyled: true,
        pt: CustomTheme,
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: mergeClassNames,
        },
      }}
    >
      <Provider {...stores}>
        <Toaster />
        <RefreshBrowserRouter
          location={history.location}
          navigationType={history.action}
          navigator={routingStore.history}
        >
          <Routes />
        </RefreshBrowserRouter>
      </Provider>
    </PrimeReactProvider>
  )
}

export default App
