import { observer } from 'mobx-react'
import { React, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { VerificationCodeInput } from '../../components/VerificationCodeInput'

// Images
import logo from '../../assets/images/logo.svg'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Service & Utils
import { loginConfirm, resendLoginCode } from '../../services/user.service'
import { toast } from '../../utils/helpers'

const CODE_LENGTH = 6

/**
 *
 * MultiFactorAuthentication
 *
 */
const MultiFactorAuthentication = observer(() => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const { getUpdatedUser, setCurrentTokens, setCurrentUser, user } = useContext(UserStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: null,
    },
  })

  /**
   * Handles form submission
   * - Redirects to dashboard if successful
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const result = await loginConfirm(
      { code: data.code, username: user.username },
      handleError,
      setLoading,
      setCurrentTokens,
      getUpdatedUser,
    )

    if (result) {
      navigate('/clients')
    }
  }

  /**
   * Handles re-sending verification form submission
   * @param {object} data
   */
  const submitResendForm = async (device) => {
    if (!location.state.password) navigate(`/login`, { replace: true })

    const results = await resendLoginCode(
      { mfaDevice: device, username: user.username, password: location.state.password },
      handleError,
      setLoadingResend,
    )

    if (results) {
      setCurrentUser({ email: user.email, ...results })
      handleSuccess(user.message)
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-2 sm:px-6 lg:px-8">
      <img alt="Longs Peak Advisory" src={logo} className="mx-auto h-24 w-auto" />
      <FormHeader title="Two Factor Authentication" />

      <div className="mx-4 mt-10 sm:mx-auto sm:w-full sm:max-w-[475px]">
        <div className="rounded-lg bg-white px-4 py-10 shadow sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <p className="mt-2 text-center text-sm text-gray-dark">
                Enter the verification code sent to{' '}
                <span className="font-bold">{user.address}</span>.
              </p>
              <p className="text-center text-sm text-gray-dark">
                Or
                <Link
                  to="/login"
                  className="hover:text-700 ml-1 font-medium text-blue hover:underline"
                >
                  Log In With A Different Account
                </Link>
              </p>
            </div>

            <div className="-space-y-px">
              <Controller
                name="code"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <VerificationCodeInput
                    error={errors.code && 'This field is required'}
                    handleSubmitCode={(v) => onChange(v)}
                    containerStyles={{ alignItems: 'center' }}
                    codeLength={CODE_LENGTH}
                  />
                )}
              />
            </div>

            <div className="flex justify-end">
              <Button
                plain
                label="Resend Verification Via Email"
                labelClassName="text-blue hover:text-700 hover:underline"
                onClick={() => submitResendForm('Email')}
                type="button"
                loading={loadingResend}
              />
            </div>

            <Button disabled={loadingResend} fullWidth label="Verify" loading={loading} />
          </form>
        </div>
      </div>
    </div>
  )
})

export default MultiFactorAuthentication
