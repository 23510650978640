import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import { UserModal } from '../../components/UserModal'

// Store
import { getClients } from '../../services/clients.service'
import { getUsers, updateUser } from '../../services/users.service'
import { UserStoreContext } from '../../stores/UserStore'

// Utils
import { toast } from '../../utils/helpers'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

// Constants
const BASE_USERS_URL = '/users/?expand=assigned_clients'

const Users = observer(() => {
  // Context
  const { isAdminUser, lastUpdated, user, users } = useContext(UserStoreContext)

  // Pagination
  const { pagination, setTotalRecords } = usePagination()
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting()
  const { sortedColumn } = sorting

  // State
  const [loading, setLoading] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [error, setError] = useState(null)
  const [updatedUsers, setUpdatedUsers] = useState(users)
  const [showUserModal, setShowUserModal] = useState(false)
  const [editUser, setEditUser] = useState(null)
  const [clientsList, setClientsList] = useState([null])

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of users; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedUserList = async (url) => {
    const response = await getUsers(url, setError, setLoading, () => {})

    if (response) {
      setTotalRecords(response.count)
      setUpdatedUsers(response.results)
    }
  }

  useEffect(() => {
    getUpdatedUserList(`${BASE_USERS_URL}&limit=${perPage}`)

    getClients('clients/?limit=1000', setError, setLoading, (response) => {
      if (response) setClientsList(response.results.map((c) => ({ label: c.name, id: c.id })))
    })
  }, [])

  useEffect(() => {
    getUpdatedUserList(
      `${BASE_USERS_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
    )
  }, [perPage, sortedColumn, currentPage])

  const resetForm = () => {
    setEditUser(false)
    setShowUserModal(false)
  }

  /**
   * Handles submitting the add or edit user forms.
   * @param {object} data
   */
  const onSubmit = (data) => {
    if (editUser) {
      const updatedUser = {
        id: data.id,
        assignedClients: data.assignedClients.map((client) => client.id),
      }

      updateUser(updatedUser, handleErrors, setLoadingUser, (m) => {
        getUpdatedUserList(`${BASE_USERS_URL}&limit=${perPage}`)
        handleSuccess(m)
        resetForm()
      })
    }
  }

  const columns = [
    {
      field: 'first_name',
      header: 'Name',
      body: (row) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      sortable: true,
    },
    {
      field: 'username',
      header: 'Username',
      body: (row) => row.username,
      sortable: true,
    },
    {
      field: 'jobTitle',
      header: 'Job Title',
      body: (row) => row.jobTitle,
      sortable: true,
    },
    {
      field: 'email',
      header: 'Email',
      body: (row) => row.email,
      sortable: true,
    },
    {
      field: 'lastLogin',
      header: 'Last Login',
      body: (row) => (row.lastLogin ? dayjs(row.lastLogin).format('MM/DD/YYYY h:mm A') : ''),
      sortable: true,
    },
    {
      field: 'role',
      header: 'Role',
      body: (row) => row.role,
      sortable: true,
      width: '150px',
    },
    {
      id: 'edit',
      grow: 0.25,
      header: 'Actions',
      body: (row) => (
        <Button
          background="bg-blue"
          disabled={row.id === user.id}
          type="button"
          onClick={() => {
            const userToEdit = {
              id: row.id,
              firstName: row.firstName,
              lastName: row.lastName,
              email: row.email,
              phoneNumber: row.phoneNumber,
              mfaDevice: row.mfaDevice,
              assignedClients: row.assignedClients.map((client) => ({
                label: client.name,
                id: client.id,
              })),
              role: row.role,
              jobTitle: row.jobTitle,
            }

            setEditUser(userToEdit)
            setShowUserModal(true)
          }}
          label={isAdminUser ? 'Edit' : 'View'}
        />
      ),
      center: true,
    },
  ]

  return (
    <PageContainer withPadding>
      <StateContainer async error={error} lastUpdated={lastUpdated} loading={loading}>
        {() => (
          <div>
            <div className="relative mx-1 mb-5 flex w-full flex-row">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">Users</h3>
            </div>

            <DataTable
              data={updatedUsers}
              columns={columns}
              loading={loading}
              pagination={pagination}
              sorting={sorting}
              hoverEffect={false}
            />
          </div>
        )}
      </StateContainer>

      {showUserModal && (
        <UserModal
          loadingUser={loadingUser}
          onSubmit={onSubmit}
          editUser={editUser}
          roles={{
            allowRoleChange: true,
            key: 'isStaff',
          }}
          setEditUser={setEditUser}
          showUserModal={showUserModal}
          setShowUserModal={setShowUserModal}
          clientsList={clientsList}
        />
      )}
    </PageContainer>
  )
})

export default Users
