import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Utils & Services
import { createAumData } from '../../services/aum.service'
import { toast } from '../../utils/helpers'

const AddAumDataModal = ({ client, closeModal, onSuccess }) => {
  // State
  const [loading, setLoading] = useState(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm({
    defaultValues: {
      year: '',
      aumAmount: '',
      advisoryOnlyAssetsAmount: '',
      uncalledCommittedCapitalAmount: '',
      assetsDisplayedIn: { label: 'Thousands', value: 'Thousands' },
    },
  })

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  /**
   * Handles submitting the create aum data request.
   * @param {object} data
   */
  const onSubmit = async (data) =>
    createAumData(
      client.id,
      {
        ...data,
        year: dayjs(`12/31/${data.year}`, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        assetsDisplayedIn: data.assetsDisplayedIn.value,
      },
      handleError,
      setLoading,
      (m) => {
        handleSuccess(m)
        onSuccess()
      },
    )

  return (
    <Modal
      icon={<PlusIcon className="h-6 fill-white" />}
      open
      title="Add Row"
      loading={loading}
      onClose={closeModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex size-full flex-col gap-4 pt-2"
        >
          <TextInput
            fullWidth
            label="Year"
            name="year"
            type="number"
            error={errors.year && 'This field is required'}
            {...register('year', {
              required: true,
              validate: (value) => {
                if (value.toString().length > 4) return 'No more than 20 digits'
                if (!dayjs(value, 'YYYY').isValid()) return 'Invalid year'
                return true
              },
            })}
          />

          <TextInput
            fullWidth
            icon={<span>$</span>}
            label="AUM Amount"
            name="aumAmount"
            type="number"
            error={errors.aumAmount && 'This field is required'}
            {...register('aumAmount', {
              required: true,
              validate: (value) => {
                if (value.toString().length > 20) return 'No more than 20 digits'
                if (!/^\d*(\.\d{0,2})?$/.test(value)) return 'No more than 2 decimals'
                return true
              },
            })}
          />

          <TextInput
            icon={<span>$</span>}
            fullWidth
            label="Advisory Only Assets Amount"
            name="advisoryOnlyAssetsAmount"
            type="number"
            error={errors.advisoryOnlyAssetsAmount && 'This field is required'}
            {...register('advisoryOnlyAssetsAmount', {
              validate: (value) => {
                if (value.toString().length > 19) return 'No more than 19 digits'
                if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
          />

          <TextInput
            icon={<span>$</span>}
            fullWidth
            label="Uncalled Committed Capital Amount"
            name="uncalledCommittedCapitalAmount"
            type="number"
            error={errors.uncalledCommittedCapitalAmount && 'This field is required'}
            {...register('uncalledCommittedCapitalAmount', {
              validate: (value) => {
                if (value.toString().length > 19) return 'No more than 19 digits'
                if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
          />

          <Controller
            name="assetsDisplayedIn"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Assets Displayed In"
                style={{ width: '100%' }}
                error={errors.assetsDisplayedIn && 'This field is required'}
                onChange={onChange}
                options={[
                  { label: 'Thousands', value: 'Thousands' },
                  { label: 'Millions', value: 'Millions' },
                  { label: 'Billions', value: 'Billions' },
                ]}
              />
            )}
            rules={{ required: true }}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Add',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: closeModal },
      ]}
    />
  )
}

AddAumDataModal.propTypes = {
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default AddAumDataModal
