import { MultiSelect } from 'primereact/multiselect'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { Button } from '../Button'
import { SearchIcon } from '../SearchIcon'
import { TextInput } from '../TextInput'

const DataTableMultiSelect = ({
  value,
  filterCallback,
  handleChange = () => {},
  data,
  placeholder = 'Filter',
  search = false,
}) => {
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])

  // Ref
  const searchInputRef = useRef()

  useEffect(() => {
    if (searchTerm) {
      setFilteredOptions(
        data.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase())),
      )
    } else {
      setFilteredOptions(data)
    }
  }, [searchTerm])

  return (
    <MultiSelect
      filter={search}
      filterTemplate={
        <div className="w-full px-2">
          <TextInput
            autoComplete="off"
            className="w-full rounded-lg py-2.5 pl-6 pr-2 text-sm placeholder:font-normal placeholder:text-gray-600"
            id="search"
            icon={<SearchIcon className="ml-1 w-4 stroke-gray" />}
            endIcon={
              searchTerm ? (
                <Button
                  type="button"
                  onClick={(e) => {
                    setFilteredOptions(data)
                    setSearchTerm('')
                    searchInputRef.current.value = ''

                    // Prevent the dropdown from closing
                    e.stopPropagation()
                  }}
                  ariaLabel="Clear search"
                  iconOnly
                  icon={<XMarkIcon className="h-5 text-blue" aria-hidden="true" />}
                />
              ) : null
            }
            name="search"
            onChange={(e) => {
              setFilteredOptions(
                filteredOptions.filter(
                  (o) =>
                    o.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    o.alwaysDisplay,
                ),
              )

              setSearchTerm(e.target.value)
            }}
            placeholder="Search"
            ref={searchInputRef}
            type="text"
            value={searchTerm}
          />
        </div>
      }
      value={value}
      options={filteredOptions}
      onChange={(e) => {
        filterCallback(e.value)
        handleChange(e.value)
      }}
      placeholder={placeholder}
      optionLabel="label"
      optionValue="id"
      className="w-full"
      closeIcon={null}
    />
  )
}

export default DataTableMultiSelect

DataTableMultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  filterCallback: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
}
