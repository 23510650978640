import { createContext } from 'react'
import { observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  reports: [],
  lastUpdated: null,
}

const TYPES = {
  reports: observable,
  lastUpdated: observable,
}

export class DashboardStore extends BaseStore {
  setReports = (reports) => {
    this.reports = reports
    this.lastUpdated = new Date()
  }
}

export const dashboard = new DashboardStore(INITIAL_STATE, TYPES)
export const DashboardStoreContext = createContext(dashboard)
