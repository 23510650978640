import { useState } from 'react'

export const usePagination = (initialPerPage = 10) => {
  // State
  const [first, setFirst] = useState(0)
  const [perPage, setPerPage] = useState(initialPerPage)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRecords, setTotalRecords] = useState(0)

  /**
   * - It calculates the new page number based on the event's `first` (the index of the first record on the page)
   *   and `rows` (the number of records per page) values.
   * - It updates the current page, the first record index, and the number of rows to be displayed per page in the component's state.
   * - This allows the DataTable to correctly display the next or previous set of data depending on the user's action.
   *
   * @param {object} event - The page change event containing pagination details.
   * @param {number} event.first - The index of the first record on the new page.
   * @param {number} event.rows - The number of records per page.
   */
  const handlePageChange = (event) => {
    const newPage = event.first / event.rows + 1

    setCurrentPage(newPage)
    setFirst(event.first)
    setPerPage(event.rows)
  }

  return {
    pagination: {
      first,
      perPage,
      currentPage,
      totalRecords,
      setFirst,
      setPerPage,
      setCurrentPage,
      handlePageChange,
    },
    setTotalRecords,
  }
}

export const useSorting = (initialField = '', initialOrder = 1) => {
  // State
  const [sortField, setSortField] = useState(initialField)
  const [sortOrder, setSortOrder] = useState(initialOrder)
  const [sortedColumn, setSortedColumn] = useState(initialField ? `${initialField}` : '')

  /**
   * Handles the change in sorting for the DataTable.
   *
   * This function is triggered when the user changes the sorting order of a column in the DataTable.
   * It updates the sorting state and formats the sorting field to snake_case with an optional
   * direction prefix ('-' for descending order).
   *
   * @param {Object} event - The event object containing sorting information.
   * @param {string} event.sortField - The field by which the DataTable is being sorted.
   * @param {number} event.sortOrder - The order of sorting (1 for ascending, -1 for descending).
   */
  const handleSortChange = (event) => {
    const direction = event.sortOrder === 1 ? '' : '-'
    const snakeCaseField = event.sortField.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()

    setSortField(event.sortField)
    setSortOrder(event.sortOrder)
    setSortedColumn(`${direction}${snakeCaseField}`)
  }

  return {
    sorting: {
      sortField,
      sortOrder,
      sortedColumn,
      setSortField,
      setSortOrder,
      setSortedColumn,
      handleSortChange,
    },
  }
}
