import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import React, { Fragment, forwardRef } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 *
 * Select
 *
 */
const Select = forwardRef(
  (
    {
      className = '',
      customButton = null,
      dataTestId = null,
      disabled = false,
      error = null,
      icon = null,
      inputTextClassName,
      label = null,
      onChange,
      options,
      name,
      placeholder = 'Select an Option',
      style = {},
      value = null,
    },
    ref,
  ) => {
    let inputTextColor = 'text-charcoal-400'
    if (value) inputTextColor = 'text-charcoal-900'

    const buttonStyles = mergeClassNames(
      'relative flex w-full appearance-none rounded-md border-[1.5px] border-gray-200 bg-white px-2 py-1 text-sm text-gray-900 placeholder:text-gray-500 focus-within:border-blue',
      className,
    )

    return (
      <div style={style}>
        <Listbox
          className="disabled:opacity-50"
          disabled={disabled}
          onChange={onChange}
          value={value}
          ref={ref}
          data-testid={dataTestId}
        >
          {({ open }) => (
            <div className="flex w-full flex-col place-items-start">
              {label && (
                <label htmlFor={name} className="pb-1 text-sm font-medium text-gray-700">
                  {label}
                </label>
              )}

              <div className={customButton ? 'relative' : 'relative w-full'}>
                <ListboxButton
                  className={customButton ? 'relative cursor-pointer' : buttonStyles}
                  disabled={!!customButton || disabled}
                  onClick={(e) => e.stopPropagation()}
                >
                  {customButton || (
                    <div
                      className={mergeClassNames(
                        disabled && 'cursor-not-allowed opacity-50',
                        'flex w-full items-center justify-between gap-2 text-start',
                      )}
                    >
                      {icon && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                          {icon}
                        </div>
                      )}

                      <span
                        className={mergeClassNames(
                          `block truncate ${inputTextColor} w-full text-sm ${inputTextClassName}`,
                          icon && 'pl-5',
                        )}
                      >
                        {value ? value.label : placeholder}
                      </span>

                      <span className="pointer-events-none flex items-center pr-2">
                        <ChevronDownIcon className="text-charcoal-900 w-8" aria-hidden="true" />
                      </span>
                    </div>
                  )}
                </ListboxButton>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions
                    className={joinClassNames(
                      customButton ? 'w-40' : 'w-full',
                      'absolute z-20 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm',
                    )}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {options.length > 0 ? (
                      options.map((o) => (
                        <ListboxOption
                          key={o.value}
                          className="text-primary group relative flex cursor-default select-none gap-2 px-4 py-2 hover:bg-blue hover:text-white"
                          value={o}
                        >
                          {() => (
                            <div className="flex w-full justify-between gap-4">
                              <span
                                className={joinClassNames(
                                  value && o.value === value.value
                                    ? 'font-semibold'
                                    : 'font-normal',
                                  'block truncate',
                                )}
                              >
                                {o.label}
                              </span>

                              {value && o.value === value.value ? (
                                <span className="flex items-center text-blue">
                                  <CheckIcon className="size-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </div>
                          )}
                        </ListboxOption>
                      ))
                    ) : (
                      <ListboxOption
                        className="text-primary group relative flex cursor-default select-none gap-2 px-4 py-2"
                        value={null}
                      >
                        {() => (
                          <div className="flex w-full justify-between gap-4">
                            <span className="font-normal">No options available</span>
                          </div>
                        )}
                      </ListboxOption>
                    )}
                  </ListboxOptions>
                </Transition>
              </div>
            </div>
          )}
        </Listbox>
        {error ? (
          <div
            className="mt-1 w-full bg-transparent px-2 py-1 text-center"
            aria-hidden={error ? 'false' : 'true'}
          >
            <p className="text-sm font-medium text-error" id={`error:${label}`}>
              {error || ' '}
            </p>
          </div>
        ) : null}
      </div>
    )
  },
)

Select.propTypes = {
  className: PropTypes.string,
  customButton: PropTypes.element,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  icon: PropTypes.element,
  inputTextClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Select
