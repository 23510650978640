import PropTypes from 'prop-types'
import React from 'react'

const StatusTag = ({ value, severity }) => {
  const statusColors = {
    success: 'bg-green-100 text-green-800',
    danger: 'bg-red-100 text-red-800',
    info: 'bg-blue-100 text-blue-800',
    warning: 'bg-yellow-100 text-yellow-800',
  }
  return (
    <span className={`rounded-full px-2 py-1 text-xs font-semibold ${statusColors[severity]}`}>
      {value}
    </span>
  )
}
StatusTag.propTypes = {
  value: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['success', 'danger', 'info', 'warning']).isRequired,
}

export default StatusTag
