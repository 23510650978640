import { action, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  panelOpen: false,
  tasks: [],
  triggerRefresh: false,
}

const TYPES = {
  panelOpen: observable,
  tasks: observable,
  triggerRefresh: observable,
}

export class TaskStore extends BaseStore {
  togglePanel = action(() => {
    this.panelOpen = !this.panelOpen
  })

  addTask = action((task) => {
    this.tasks.unshift(task)
  })

  removeTask = action((task) => {
    this.tasks = this.tasks.filter((t) => t.id !== task.id)
  })

  clearTasks = action(() => {
    this.tasks = []
  })

  updateTask = action((task) => {
    let index = this.tasks.findIndex((t) => t.id === task.id)

    // If the task is not found, it was dismissed from the tracker. Add it back
    // to continue monitoriting progress.
    if (index === -1) {
      this.tasks.unshift(task)
      index = this.tasks.length - 1
    }

    // When the status of a task changes, trigger a refresh
    if (task.data.status !== this.tasks[index].data?.status) {
      this.triggerRefresh = true
    }
    this.tasks[index] = task
  })

  getMatchingTask = (id) => this.tasks.find((t) => t.id === id)

  resetRefresh = action(() => {
    this.triggerRefresh = false
  })
}

export const tasks = new TaskStore(INITIAL_STATE, TYPES)
export const TaskStoreContext = createContext(tasks)
