import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/20/solid'

// Components
import { Button } from '../../components/Button'
import { DataTable } from '../../components/DataTable'
import { Modal } from '../../components/Modal'
import { PageContainer } from '../../components/PageContainer'
import { StateContainer } from '../../components/StateContainer'
import { TextEditor } from '../../components/CustomEditor'
import { TextInput } from '../../components/TextInput'

// Services
import { getClient } from '../../services/clients.service'
import {
  createClientBenchmark,
  deleteClientBenchmark,
  getClientBenchmarks,
  updateClientBenchmark,
} from '../../services/benchmarks.service'

// Stores
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Utils & Hooks
import { toast } from '../../utils/helpers'
import { usePagination, useSorting } from '../../hooks/DataTableManagement'

/**
 *
 * Benchmarks
 *
 */
const Benchmarks = observer(() => {
  // Context
  const { clientId } = useParams()
  const { client, setClient } = useContext(ClientDashboardStoreContext)

  // Pagination
  const { pagination, setTotalRecords } = usePagination(10)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('name')
  const { sortedColumn } = sorting

  // State
  const [loading, setLoading] = useState(true)
  const [loadingBenchmarks, setLoadingBenchmarks] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [error, setError] = useState(null)
  const [benchmarks, setBenchmarks] = useState([])
  const [showAddBenchmarkModal, setShowAddBenchmarkModal] = useState(false)

  const BASE_URL = `/clients/${clientId}/benchmarks/?`

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  /**
   * Gets the updated list of benchmarks; updates pagination.
   * @param {string} url
   */
  const getUpdatedBenchmarks = async (url) => {
    const response = await getClientBenchmarks(url, setError, setLoadingBenchmarks)

    if (response) {
      setTotalRecords(response.count)
      setBenchmarks(response.results)
    }
  }

  useEffect(() => {
    if (clientId) {
      const getUpdatedClient = async () => {
        const updatedClient = await getClient(clientId, setError, setLoading)

        if (updatedClient) {
          setClient(updatedClient)
        }
      }

      getUpdatedClient()
    }
  }, [clientId])

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of benchmarks.
   */
  useEffect(() => {
    if (clientId) {
      getUpdatedBenchmarks(
        `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage])

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      component: '',
    },
  })

  /**
   * Handles form submission by creating a new benchmark for the client.
   * @param {object} data
   */
  const onSubmit = async (data) =>
    createClientBenchmark(client.id, data, handleErrors, setLoading, (m) => {
      handleSuccess(m)
      reset()
      getUpdatedBenchmarks(
        `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
      )
      setShowAddBenchmarkModal(false)
    })

  /**
   * Handles row edit completion by updating the local `benchmark` with new data and
   * submitting it to the backend.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData, index }) => {
    const oldBenchmarks = [...benchmarks]
    const updatedData = [...benchmarks]
    updatedData[index] = newData

    // Optimistically update the data to prevent showing internal values
    setBenchmarks(updatedData)

    await updateClientBenchmark(
      clientId,
      newData,
      (m) => {
        // Reset back to previous value
        setBenchmarks(oldBenchmarks)
        handleErrors(m)
      },
      () => {},
      (m) => handleSuccess(m),
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loading}>
        <div className="flex size-full flex-col overflow-y-auto bg-background">
          <div className="space-y-6">
            <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">Benchmarks</h2>

              <Button label="Add Benchmark" onClick={() => setShowAddBenchmarkModal(true)} />
            </div>

            <DataTable
              data={benchmarks}
              columns={[
                {
                  field: 'name',
                  header: 'Name',
                  editor: TextEditor,
                  style: { width: '50%' },
                  sortable: true,
                },
                {
                  field: 'component',
                  header: 'Component',
                  editor: TextEditor,
                  style: { width: '50%' },
                  sortable: true,
                },
              ]}
              loadingDelete={loadingDelete}
              hoverEffect={false}
              loading={loadingBenchmarks}
              pagination={pagination}
              sorting={sorting}
              onRowEditComplete={onRowEditComplete}
              onRowDeleteConfirm={(row) => {
                deleteClientBenchmark(clientId, row.id, handleErrors, setLoadingDelete, () => {
                  handleSuccess('Benchmark deleted.')
                  getUpdatedBenchmarks(
                    `${BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                  )
                })
              }}
              areRowsDeleteable
              areRowsEditable
              rowDeleteConfirm={(row) => ({
                title: `Delete Benchmark`,
                type: 'warning',
                message: (
                  <div className="flex flex-col">
                    <span className="">Are you sure you want to delete the benchmark:</span>
                    <span className="font-semibold">
                      {row.name} ({row.component})
                    </span>
                  </div>
                ),
              })}
            />
          </div>
        </div>
      </StateContainer>

      <Modal
        icon={<PlusIcon className="h-6 fill-white" />}
        open={showAddBenchmarkModal}
        title="Add Benchmark"
        loading={loading}
        onClose={() => {
          setShowAddBenchmarkModal(false)
          reset()
        }}
        content={
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-8 flex size-full flex-col gap-4 pt-2"
          >
            <TextInput
              fullWidth
              label="Name"
              name="name"
              error={errors.name && 'This field is required'}
              {...register('name', {
                required: true,
              })}
            />

            <TextInput
              fullWidth
              label="Component"
              name="component"
              error={errors.component && 'This field is required'}
              {...register('component', {
                required: true,
              })}
            />
          </form>
        }
        actions={[
          {
            type: 'submit',
            label: 'Add',
            onClick: handleSubmit(onSubmit),
          },
          {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => {
              setShowAddBenchmarkModal(false)
              reset()
            },
          },
        ]}
      />
    </PageContainer>
  )
})

export default Benchmarks
