/** @todo Fix import cycle */
// eslint-disable-next-line import/no-cycle
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the list of clients the user has access to.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClients = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    setSuccess(data)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes a client.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */

export const deleteClient = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${id}/`)
    setLoading(false)
    setSuccess(true)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Get the client by id.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClient = async (
  id,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(
      `/clients/${id}/?expand=owner,project_manager,resource,pending_data_import,latest_successful_import`,
    )

    setLoading(false)
    setSuccess(true)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/** --------------------------- Client: Related --------------------------- */

/**
 * Get the composites for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientPortfolioDataComposites = async (
  id,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/composites?limit=99999`) // Set high limit to prevent pagination

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the accounts for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientPortfolioDataAccounts = async (
  id,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/accounts?limit=99999`) // Set high limit to prevent pagination

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the portfolio periods for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientPortfolioDataPeriods = async (
  id,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/portfolio_periods`)

    setLoading(false)
    return data.periods
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the portfolios for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientPortfolioDataPortfolios = async (
  id,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/portfolios?limit=99999`) // Set high limit to prevent pagination

    setLoading(false)
    return data.results
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the years for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientAumDataYears = async (id, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/years`)

    setLoading(false)
    return data.years
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the benchmark periods for the specified client by `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientBenchmarkDataPeriods = async (
  id,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const data = await axios.get(`/clients/${id}/benchmark_periods`)

    setLoading(false)
    return data.years
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Get the owners for all clients.
 * @param {func} setError
 * @returns `data` results
 */
export const getOwners = async (setError = () => {}) => {
  try {
    const data = await axios.get(`/clients/owners`)

    return data.owners
  } catch (err) {
    setError(getErrorMessage(err))
    return null
  }
}

/**
 * Get the project managers for all clients.
 * @param {func} setError
 * @returns `data` results
 */
export const getProjectManagers = async (setError = () => {}) => {
  try {
    const data = await axios.get(`/clients/project_managers`)
    return data.projectManagers
  } catch (err) {
    setError(getErrorMessage(err))
    return null
  }
}

/**
 * Get the resources for all clients.
 * @param {func} setError
 * @returns `data` results
 */
export const getResources = async (setError = () => {}) => {
  try {
    const data = await axios.get(`/clients/resources`)
    return data.resources
  } catch (err) {
    setError(getErrorMessage(err))
    return null
  }
}
