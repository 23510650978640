// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the benchmarks for the specified `clientId`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientBenchmarks = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new benchmark with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createClientBenchmark = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/benchmarks/`, payload)
    setSuccess('New benchmark added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the benchmark with the specified `benchmarkId` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` for the new benchmark
 */
export const updateClientBenchmark = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(`/clients/${clientId}/benchmarks/${payload.id}/`, payload)
    setSuccess(`${response.name} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the benchmark with the specified `benchmarkId` and `payload`.
 * @param {string} clientId
 * @param {string} benchmarkId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteClientBenchmark = async (
  clientId,
  benchmarkId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/benchmarks/${benchmarkId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

// ---------------------------------- Data ----------------------------------

/**
 * Gets the benchmark data using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getBenchmarkData = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new benchmark data item with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createBenchmarkData = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/benchmark-data/`, payload)
    setSuccess('New row added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the benchmark data with the specified `id` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` for the new benchmark data
 */
export const updateBenchmarkData = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/clients/${clientId}/benchmark-data/${payload.id}/?expand=benchmark`,
      payload,
    )
    setSuccess(`${response?.benchmark.name || 'Benchmark Data'} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the benchmark data with the specified `benchmarkId` and `payload`.
 * @param {string} clientId
 * @param {string} benchmarkId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteBenchmarkData = async (
  clientId,
  benchmarkId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/benchmark-data/${benchmarkId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
