import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/20/solid'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Utils & Services
import { createPortfolioData } from '../../services/portfolio.service'
import { toast } from '../../utils/helpers'

const BOOL_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const AddPortfolioDataModal = ({
  accounts,
  client,
  closeModal,
  composites,
  periods,
  portfolios = [],
  onSuccess,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    clearErrors,
    register,
  } = useForm({
    defaultValues: {
      composite: '',
      account: '',
      period: '',
      portfolio: null,
      beginningValue: 0,
      endingValue: 0,
      grossReturn: 0,
      netReturn: 0,
      isIncluded: BOOL_OPTIONS[0],
      isFeePaying: BOOL_OPTIONS[0],
      hasBundledFee: BOOL_OPTIONS[0],
      comments: '',
    },
  })

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  /**
   * Handles submitting the create portfolio data request.
   * @param {object} data
   */
  const onSubmit = async (data) =>
    createPortfolioData(
      client.id,
      {
        ...data,
        account: data.account.id,
        composite: data.composite.id,
        grossReturn: data.grossReturn > 0 ? data.grossReturn / 100 : data.grossReturn,
        period: data.period.value,
        portfolio: data.portfolio?.id || null,
        netReturn: data.netReturn > 0 ? data.netReturn / 100 : data.netReturn,
        isIncluded: data.isIncluded.value,
        isFeePaying: data.isFeePaying.value,
        hasBundledFee: data.hasBundledFee.value,
      },
      handleError,
      setLoading,
      (m) => {
        handleSuccess(m)
        onSuccess()
      },
    )

  return (
    <Modal
      icon={<PlusIcon className="h-6 fill-white" />}
      open
      title="Add Row"
      loading={loading}
      onClose={closeModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex size-full flex-col gap-4 pt-2"
        >
          <Controller
            name="composite"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Composite"
                style={{ width: '100%' }}
                error={errors.composite && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('composite')
                }}
                options={composites}
              />
            )}
            rules={{ required: true }}
          />

          {client.hasAnyHouseholdData && (
            <Controller
              name="portfolio"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  label="Portfolio"
                  style={{ width: '100%' }}
                  error={errors.portfolio && 'This field is required'}
                  onChange={(option) => {
                    onChange(option)
                    clearErrors('portfolio')
                  }}
                  options={portfolios}
                />
              )}
              rules={{ required: true }}
            />
          )}

          <Controller
            name="account"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Account"
                style={{ width: '100%' }}
                error={errors.account && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('account')
                }}
                options={accounts}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="period"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Period"
                style={{ width: '100%' }}
                error={errors.period && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('period')
                }}
                options={periods}
              />
            )}
            rules={{ required: true }}
          />

          <div className="flex w-full flex-col gap-2 sm:flex-row">
            <TextInput
              fullWidth
              icon={<span>$</span>}
              label="Beginning Value"
              name="beginningValue"
              type="number"
              error={errors.beginningValue && 'This field is required'}
              {...register('beginningValue', {
                required: true,
                validate: (value) => {
                  if (value.toString().length > 20) return 'No more than 20 digits'
                  if (!/^\d*(\.\d{0,2})?$/.test(value)) return 'No more than 2 decimals'
                  return true
                },
              })}
            />

            <TextInput
              fullWidth
              icon={<span>$</span>}
              label="Ending Value"
              name="endingValue"
              type="number"
              error={
                errors?.endingValue
                  ? errors.endingValue?.message || 'This field is required'
                  : null
              }
              {...register('endingValue', {
                required: true,
                validate: (value) => {
                  if (value.toString().length > 20) return 'No more than 20 digits'
                  if (!/^\d*(\.\d{0,2})?$/.test(value)) return 'No more than 2 decimals'
                  return true
                },
              })}
            />
          </div>

          <div className="flex w-full flex-col gap-2 sm:flex-row">
            <TextInput
              endIcon={<span>%</span>}
              fullWidth
              label="Gross Return"
              name="grossReturn"
              type="number"
              error={errors.grossReturn && 'This field is required'}
              {...register('grossReturn', {
                required: true,
                validate: (value) => {
                  if (value.toString().length > 19) return 'No more than 19 digits'
                  if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                  return true
                },
              })}
            />

            <TextInput
              endIcon={<span>%</span>}
              fullWidth
              label="Net Return"
              name="netReturn"
              type="number"
              error={errors.netReturn && 'This field is required'}
              {...register('netReturn', {
                required: true,
                validate: (value) => {
                  if (value.toString().length > 19) return 'No more than 19 digits'
                  if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                  return true
                },
              })}
            />
          </div>

          <Controller
            name="isIncluded"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Included"
                style={{ width: '100%' }}
                error={errors.isIncluded && 'This field is required'}
                onChange={onChange}
                options={BOOL_OPTIONS}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="isFeePaying"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Fee Paying"
                style={{ width: '100%' }}
                error={errors.isFeePaying && 'This field is required'}
                onChange={onChange}
                options={BOOL_OPTIONS}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="hasBundledFee"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Has Bundled Fee"
                style={{ width: '100%' }}
                error={errors.hasBundledFee && 'This field is required'}
                onChange={onChange}
                options={BOOL_OPTIONS}
              />
            )}
            rules={{ required: true }}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Add',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: closeModal },
      ]}
    />
  )
}

AddPortfolioDataModal.propTypes = {
  accounts: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  composites: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  periods: PropTypes.array.isRequired,
  portfolios: PropTypes.array,
}

export default AddPortfolioDataModal
