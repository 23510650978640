// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the list of reports the user has access to.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getReports = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the report with the specified `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` updated report
 */
export const getReport = async (
  clientId,
  reportId,
  setError = () => {},
  setLoading = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/clients/${clientId}/reports/${reportId}/?expand=generated_by,client`,
    )

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Cancels the report with the specified `id`.
 * @param {string} id
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` updated report
 */
export const cancelReport = async (clientId, id, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.patch(`/clients/${clientId}/reports/${id}/?expand=generated_by`, {
      mark_as_canceled: true,
    })

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new report with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` for the new report
 */
export const addReport = async (clientId, payload, setError = () => {}, setLoading = () => {}) => {
  setLoading(true)

  try {
    const response = await axios.post(`/clients/${clientId}/reports/?expand=client`, payload)

    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
