/* @flow */
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { PasswordInput } from '../../components/PasswordInput'
import { TextInput } from '../../components/TextInput'

// Images
import logo from '../../assets/images/logo.svg'

// Service
import { login } from '../../services/user.service'

// Store
import { UserStoreContext } from '../../stores/UserStore'

// Utils
import { toast, verifyPassword } from '../../utils/helpers'

/**
 *
 * Login
 *
 */
const Login = () => {
  // Context
  const location = useLocation()
  const navigate = useNavigate()
  const { setCurrentUser } = useContext(UserStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  useEffect(() => {
    if (!location.pathname.includes('login')) navigate('/login', { replace: true })
  }, [location])

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  /**
   * Handles form submission
   * - Redirects to verification page if successful
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const loginData = await login(data, handleError, setLoading)

    if (loginData) {
      setCurrentUser({ username: data.username, ...loginData })
      navigate('/verification', {
        state: { password: data.password },
      })
    }
  }

  /**
   * Check if the user has been redirected to this page with a URL parameter
   *
   * - `confirmed` indicates that a user has been sent to this page from `ConfirmAccount`
   * - `reset` indicates that a user has been sent to this page from `ResetPassword`
   * - `created` indicates that a user has been sent to this page from an email confirmation link
   */
  useEffect(() => {
    if (location && location.search === '?confirmed=true')
      handleSuccess('Your email address has been confirmed. You may now log in.')
    else if (location && location.search === '?reset=true')
      handleSuccess('Your password has been changed. You may now log in.')
    else if (location && location.search === '?created=true')
      handleSuccess('Your account has been created. You may now log in.')
  }, [])

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-2 sm:px-6 lg:px-8">
      <img alt="Longs Peak Advisory" src={logo} className="mx-auto h-24 w-auto" />
      <FormHeader title="Sign in to your account" />

      <div className="mx-4 mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="rounded-lg bg-white px-4 py-10 shadow sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-1">
              <TextInput
                data-testid="username"
                error={errors.username && 'This field is required'}
                autoComplete="username"
                id="username"
                name="username"
                placeholder="Username"
                required
                {...register('username', { required: true })}
              />

              <Controller
                error={errors.password && 'This field is required'}
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    const err = verifyPassword(value)
                    if (err) return err
                    return undefined
                  },
                }}
                render={({ field }) => (
                  <PasswordInput
                    error={
                      errors.password && (errors.password.message || 'This field is required')
                    }
                    id="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex justify-end">
              <Link
                to="/password-reset-request"
                className="text-sm font-medium leading-6 text-blue-700 hover:text-blue-dark hover:underline"
              >
                Forgot your password?
              </Link>
            </div>

            <Button fullWidth label="Sign in" loading={loading} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
