import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'

// Services
import { getClient } from '../../services/clients.service'
import { addReport, cancelReport, getReport } from '../../services/reports.service'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'
import { TaskStoreContext } from '../../stores/TaskStore'
import { UserStoreContext } from '../../stores/UserStore'

const SLIDER_LABEL_STYLE = 'text-gray-dark text-sm font-medium'

const Report = observer(() => {
  // Context
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { client, setClient } = useContext(ClientDashboardStoreContext)
  const { addTask, getMatchingTask, resetRefresh, triggerRefresh } = useContext(TaskStoreContext)
  const { user } = useContext(UserStoreContext)

  const newReport = location.pathname.includes('new-report')
  const { clientId, reportId } = params
  const matchingTask = getMatchingTask(reportId)

  // State
  const [loading, setLoading] = useState(false)
  const [loadingClient, setLoadingClient] = useState(true)
  const [error, setError] = useState(null)
  const [cancelLoading, setCancelLoading] = useState(null)
  const [cancelError, setCancelError] = useState(null)
  const [report, setReport] = useState(null)

  const { control, handleSubmit, register, reset, unregister, watch } = useForm({
    defaultValues: {
      sendEmailNotification: false,
      additionalEmails: null,
      isHousehold: false,
      configParagraphIndent: 4,
    },
  })

  const watchFlagLastMonth = watch('flagLastMonth')
  const watchGipsFile = watch('generateGIPSFile')

  const getUpdatedData = async () => {
    const [updatedClient, updatedreport] = await Promise.all([
      getClient(clientId, setError, setLoadingClient),
      reportId && getReport(clientId, reportId, setError, setLoading),
    ])

    setClient(updatedClient)
    setReport(updatedreport)
  }

  useEffect(() => {
    if (clientId) {
      resetRefresh()
      getUpdatedData()
    }
  }, [clientId, triggerRefresh, reportId])

  useEffect(() => {
    if (watchFlagLastMonth) {
      register('flagLastMonthNum')
    } else {
      unregister('flagLastMonthNum')
    }
  }, [register, unregister, watchFlagLastMonth])

  useEffect(() => {
    if (reportId && matchingTask) {
      // We want to refresh the data if the status has changed.
      if (
        report &&
        (report.status !== matchingTask.status ||
          report.qcOutputFileStatus !== matchingTask.qcOutputFileStatus ||
          report.outputFileStatus !== matchingTask.outputFileStatus ||
          report.resultsFileStatus !== matchingTask.resultsFileStatus ||
          report.verifierFileStatus !== matchingTask.verifierFileStatus ||
          report.nixonFileStatus !== matchingTask.nixonFileStatus ||
          report.gipsFileStatus !== matchingTask.gipsFileStatus)
      ) {
        setReport({ ...report, ...matchingTask })
      }
    }
  }, [matchingTask])

  /**
   * Handles submitting the form to create a new report.
   * @param {object} data
   */
  const onSubmit = async (data) => {
    const updatedData = { ...data }
    updatedData.generatedBy = user.id

    // Mark skipped files
    if (!updatedData.generateOutputFile) {
      updatedData.outputFileStatus = 'Skipped'
    }
    if (!updatedData.generateQCOutputFile) {
      updatedData.qcOutputFileStatus = 'Skipped'
    }
    if (!updatedData.generateResultsFile) {
      updatedData.resultsFileStatus = 'Skipped'
    }
    if (!updatedData.generateVerifierFile) {
      updatedData.verifierFileStatus = 'Skipped'
    }
    if (!updatedData.generateGIPSFile) {
      updatedData.gipsFileStatus = 'Skipped'
    }
    delete updatedData.generateOutputFile
    delete updatedData.generateQCOutputFile
    delete updatedData.generateResultsFile
    delete updatedData.generateVerifierFile
    delete updatedData.generateGIPSFile

    // Create report
    const createdReport = await addReport(clientId, updatedData, setError, setLoading)
    if (createdReport) {
      addTask({ type: 'report', id: createdReport.id, data: createdReport })
      reset()
      navigate(`/clients/${clientId}/reports/${createdReport.id}`)
    }
  }

  /**
   * Handles canceling the report.
   */
  const onCancelReport = async () => {
    // Cancel report and update the report state
    const response = await cancelReport(clientId, reportId, setCancelError, setCancelLoading)
    setReport(response)
  }

  /**
   * Determines the background color based on the file status.
   * @param {string} status
   */
  const getReportButtonBackground = (status) => {
    if (status === 'Complete') {
      return 'bg-green'
    }

    if (status === 'Failed') {
      return 'bg-red'
    }

    return 'bg-white'
  }

  /**
   * Renders the error list for the report.
   */
  const getFileErrorList = () => {
    const errorList = []
    if (report?.outputFileStatus === 'Failed') {
      errorList.push(`Output File: ${report?.outputFileStatusMessage}`)
    }
    if (report?.verifierFileStatus === 'Failed') {
      errorList.push(`Verifier File: ${report?.verifierFileStatusMessage}`)
    }
    if (report?.gipsFileStatus === 'Failed') {
      errorList.push(`GIPS Report: ${report?.gipsFileStatusMessage}`)
    }
    if (report?.qcOutputFileStatus === 'Failed') {
      errorList.push(`QC Output File: ${report?.qcOutputFileStatusMessage}`)
    }

    return (
      <ul className="list-disc">
        {errorList.map((e) => (
          <li key={e} style={{ marginLeft: '15px' }}>
            {e}
          </li>
        ))}
      </ul>
    )
  }

  /**
   * Renders the status of the report, with a tooltip if the report failed.
   * @param {string} status
   * @param {string} statusMessage
   */
  const renderStatusCell = (status, statusMessage) => {
    // Failed or Partially Failed statuses display a badge and a tooltip
    if (status === 'Failed' || status === 'Partially Failed') {
      return (
        <div className="flex flex-row items-center gap-2">
          <span className="inline-flex rounded-full bg-red-100 px-3 py-1 text-sm font-semibold leading-5 text-red-800">
            {status}
          </span>

          <Tooltip
            content={
              <div className="w-[280px] rounded-lg bg-white p-4 shadow-lg ring-1 ring-black/5">
                <span className="text-sm font-medium">
                  {statusMessage || 'No failure message provided'}
                </span>
                <span>{getFileErrorList()}</span>
              </div>
            }
            placement="bottom-end"
          >
            <ChatBubbleBottomCenterTextIcon className="h-5 text-black sm:h-6" />
          </Tooltip>
        </div>
      )
    }

    // All other statuses display a badge with a color based on the status
    let extraClass = 'bg-gray-100 text-grey-800'
    if (status === 'Pending') {
      extraClass = 'bg-yellow-100 text-yellow-800'
    } else if (
      status === 'Processing Data' ||
      status === 'Ready for Report Generation' ||
      status === 'Preparing Reports'
    ) {
      extraClass = 'bg-blue-100 text-blue-800'
    } else if (status === 'Complete') {
      extraClass = 'bg-green-100 text-green-800'
    }

    return (
      <span
        className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${extraClass}`}
      >
        {status}
      </span>
    )
  }

  const renderDownloadButton = (displayName, fileStatus, fileUrl) => (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
      <div className="flex w-0 flex-1 items-center">
        <svg
          className="size-5 shrink-0 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fillRule="evenodd"
            d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
            clipRule="evenodd"
          />
        </svg>
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{displayName}</span>
        </div>
      </div>
      <div className="ml-4 shrink-0">
        <Button
          type="Button"
          background={getReportButtonBackground(fileStatus)}
          disabled={fileUrl === null}
          onClick={() => window.open(fileUrl, '_blank')}
          label="Download"
        />
      </div>
    </li>
  )

  if (newReport && client && client.portfolioDataStatus !== 'Ready to Run CTV6') {
    navigate(`/clients/${clientId}/dashboard`)
  }

  if (newReport) {
    return (
      <StateContainer error={error} loading={loadingClient}>
        <div className="flex size-full flex-col overflow-y-auto">
          <ClientHeader client={client} />
          <div className="flex size-full flex-col overflow-y-auto bg-background px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="pb-5 sm:flex sm:items-center sm:justify-between">
              <h2 className="text-xl font-semibold leading-6 text-gray-900">
                Generate a New Report
              </h2>
            </div>

            <div className="mt-2 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                {client?.hasAllHouseholdData && (
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Report Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <input
                        id="isHousehold"
                        name="isHousehold"
                        {...register('isHousehold', { required: false })}
                        type="checkbox"
                        className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                      />
                      <label
                        htmlFor="isHousehold"
                        className="px-2 text-sm font-medium text-gray-900"
                      >
                        Household Report
                      </label>
                    </dd>
                  </div>
                )}
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Reports to Generate
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <div>
                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          id="outputFile"
                          name="Output File"
                          {...register('generateOutputFile', { required: false, value: true })}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label htmlFor="outputFile" className="text-sm font-medium text-gray-900">
                          Output File
                        </label>
                      </div>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          id="qcOutputFile"
                          name="QC Output File"
                          {...register('generateQCOutputFile', { required: false })}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label
                          htmlFor="qcOutputFile"
                          className="text-sm font-medium text-gray-900"
                        >
                          QC Output File
                        </label>
                      </div>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          id="resultsFile"
                          name="Results File"
                          {...register('generateResultsFile', { required: false })}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label htmlFor="resultsFile" className="text-sm font-medium text-gray-900">
                          Results File
                        </label>
                      </div>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          id="verifierFile"
                          name="Verifier File"
                          {...register('generateVerifierFile', { required: false })}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label
                          htmlFor="verifierFile"
                          className="text-sm font-medium text-gray-900"
                        >
                          Verifier File
                        </label>
                      </div>
                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          id="gipsReport"
                          name="GIPS Report"
                          {...register('generateGIPSFile', { required: false })}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label htmlFor="gipsReport" className="text-sm font-medium text-gray-900">
                          GIPS Report
                        </label>
                      </div>
                    </div>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">Report Settings</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <h3 className="pb-2 text-sm font-medium leading-6 text-gray-900">
                      Excel Reports
                    </h3>
                    <div className="flex flex-row place-items-center gap-2">
                      <input
                        id="addUniqueID"
                        name="addUniqueID"
                        {...register('addUniqueID', { required: false })}
                        type="checkbox"
                        className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                      />
                      <label htmlFor="addUniqueID" className="text-sm font-medium text-gray-900">
                        Add Unique ID Column?
                      </label>
                    </div>

                    <div className="flex flex-row place-items-center gap-2">
                      <input
                        id="flagLastMonth"
                        name="flagLastMonth"
                        {...register('flagLastMonth', { required: false })}
                        type="checkbox"
                        className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        min="0"
                        step="1"
                      />
                      <label htmlFor="flagLastMonth" className="text-sm font-medium text-gray-900">
                        Specify Number of Most Recent Months of Outliers to Include
                      </label>
                    </div>

                    {watchFlagLastMonth && (
                      <div className="flex flex-row place-items-center gap-2 pt-2">
                        <input
                          id="flagLastMonthNum"
                          name="flagLastMonthNum"
                          {...register('flagLastMonthNum', { required: false })}
                          type="number"
                          className="h-8 w-16 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label
                          htmlFor="flagLastMonthNum"
                          className="text-sm font-medium text-gray-900"
                        >
                          Number of months to include
                        </label>
                      </div>
                    )}

                    {watchGipsFile && (
                      <>
                        <h3 className="pb-2 pt-4 text-sm font-medium leading-6 text-gray-900">
                          GIPS Reports
                        </h3>

                        <div className="flex flex-row place-items-center gap-2">
                          <input
                            id="includeSecTable"
                            name="includeSecTable"
                            {...register('includeSecTable', { required: false, value: true })}
                            type="checkbox"
                            className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                          />
                          <label
                            htmlFor="includeSecTable"
                            className="text-sm font-medium text-gray-900"
                          >
                            SEC 1,5,10 Table Inclusion
                          </label>
                        </div>

                        <Controller
                          name="configParagraphIndent"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <>
                              <label
                                htmlFor="stepsRange"
                                className="text-primary text-md mb-2 block font-medium"
                              >
                                Space Between Paragraphs
                              </label>

                              <input
                                type="range"
                                min="3"
                                max="9"
                                onChange={onChange}
                                value={value}
                                step="1"
                                className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                                id="stepsRange"
                              />
                            </>
                          )}
                          rules={{ required: true }}
                        />
                        <div className="mx-1 flex w-full justify-between">
                          <span className={SLIDER_LABEL_STYLE}>3</span>
                          <span className={SLIDER_LABEL_STYLE}>4</span>
                          <span className={SLIDER_LABEL_STYLE}>5</span>
                          <span className={SLIDER_LABEL_STYLE}>6</span>
                          <span className={SLIDER_LABEL_STYLE}>7</span>
                          <span className={SLIDER_LABEL_STYLE}>8</span>
                          <span className={SLIDER_LABEL_STYLE}>9</span>
                        </div>
                      </>
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Email Notifications
                  </dt>
                  <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <p className="pb-2 text-sm leading-6 text-gray-500">
                      Notifications for this report are sent to you by default when enabled.
                      Optionally, you can input an additional email address to receive
                      notifications.
                    </p>

                    <div className="flex flex-row place-items-center gap-2">
                      <input
                        id="sendEmailNotification"
                        name="sendEmailNotification"
                        {...register('sendEmailNotification', { required: false })}
                        type="checkbox"
                        className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                      />
                      <label
                        htmlFor="sendEmailNotification"
                        className="text-sm font-medium text-gray-900"
                      >
                        Send email notification when Report is complete?
                      </label>
                    </div>

                    <TextInput
                      data-testid="email"
                      fullWidth
                      id="additionalEmails"
                      inputStyles="rounded-none font-"
                      name="additionalEmails"
                      label=""
                      placeholder="Emails (comma-separated list)"
                      {...register('additionalEmails', { required: false })}
                    />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="flex w-full flex-row justify-end gap-4">
              <Button
                background="bg-white"
                loading={loading}
                onClick={() => navigate(-1)}
                outlined
                label="Cancel"
              />

              <Button
                onClick={handleSubmit(onSubmit)}
                label="Start Report Generation"
                loading={loading}
                type="button"
              />
            </div>
          </div>
        </div>
      </StateContainer>
    )
  }

  if (reportId) {
    return (
      <StateContainer error={error} loading={loadingClient}>
        {report && (
          <div className="flex size-full flex-col overflow-y-auto bg-background pb-12">
            <ClientHeader client={client} />
            <div className="flex size-full flex-col overflow-y-auto bg-background px-4 pb-12 pt-6 sm:px-6 lg:px-8">
              <div className="pb-2 sm:flex sm:items-center sm:justify-between">
                <h2 className="text-xl font-semibold leading-6 text-gray-900">
                  Report {dayjs(report.createdAt).format('MM/DD/YYYY HH:mm A')}
                </h2>
                {renderStatusCell(report.status, report.statusMessage)}
              </div>

              <div className="flex flex-row flex-wrap gap-2 pb-5">
                <span className="tracking-light text-xs font-semibold text-gray-500">
                  Generated By:
                </span>
                <span className="tracking-light text-xs text-gray-500">
                  {report.generatedBy.firstName} {report.generatedBy.lastName} (
                  {report.generatedBy.email})
                </span>
              </div>

              <div className="mt-2 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Report Type</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <input
                        disabled
                        checked={report.isHousehold}
                        type="checkbox"
                        className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        id="isHousehold"
                      />
                      <label htmlFor="isHousehold" className="px-2 font-medium text-gray-900">
                        Household Report
                      </label>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Report Settings
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <h3 className="pb-2 text-sm font-medium leading-6 text-gray-900">
                        Excel Reports
                      </h3>
                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          disabled
                          checked={report.addUniqueId}
                          type="checkbox"
                          id="addUniqueID"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                        />
                        <label htmlFor="addUniqueID" className="text-sm font-medium text-gray-900">
                          Add Unique ID Column?
                        </label>
                      </div>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          disabled
                          checked={report.flagLastMonth}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                          id="flagLastMonth"
                        />
                        <label
                          htmlFor="flagLastMonth"
                          className="text-sm font-medium text-gray-900"
                        >
                          Specify Number of Most Recent Months of Outliers to Include
                        </label>
                      </div>

                      {watchFlagLastMonth && (
                        <div className="flex flex-row place-items-center gap-2 pt-2">
                          <input
                            disabled
                            value={report.flagLastMonthNum}
                            type="number"
                            className="h-8 w-16 rounded border-gray-300 text-blue focus:ring-blue-600"
                            id="flagLastMonthNum"
                          />
                          <label
                            htmlFor="flagLastMonthNum"
                            className="text-sm font-medium text-gray-900"
                          >
                            Number of months to include
                          </label>
                        </div>
                      )}

                      <h3 className="pb-2 pt-4 text-sm font-medium leading-6 text-gray-900">
                        GIPS Reports
                      </h3>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          disabled
                          checked={report.includeSecTable}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                          id="includeSecTable"
                        />
                        <label
                          htmlFor="includeSecTable"
                          className="text-sm font-medium text-gray-900"
                        >
                          SEC 1,5,10 Table Inclusion
                        </label>
                      </div>

                      <label
                        htmlFor="steps-range"
                        className="text-primary text-md mb-2 block font-medium"
                      >
                        Space Between Paragraphs
                      </label>

                      <input
                        disabled
                        type="range"
                        min="3"
                        max="9"
                        value={report.configParagraphIndent}
                        step="1"
                        className="h-2 w-full cursor-pointer appearance-none rounded-lg bg-gray-200"
                        id="steps-range"
                      />
                      <div className="mx-1 flex w-full justify-between">
                        <span className={SLIDER_LABEL_STYLE}>3</span>
                        <span className={SLIDER_LABEL_STYLE}>4</span>
                        <span className={SLIDER_LABEL_STYLE}>5</span>
                        <span className={SLIDER_LABEL_STYLE}>6</span>
                        <span className={SLIDER_LABEL_STYLE}>7</span>
                        <span className={SLIDER_LABEL_STYLE}>8</span>
                        <span className={SLIDER_LABEL_STYLE}>9</span>
                      </div>
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Email Notifications
                    </dt>
                    <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <p className="pb-2 text-sm leading-6 text-gray-500">
                        Notifications for this report are sent to you by default when enabled.
                        Optionally, you can input an additional email address to receive
                        notifications.
                      </p>

                      <div className="flex flex-row place-items-center gap-2">
                        <input
                          disabled
                          checked={report.sendEmailNotification}
                          type="checkbox"
                          className="size-4 rounded border-gray-300 text-blue focus:ring-blue-600"
                          id="sendEmailNotification"
                        />
                        <label
                          htmlFor="sendEmailNotification"
                          className="font-medium text-gray-900"
                        >
                          Send email notification when Report is complete?
                        </label>
                      </div>

                      <TextInput
                        disabled
                        fullWidth
                        inputStyles="rounded-none font-"
                        value={report.additionalEmails || ''}
                        label=""
                      />
                    </dd>
                  </div>

                  {report.status !== 'Pending' && report.status !== 'Processing Data' && (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Alerts and Warnings
                      </dt>
                      <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {report?.messages?.errorMessages && (
                          <div>
                            <div
                              className="font-bold text-red-700"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: report?.messages?.errorMessages.status,
                              }}
                            ></div>
                            <div
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: report?.messages?.errorMessages.message,
                              }}
                            ></div>
                            <div
                              className="text-gray-500"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: report?.messages?.errorMessages.content,
                              }}
                            ></div>
                          </div>
                        )}
                        {_.map(report?.messages?.warningMessages, (message) => (
                          <div>
                            <div
                              className="font-bold"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{ __html: message.status }}
                            ></div>
                            <div
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{ __html: message.message }}
                            ></div>
                            <div
                              className="text-gray-500"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{ __html: message.content }}
                            ></div>
                          </div>
                        ))}
                      </dd>
                    </div>
                  )}

                  {(report.status === 'Complete' ||
                    report.status === 'Preparing Reports' ||
                    report.status === 'Partially Failed' ||
                    report.status === 'Canceled') && (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Generated Files
                      </dt>
                      <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                          {report.outputFileStatus !== 'Skipped' &&
                            renderDownloadButton(
                              'Output File (xlsx)',
                              report.outputFileStatus,
                              report.outputFileUrl,
                            )}

                          {report.verifierFileStatus !== 'Skipped' &&
                            renderDownloadButton(
                              'Verifier File (xlsx)',
                              report.verifierFileStatus,
                              report.verifierFileUrl,
                            )}
                          {report.gipsFileStatus !== 'Skipped' &&
                            renderDownloadButton(
                              'GIPS Report (docx)',
                              report.gipsFileStatus,
                              report.gipsFileUrl,
                            )}
                          {report.qcOutputFileStatus !== 'Skipped' &&
                            renderDownloadButton(
                              'QC File (xlsx)',
                              report.qcOutputFileStatus,
                              report.qcOutputFileUrl,
                            )}
                        </ul>
                        {(report.status === 'Complete' ||
                          report.status === 'Partially Failed') && (
                          <div className="mt-4 text-sm leading-6 text-gray-500">
                            Available until{' '}
                            {dayjs(report.modifiedAt).add(1, 'day').format('MM/DD/YYYY HH:mm A')}
                          </div>
                        )}
                      </dd>
                    </div>
                  )}

                  {(report.status === 'Pending' ||
                    report.status === 'Processing Data' ||
                    report.status === 'Ready for Report Generation' ||
                    report.status === 'Preparing Reports') && (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Actions</dt>
                      <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <Button
                          disabled={cancelLoading}
                          background="bg-red"
                          label="Cancel Processing"
                          onClick={onCancelReport}
                        />
                        {cancelError && (
                          <span className="text-sm font-medium text-red-500">{cancelError} </span>
                        )}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
        )}
      </StateContainer>
    )
  }

  return <Navigate to="/clients" />
})

export default Report
