import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { observer } from 'mobx-react'

// Components
import { Button } from '../../components/Button'
// import { FileUploader } from '../../components/FileUploader'
import { PageContainer } from '../../components/PageContainer'
import { PasswordInput } from '../../components/PasswordInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import { updateUser, updateUserPassword } from '../../services/user.service'
import { toast, verifyPassword } from '../../utils/helpers'

/**
 *
 * UserProfile
 *
 */
const UserProfile = observer(() => {
  // Context
  const {
    error: loadingError,
    loading,
    getUpdatedUser,
    setCurrentUser,
    user,
  } = useContext(UserStoreContext)

  // State
  // const [uploadLogo, setUploadLogo] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingPassword, setLoadingPassword] = useState(false)

  useEffect(() => {
    getUpdatedUser(user.id)
  }, [])

  const {
    handleSubmit: handleProfileSubmit,
    formState: { errors: profileErrors, isDirty: profileIsDirty },
    register: profileRegister,
    reset: profileReset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      username: '',
    },
  })

  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors, isDirty: passwordIsDirty },
    reset: resetPasswordForm,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
  })

  useEffect(() => {
    if (user) {
      profileReset({
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
      })
    }
  }, [user])

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  /**
   * Handles submitting changes to the user's profile.
   * - If the user is changing either their email or phone number, these get handled
   *  separately. Both of these changes require a verification code.
   * @param {object} data
   */
  const onProfileSubmit = async (data) =>
    updateUser(
      {
        id: user.id,
        ...data,
      },
      handleErrors,
      setLoadingProfile,
      (m) => handleSuccesses(m),
      setCurrentUser,
    )

  return (
    <PageContainer withPadding>
      <StateContainer error={loadingError} loading={loading}>
        <div className="items-center divide-y divide-blue/15">
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-2 py-6 sm:px-2 md:grid-cols-3 lg:px-4">
            <div>
              <h2 className="text-primary text-base font-semibold leading-7">
                Personal Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Update any profile information.
              </p>
            </div>

            <form className="md:col-span-2" onSubmit={handleProfileSubmit(onProfileSubmit)}>
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <TextInput
                    data-testid="firstName"
                    disabled
                    fullWidth
                    id="firstName"
                    inputStyles="rounded-none font-"
                    name="firstName"
                    label="First Name"
                    value={user.firstName}
                  />
                </div>

                <div className="sm:col-span-3">
                  <TextInput
                    data-testid="lastName"
                    disabled
                    fullWidth
                    id="lastName"
                    inputStyles="rounded-none font-"
                    name="lastName"
                    label="Last Name"
                    value={user.lastName}
                  />
                </div>

                <div className="col-span-full">
                  <TextInput
                    data-testid="email"
                    disabled
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={user.email}
                  />
                </div>

                <div className="col-span-full">
                  <TextInput
                    data-testid="username"
                    error={profileErrors.username && 'This field is required'}
                    fullWidth
                    id="username"
                    inputStyles="rounded-none font-"
                    name="username"
                    label="Username"
                    placeholder="Username"
                    {...profileRegister('username', { required: true })}
                  />
                </div>
              </div>

              <div className="mt-8 flex">
                <Button
                  disabled={loadingProfile || !profileIsDirty}
                  onClick={handleProfileSubmit(onProfileSubmit)}
                  label="Save Changes"
                  loading={loadingProfile}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-2 py-6 sm:px-2 md:grid-cols-3 lg:px-4">
            <div>
              <h2 className="text-primary text-base font-semibold leading-7">Change password</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Update the password associated with your account.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <Controller
                    name="oldPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        data-testid="oldPassword"
                        error={passwordErrors.oldPassword && 'This field is required'}
                        fullWidth
                        id="oldPassword"
                        label="Current Password"
                        placeholder="Current Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="col-span-full">
                  <Controller
                    name="newPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        data-testid="newPassword"
                        error={
                          passwordErrors.newPassword &&
                          (passwordErrors.newPassword.message || 'This field is required')
                        }
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label="New Password"
                        placeholder="New Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{ required: true, validate: (value) => verifyPassword(value) }}
                  />
                </div>

                <div className="col-span-full">
                  <Controller
                    name="confirmPassword"
                    control={passwordControl}
                    render={({ field: { onChange, value } }) => (
                      <PasswordInput
                        autoComplete="password"
                        data-testid="confirmPassword"
                        error={
                          passwordErrors.confirmPassword &&
                          (passwordErrors.confirmPassword.message || 'This field is required')
                        }
                        fullWidth
                        id="confirmPassword"
                        name="confirmPassword"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    rules={{
                      required: true,
                      validate: (value) => {
                        const err = verifyPassword(value)
                        if (err) return err
                        if (watch('newPassword') !== value) {
                          return 'Passwords do not match'
                        }
                        return undefined
                      },
                    }}
                  />
                </div>
              </div>

              <div className="mt-8 flex">
                <Button
                  disabled={loadingPassword || !passwordIsDirty}
                  onClick={handlePasswordSubmit((data) =>
                    updateUserPassword(
                      {
                        id: user.id,
                        ...data,
                      },
                      handleErrors,
                      setLoadingPassword,
                      (m) => {
                        handleSuccesses(m)
                        resetPasswordForm()
                      },
                    ),
                  )}
                  label="Save Changes"
                  loading={loadingPassword}
                  type="button"
                />
              </div>
            </form>
          </div>

          {/* <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-2 py-6 sm:px-2 md:grid-cols-3 lg:px-4">
            <div>
              <h2 className="text-primary text-base font-semibold leading-7">Email Address</h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update the email address associated with your account.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <TextInput
                    data-testid="email"
                    error={emailErrors.email && 'This field is required'}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    {...emailRegister('email', { required: true })}
                  />
                </div>

                {verifyMfa === 'email' && (
                  <div className="col-span-full flex flex-col">
                    <TextInput
                      data-testid="code"
                      error={profileErrors.code && 'This field is required'}
                      fullWidth
                      id="code"
                      name="code"
                      label="Verification Code"
                      placeholder="Verification Code"
                      {...emailRegister('code', { required: true })}
                    />

                    <button
                      className="mr-1 mt-1 place-self-end"
                      type="button"
                      onClick={async () => {
                        await requestEmailChange(
                          getEmailValues().email,
                          handleErrors,
                          setLoadingEmail,
                          (m) => {
                            handleSuccesses(m)
                            setVerifyMfa('email')
                          },
                        )
                      }}
                    >
                      <span className="text-sm font-medium text-blue hover:text-blue-dark">
                        Resend Verification Code
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-6 flex space-x-2">
                {verifyMfa === 'email' && (
                  <Button
                    background="bg-white"
                    loading={loading}
                    onClick={() => {
                      setVerifyMfa(null)
                      emailReset()
                    }}
                    outlined
                    label="Cancel"
                  />
                )}

                <Button
                  disabled={loadingEmail || !emailIsDirty}
                  onClick={handleEmailSubmit(onEmailSubmit)}
                  label={verifyMfa === 'email' ? 'Confirm Change' : 'Save Changes'}
                  loading={loadingEmail}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-2 py-6 sm:px-2 md:grid-cols-3 lg:px-4">
            <div>
              <h2 className="text-primary text-base font-semibold leading-7">Phone Number</h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Update the phone number associated with your account.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <PhoneInput
                    control={phoneControl}
                    country="US"
                    data-testid="phoneNumber"
                    errors={phoneErrors}
                    inputComponent={PhoneNumberInput}
                    name="phoneNumber"
                    rules={{
                      validate: {
                        valid: (v) => {
                          if (v !== undefined && v !== null && v.length > 0)
                            return isPossiblePhoneNumber(v)
                          return true
                        },
                      },
                    }}
                    withCountryCallingCode
                  />
                </div>

                {verifyMfa === 'phone' && (
                  <div className="col-span-full flex flex-col">
                    <TextInput
                      data-testid="code"
                      error={phoneErrors.code && 'This field is required'}
                      fullWidth
                      id="code"
                      inputStyles="rounded-none font-"
                      name="code"
                      label="Verification Code"
                      placeholder="Verification Code"
                      {...phoneRegister('code', { required: true })}
                    />

                    <button
                      className="mr-1 mt-1 place-self-end"
                      type="button"
                      onClick={async () => {
                        const { phoneNumber } = getPhoneValues()
                        await requestPhoneChange(
                          phoneNumber,
                          handleErrors,
                          setLoadingPhone,
                          (m) => {
                            if (phoneNumber !== null && phoneNumber.length > 0) {
                              handleSuccesses(m)
                              setVerifyMfa('phone')
                            } else {
                              handleSuccesses('Phone number removed.')
                            }
                          },
                        )
                      }}
                    >
                      <span className="text-sm font-medium text-blue hover:text-blue-dark">
                        Resend Verification Code
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-6 flex space-x-2">
                {verifyMfa === 'phone' && (
                  <Button
                    background="bg-white"
                    loading={loading}
                    onClick={() => {
                      setVerifyMfa(null)
                      phoneReset()
                    }}
                    outlined
                    label="Cancel"
                  />
                )}

                <Button
                  disabled={loadingPhone || !phoneIsDirty}
                  onClick={handlePhoneSubmit(onPhoneSubmit)}
                  label={verifyMfa === 'phone' ? 'Confirm Change' : 'Save Changes'}
                  loading={loadingPhone}
                  type="button"
                />
              </div>
            </form>
          </div>

          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-2 py-6 sm:px-2 md:grid-cols-3 lg:px-4">
            <div>
              <h2 className="text-primary text-base font-semibold leading-7">
                Multi-Factor Authentication
              </h2>
              <p className="text-primary mt-1 text-sm leading-6">
                Configure where you would like to receive your authentication codes.
              </p>
            </div>

            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div className="col-span-full">
                  <Controller
                    name="mfaDevice"
                    control={multiFactorControl}
                    render={({ field: { onChange, value, ref } }) => (
                      <RadioInput
                        className="mt-4 self-center"
                        horizontal
                        name="mfaDevice"
                        options={[
                          {
                            id: 'Phone',
                            label: 'Phone Number',
                            disabled: user.phoneNumber === null || user.phoneNumber === '',
                          },
                          { id: 'Email', label: 'Email Address' },
                        ]}
                        onChange={onChange}
                        value={value}
                        ref={ref}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="mt-8 flex">
                <Button
                  disabled={loadingMfa || !multiFactorIsDirty}
                  onClick={handleMultiFactorSubmit((data) =>
                    updateUser(
                      {
                        id: user.id,
                        ...data,
                      },
                      handleErrors,
                      setLoadingMfa,
                      (m) => handleSuccesses(m),
                      setCurrentUser,
                    ),
                  )}
                  label="Save Changes"
                  loading={loadingMfa}
                  type="button"
                />
              </div>
            </form>
          </div> */}
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default UserProfile
