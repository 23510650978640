import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * CustomLink
 *
 */
const CustomLink = ({
  children,
  className = 'text-blue-600 bg-blue-50 hover:bg-blue-100',
  disabled = false,
  to = '#',
  ...rest
}) => (
  <Link
    to={to}
    className={mergeClassNames(
      'inline-flex rounded-md  px-2.5 py-1.5 text-sm font-semibold shadow-sm ',
      className,
      disabled && 'cursor-not-allowed opacity-50',
    )}
    onClick={(e) => disabled && e.preventDefault()}
    disabled={disabled}
    {...rest}
  >
    {children}
  </Link>
)

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
}

export default CustomLink
