/* eslint-disable prefer-regex-literals */
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import React from 'react'

export const oneUppercaseLetterRegEx = new RegExp(/[A-Z]/)
export const oneLowercaseLetterRegEx = new RegExp(/[a-z]/)
export const oneNumberRegEx = new RegExp(/[\d]/, 'i')
export const oneSymbolRegEx = new RegExp(/[()[\]{}|`~!@#$%^&*_\-+=;:'",<>.\\/?]/, 'i')

/**
 * PasswordVerifier
 *
 * Tests the supplied `password` by the set rules:
 * - 1 uppercase letter
 * - 1 lowercase letter
 * - 1 number
 * - 1 symbol
 */
const PasswordVerifier = ({ password = '' }) => (
  <div
    data-testid="password-verifier"
    className="space-y-1 rounded-md border bg-white p-2 shadow-md"
  >
    <div className="flex flex-row space-x-2">
      <p className="text-xs text-gray-600">Password must have at least 1 lowercase letter.</p>

      <div className="pointer-events-none inset-y-0 flex items-center pr-3">
        {oneLowercaseLetterRegEx.test(password) && password ? (
          <CheckCircleIcon className="size-4 text-green-500" aria-hidden="true" />
        ) : (
          <ExclamationCircleIcon className="size-4 text-red-500" aria-hidden="true" />
        )}
      </div>
    </div>
    <div className="flex flex-row space-x-2">
      <p className="text-xs text-gray-600">Password must have at least 1 uppercase letter.</p>

      <div className="pointer-events-none inset-y-0 flex items-center pr-3">
        {oneUppercaseLetterRegEx.test(password) && password ? (
          <CheckCircleIcon className="size-4 text-green-500" aria-hidden="true" />
        ) : (
          <ExclamationCircleIcon className="size-4 text-red-500" aria-hidden="true" />
        )}
      </div>
    </div>
    <div className="flex flex-row space-x-2">
      <p className="text-xs text-gray-600">Password must have at least 1 number.</p>

      <div className="pointer-events-none inset-y-0 flex items-center pr-3">
        {oneNumberRegEx.test(password) && password ? (
          <CheckCircleIcon className="size-4 text-green-500" aria-hidden="true" />
        ) : (
          <ExclamationCircleIcon className="size-4 text-red-500" aria-hidden="true" />
        )}
      </div>
    </div>
    <div className="flex flex-row space-x-2">
      <p className="text-xs text-gray-600">Password must have at least 1 symbol.</p>

      <div className="pointer-events-none inset-y-0 flex items-center pr-3">
        {oneSymbolRegEx.test(password) && password ? (
          <CheckCircleIcon className="size-4 text-green-500" aria-hidden="true" />
        ) : (
          <ExclamationCircleIcon className="size-4 text-red-500" aria-hidden="true" />
        )}
      </div>
    </div>
    <p className="text-xs text-gray-600">Note: Password must not be a previous password.</p>
    <p className="text-xs text-gray-600">Note: Password must not be similar to username.</p>
  </div>
)

PasswordVerifier.propTypes = {
  password: PropTypes.string,
}

export default PasswordVerifier
