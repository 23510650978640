import { CheckIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../Button'

const StepButton = ({ active, enabled = true, completed, step }) => {
  if (active) {
    return (
      <Button
        onClick={step.onClick}
        aria-current="step"
        type="button"
        className="bg-transparent px-6 py-4"
        alignLeft
        plain
        label={
          <span className="flex items-center text-sm font-medium">
            <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-blue">
              <span className="text-blue">{step.id}</span>
            </span>
            <span className="ml-4 text-sm font-medium text-blue">{step.name}</span>
          </span>
        }
        ariaLabel={`Step ${step.id}: ${step.name}`}
      />
    )
  }

  if (completed) {
    return (
      <Button
        disabled={!enabled}
        type="button"
        alignLeft
        plain
        className="bg-transparent px-6 py-4"
        onClick={step.onClick}
        ariaLabel={`Completed Step ${step.id}: ${step.name}`}
        label={
          <span className="flex items-center text-sm font-medium">
            <span className="flex size-10 shrink-0 items-center justify-center rounded-full bg-blue group-hover:bg-blue-800">
              <CheckIcon aria-hidden="true" className="size-6 text-white" />
            </span>
            <span className="ml-4 text-sm font-medium text-blue-dark">{step.name}</span>
          </span>
        }
      />
    )
  }

  return (
    <Button
      onClick={step.onClick}
      ariaLabel={`Cannot move to Step ${step.id}: ${step.name}`}
      alignLeft
      plain
      className="bg-transparent px-6 py-4 disabled:cursor-not-allowed disabled:opacity-50"
      disabled={!enabled}
      type="button"
      label={
        <span className="flex items-center text-sm font-medium">
          <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500 group-hover:text-blue-dark">{step.id}</span>
          </span>
          <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-blue-dark">
            {step.name}
          </span>
        </span>
      }
    />
  )
}

StepButton.propTypes = {
  active: PropTypes.bool.isRequired,
  enabled: PropTypes.bool,
  completed: PropTypes.bool.isRequired,
  step: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
}

export default StepButton
