import PropTypes from 'prop-types'
import React from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { ActivityVerbIcon } from '../../components/ActivityVerbIcon'
// Utils
import { formatShortRelativeTime } from '../../utils/formatters'

const ActivityLogPreview = ({ activity, error }) => {
  if (error) {
    return (
      <section className="m-auto flex flex-col pr-8">
        <p className="text-md font-semibold text-red-700">{error}</p>
      </section>
    )
  }

  return (
    <section className="flex flex-col pr-8">
      <h2 className="mb-8 text-xl font-semibold leading-6 text-gray-900">Latest Activity</h2>

      {activity.length === 0 ? (
        <p className="text-sm text-gray-500">No activity items are available.</p>
      ) : (
        <ul className="space-y-6">
          {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={mergeClassNames(
                  activityItemIdx === activity.length - 1 ? 'h-5' : '-bottom-5',
                  'absolute left-0 top-0 flex w-5 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>

              <div className="relative flex size-5 flex-none items-center justify-center bg-white">
                <ActivityVerbIcon verb={activityItem.verb} />
              </div>
              <p className="flex-auto py-0.5 text-xs/5 text-gray-500">
                <span className="font-medium text-gray-900">{`${activityItem.actor.firstName} ${activityItem.actor.lastName}`}</span>{' '}
                {activityItem.description}.
              </p>
              <time
                dateTime={activityItem.timestamp}
                className="flex-none py-0.5 text-xs/5 text-gray-500"
              >
                {formatShortRelativeTime(activityItem.timestamp)}
              </time>
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

export default ActivityLogPreview

ActivityLogPreview.propTypes = {
  activity: PropTypes.array,
  error: PropTypes.string,
}
