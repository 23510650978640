import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { PlusIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'

// Components
import { CalendarInput } from '../../components/CalendarInput'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { Select } from '../../components/Select'

// Utils & Services
import { createBenchmarkData } from '../../services/benchmarks.service'
import { toast } from '../../utils/helpers'

const AddBenchmarkDataModal = ({
  benchmarks,
  client,
  closeModal,
  composites,
  onSuccess,
  rebalancingFrequencies,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      benchmark: null,
      weight: null,
      rebalancingFrequency: null,
      composite: null,
      period: null,
      benchmarkReturn: null,
    },
  })

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  /**
   * Handles submitting the create data request.
   * @param {object} data
   */
  const onSubmit = async (data) =>
    createBenchmarkData(
      client.id,
      {
        weight: data.weight > 0 ? data.weight / 100 : data.weight,
        benchmarkReturn:
          data.benchmarkReturn > 0 ? data.benchmarkReturn / 100 : data.benchmarkReturn,
        benchmark: data.benchmark.value,
        rebalancingFrequency: data.rebalancingFrequency.value,
        composite: data.composite.value,
        period: dayjs(data.period).format('YYYY-MM-DD'),
      },
      handleError,
      setLoading,
      (m) => {
        handleSuccess(m)
        onSuccess()
      },
    )

  return (
    <Modal
      icon={<PlusIcon className="h-6 fill-white" />}
      open
      title="Add Row"
      loading={loading}
      onClose={closeModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex size-full flex-col gap-4 pt-2"
        >
          <Controller
            name="benchmark"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Benchmark"
                style={{ width: '100%' }}
                error={errors.composite && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('benchmark')
                }}
                options={benchmarks}
              />
            )}
            rules={{ required: true }}
          />

          <TextInput
            endIcon={<span>%</span>}
            fullWidth
            label="Weight"
            name="weight"
            type="number"
            error={errors.weight && 'This field is required'}
            {...register('weight', {
              required: true,
              validate: (value) => {
                if (value.toString().length > 19) return 'No more than 19 digits'
                if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
          />

          <Controller
            name="rebalancingFrequency"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Rebalancing Frequency"
                style={{ width: '100%' }}
                error={errors.rebalancingFrequency && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('rebalancingFrequency')
                }}
                options={rebalancingFrequencies}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="composite"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                label="Composite"
                style={{ width: '100%' }}
                error={errors.composite && 'This field is required'}
                onChange={(option) => {
                  onChange(option)
                  clearErrors('composite')
                }}
                options={composites}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="period"
            control={control}
            render={({ field: { value } }) => (
              <CalendarInput
                label="Period"
                id="period"
                error={errors.period && 'This field is required'}
                value={value}
                onChange={(d) => setValue('period', d)}
              />
            )}
            rules={{ required: true }}
          />

          <TextInput
            endIcon={<span>%</span>}
            fullWidth
            label="Return"
            name="benchmarkReturn"
            type="number"
            error={errors.benchmarkReturn && 'This field is required'}
            {...register('benchmarkReturn', {
              required: true,
              validate: (value) => {
                if (value.toString().length > 19) return 'No more than 19 digits'
                if (!/^\d*(\.\d{0,15})?$/.test(value)) return 'No more than 15 decimals'
                return true
              },
            })}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Add',
          onClick: handleSubmit(onSubmit),
        },
        { type: 'cancel', label: 'Cancel', onClick: closeModal },
      ]}
    />
  )
}

AddBenchmarkDataModal.propTypes = {
  benchmarks: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  composites: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  rebalancingFrequencies: PropTypes.array.isRequired,
}

export default AddBenchmarkDataModal
