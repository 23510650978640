import { Field, Label, Switch } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 * Toggle Component
 * - Displays a toggle switch
 */
const Toggle = forwardRef(
  (
    {
      checked = false,
      className = null,
      disabled = false,
      label,
      labelClassName = null,
      onChange,
      ...rest
    },
    ref,
  ) => (
    <Field as="div" className={mergeClassNames('flex items-center space-x-2', className)}>
      <Switch
        checked={Boolean(checked)}
        disabled={disabled}
        onChange={onChange}
        className={joinClassNames(
          checked ? 'bg-blue-800' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
        )}
        ref={ref}
        {...rest}
      >
        <span
          className={joinClassNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          <span
            className={joinClassNames(
              checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          ></span>
          <span
            className={joinClassNames(
              checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
            )}
            aria-hidden="true"
          ></span>
        </span>
      </Switch>

      <Label
        as="span"
        className={mergeClassNames(
          'text-md text-black-light font-semibold leading-6',
          labelClassName,
        )}
        passive
      >
        {label}
      </Label>
    </Field>
  ),
)

Toggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Toggle
