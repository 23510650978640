import PropTypes from 'prop-types'
import React from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'

/**
 *
 * Alert
 *
 */
const Alert = ({ className = null, message, onClose = null, title = null, type = 'success' }) => {
  const configureBackground = () => {
    switch (type) {
      case 'success':
        return 'bg-green-50'
      case 'error':
        return 'bg-error-light'
      case 'warning':
        return 'bg-warning-light'
      case 'info':
        return 'bg-info-light'
      default:
        return null
    }
  }

  const configureTitleColor = () => {
    switch (type) {
      case 'success':
        return 'text-green-700'
      case 'error':
        return 'text-error-dark'
      case 'warning':
        return 'text-warning-dark'
      case 'info':
        return 'text-info-dark'
      default:
        return null
    }
  }

  const configureTextColor = () => {
    switch (type) {
      case 'success':
        return 'text-green-700'
      case 'error':
        return 'text-error'
      case 'warning':
        return 'text-warning'
      case 'info':
        return 'text-info'
      default:
        return null
    }
  }

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon size={48} strokeWidth={2} className="text-green-700" />
      case 'error':
        return <XCircleIcon size={48} strokeWidth={2} className="text-error-icon" />
      case 'warning':
        return <ExclamationTriangleIcon size={48} strokeWidth={2} className="text-warning-icon" />
      case 'info':
        return <InformationCircleIcon size={48} strokeWidth={2} className="text-info-icon" />
      default:
        return null
    }
  }

  return (
    <div className={mergeClassNames(`rounded-md ${configureBackground()} p-4`, className)}>
      <div className="flex">
        <div className="shrink-0">
          <svg className="size-10" viewBox="0 0 40 40">
            {renderIcon()}
          </svg>
        </div>

        <div className="mx-3 flex w-full flex-col">
          {title && <h3 className={`text-sm font-medium ${configureTitleColor()}`}>{title}</h3>}
          {message && (
            <div className={`mt-2 text-sm ${configureTextColor()}`}>
              <p>{message}</p>
            </div>
          )}
        </div>

        <button
          className="relative -right-2 -top-2 flex size-6 place-items-center justify-center"
          onClick={onClose}
          type="button"
        >
          <svg className="size-4" viewBox="0 0 40 40">
            <XMarkIcon size={48} strokeWidth={2} className={configureTextColor()} />
          </svg>
        </button>
      </div>
    </div>
  )
}

Alert.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default Alert
