import axios from './instance'

/**
 * Gets the latest client import task.
 * @returns results
 */
export const getLatestClientImport = async () => {
  try {
    return await axios.get(`/client-imports/latest/`)
  } catch (err) {
    return null
  }
}

/**
 * Gets the latest user import task.
 * @returns results
 */
export const getLatestUserImport = async () => {
  try {
    return await axios.get(`/user-imports/latest/`)
  } catch (err) {
    return null
  }
}
