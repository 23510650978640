import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

// Components
import { Button } from '../../components/Button'
import { FormHeader } from '../../components/FormHeader'
import { TextInput } from '../../components/TextInput'

// Images
import logo from '../../assets/images/logo.svg'

// Service & Utils
import { forgotPassword } from '../../services/user.service'
import { toast } from '../../utils/helpers'

/**
 *
 * PasswordResetRequest
 *
 */
const PasswordResetRequest = () => {
  // State
  const [loading, setLoading] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm()

  /**
   * Handles form submission
   * - Clears form if successful
   * @param {object} data
   */
  const onSubmit = async (data) =>
    forgotPassword(data, handleError, setLoading, (m) => {
      handleSuccess(m)

      // Reset form
      reset()
    })

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-2 sm:px-6 lg:px-8">
      <img alt="Longs Peak Advisory" src={logo} className="mx-auto h-24 w-auto" />
      <FormHeader title="Forgot your password?" />

      <div className="mx-4 mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="rounded-lg bg-white px-4 py-10 shadow sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <p className="text-center text-sm text-gray-dark">
              Or
              <Link
                to="/login"
                className="ml-1 font-medium text-blue-700 hover:text-blue-dark hover:underline"
              >
                Log In
              </Link>
            </p>

            <div className="space-y-1">
              <TextInput
                data-testid="email"
                error={errors.email && 'This field is required'}
                autoComplete="email"
                id="email"
                name="email"
                placeholder="Email"
                required
                {...register('email', { required: true })}
              />
            </div>

            <Button fullWidth label="Submit" loading={loading} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetRequest
