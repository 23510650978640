import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'
import { PasswordVerifier } from '../PasswordVerifier'
import { Tooltip } from '../Tooltip'

/**
 *
 * PasswordInput
 *
 */
const PasswordInput = forwardRef(
  (
    {
      className = '',
      inputStyles = '',
      error = '',
      fullWidth = false,
      icon = null,
      id = '',
      label = '',
      name = '',
      placeholder = '',
      renderIconStyles = null,
      showTooltip = false,
      value,
      onChange,
      ...rest
    },
    ref,
  ) => {
    // State
    const [reveal, setReveal] = useState(false)

    return (
      <div className={`flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`}>
        <label htmlFor={id} className="pb-1 text-sm font-medium text-gray-700">
          {label}
        </label>

        <Tooltip
          content={<PasswordVerifier password={value} />}
          display={showTooltip}
          fullWidth
          placement="bottom"
        >
          <div
            className={mergeClassNames(
              'relative flex w-full appearance-none rounded-md border-[1.5px] border-gray-200 bg-white px-3 py-2.5 pr-4 text-sm text-gray-900 placeholder:text-gray-500 focus-within:border-blue',
              className,
            )}
          >
            {icon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                {icon}
              </div>
            )}

            <input
              autoComplete="password"
              className={mergeClassNames(
                'text-primary relative w-full border-none bg-transparent p-0 text-sm focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-400',
                inputStyles,
              )}
              data-testid={name}
              id={id}
              name={name}
              placeholder={placeholder}
              ref={ref}
              required
              {...rest}
              onChange={onChange}
              value={value}
              type={reveal ? 'text' : 'password'}
            />

            <Button
              iconOnly
              icon={
                reveal ? (
                  <EyeIcon className={mergeClassNames('h-4 w-4', renderIconStyles)} />
                ) : (
                  <EyeSlashIcon className={mergeClassNames('h-4 w-4', renderIconStyles)} />
                )
              }
              initialToggled={false}
              onClick={() => setReveal(!reveal)}
              aria-label="Toggle password visibility"
              type="button"
              dataTestId="password-visibility-toggle"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 "
            />
          </div>

          {error && (
            <div className="mt-1 w-full bg-transparent px-2 py-1 text-center">
              <p className="text-sm font-medium text-error" id={`error:${name}`}>
                {error}
              </p>
            </div>
          )}
        </Tooltip>
      </div>
    )
  },
)

PasswordInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  inputStyles: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  renderIconStyles: PropTypes.string,
  showTooltip: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default PasswordInput
