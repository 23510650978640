/* eslint-disable react/prop-types */
import React from 'react'

/* FormHeader */
const FormHeader = ({ children, title }) => (
  <div className="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
      {title}
    </h2>
    {children}
  </div>
)

FormHeader.propTypes = {}

export default FormHeader
