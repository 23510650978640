/**
 * PublicRoute
 *
 * If a user is authenticated and attempts to access the component, they will be
 * redirected to their portfolio
 */

import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'

// Store
import { UserStoreContext } from '../../stores/UserStore'

const PublicRoute = observer(({ component: Component }) => {
  // Context
  const { accessToken } = useContext(UserStoreContext)
  const location = useLocation()

  // if `from` is set, use that value, otherwise navigate to the dashboard
  const { from } = location.state || { from: { pathname: '/clients' } }

  return accessToken ? (
    <Navigate
      to={{
        pathname: from.pathname,
      }}
    />
  ) : (
    <Component />
  )
})

export default PublicRoute
