import { PlusIcon } from '@heroicons/react/20/solid'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { FolderIcon } from '../FolderIcon'

const NoDataPrompt = ({ path, title, subtitle, linkText, Icon = FolderIcon }) => (
  <div className="rounded-md border-[.5px] border-solid border-gray-light p-8 text-center">
    <Icon className="mx-auto size-12 text-gray-400" />
    <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
    <div className="mt-6">
      <Link
        to={path}
        type="button"
        className="inline-flex items-center rounded-md bg-blue-50 px-3 py-2 text-sm font-semibold text-blue-700 shadow-sm hover:bg-blue-100"
      >
        <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 size-5" />
        {linkText}
      </Link>
    </div>
  </div>
)

export default NoDataPrompt

NoDataPrompt.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
}
