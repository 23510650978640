import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const formatPhoneNumber = (phoneNumberString) => {
  if (phoneNumberString) {
    const cleaned = ('', phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
  }
  return ''
}

export const formatCurrency = (value) => {
  if (value == null || value === '') return '-'
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    parseFloat(value),
  )
}

export const formatShortRelativeTime = (timestamp) => dayjs(timestamp).fromNow()
