// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the composites for the specified `clientId`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` results
 */
export const getClientComposites = async (
  url,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a new composite with the specified `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const createClientComposite = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.post(`/clients/${clientId}/composites/`, payload)
    setSuccess('New composite added.')
    setLoading(false)
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}

/**
 * Updates the composite with the specified `compositeId` and `payload`.
 * @param {string} clientId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 * @returns `data` for the new composite
 */
export const updateClientComposite = async (
  clientId,
  payload,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(`/clients/${clientId}/composites/${payload.id}/`, payload)
    setSuccess(`${response.name} updated.`)
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes the composite with the specified `compositeId` and `payload`.
 * @param {string} clientId
 * @param {string} compositeId
 * @param {object} payload
 * @param {func} setError
 * @param {func} setLoading
 * @param {func} setSuccess
 */
export const deleteClientComposite = async (
  clientId,
  compositeId,
  setError = () => {},
  setLoading = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    await axios.delete(`/clients/${clientId}/composites/${compositeId}/`)
    setLoading(false)
    setSuccess()
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
  }
}
