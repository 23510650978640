import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'

// Components
import { FileUploader } from '../../components/FileUploader'
import { Modal } from '../../components/Modal'

// Utils & Services
import { toast } from '../../utils/helpers'
import { createGlobalBenchmarkImport } from '../../services/global.service'

const UploadBenchmarkImportModal = ({ closeModal }) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => toast(message, 'success')

  return (
    <Modal
      icon={<CloudArrowUpIcon className="h-6 stroke-white" />}
      open
      title="Upload Global Benchmark File"
      loading={loading}
      onClose={closeModal}
      content={
        <div className="mt-8 flex size-full flex-row pt-2">
          <div className="w-full">
            <FileUploader
              acceptedFileTypes={[
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              ]}
              handleUploadToServer={(file) =>
                createGlobalBenchmarkImport(
                  {
                    file,
                    originalFilename: file.name,
                  },
                  handleError,
                  setLoading,
                  (m) => {
                    handleSuccess(m)
                    closeModal()
                  },
                )
              }
              id="file"
              maxFiles={1}
              type="manual"
            />
          </div>
        </div>
      }
      actions={[{ type: 'cancel', label: 'Cancel', onClick: closeModal }]}
    />
  )
}

UploadBenchmarkImportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default UploadBenchmarkImportModal
