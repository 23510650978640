import { twMerge as mergeClassNames } from 'tailwind-merge'
import { joinClassNames } from './helpers'

const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 scale-75',
      enterActive:
        'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const CALENDAR_BUTTON =
  'flex items-center justify-center cursor-pointer overflow-hidden relative size-7 text-primary border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out hover:text-white hover:border-transparent hover:bg-blue'
const CALENDAR_TITLE_BUTTON =
  'text-primary transition duration-200 font-semibold p-2 hover:text-blue'

// Pagination
const DEFAULT_BUTTON =
  'relative inline-flex items-center justify-center user-none overflow-hidden leading-none rounded-full min-h-[30px] min-w-[30px] my-2'
const DEFAULT_BUTTON_CONTAINER =
  'border-0 text-blue px-2 py-2 rounded-full transition duration-200 ml-2 hover:bg-blue-100'
const BUTTON_DISABLED = 'cursor-not-allowed pointer-events-none opacity-40'
const BUTTON_ENABLED = 'hover:bg-blue-100'

// Table Styles
const BASE_CELL = 'text-left border-0 border-b border-solid border-blue/20'
const BASE_ICON_BUTTON = 'inline-flex items-center justify-center overflow-hidden relative'
const EDIT_ICON_BUTTON =
  'text-left cursor-pointer select-none size-6 border-0 rounded-full transition duration-200 text-blue border-transparent hover:text-blue-700 hover:bg-blue-100'
const SAVE_ICON_BUTTON =
  'text-left cursor-pointer select-none size-6 border-0 rounded-full transition duration-200 text-green-700 hover:bg-green-100'
const CANCEL_ICON_BUTTON =
  'text-left cursor-pointer select-none size-6 border-0 rounded-full transition duration-200 text-red-700 hover:bg-red-100'

const CustomTheme = {
  calendar: {
    root: ({ props }) => ({
      className: mergeClassNames(
        'inline-flex max-w-full relative w-full',
        props.disabled && 'opacity-40 select-none pointer-events-none cursor-not-allowed',
      ),
    }),
    input: ({ props }) => ({
      root: {
        className: mergeClassNames(
          'text-sm text-primary bg-white p-2 border-[1.5px] border-blue transition-colors duration-200 appearance-none hover:border-blue',
          !props.showIcon && 'rounded-lg',
          props.showIcon && 'border-r-0 rounded-l-lg',
        ),
      },
    }),
    dropdownButton: {
      root: ({ props }) => ({
        className: mergeClassNames(props.icon && 'rounded-l-none'),
      }),
    },
    panel: ({ props }) => ({
      className: mergeClassNames(
        'bg-white  min-w-[360px] xs:min-w-full',
        !props.inline && 'shadow-md border-0 absolute',
        props.inline && 'inline-block overflow-x-auto border border-gray-200 p-2 rounded-lg',
      ),
    }),
    header: {
      className:
        'flex items-center justify-between p-2 text-primary bg-white font-semibold m-0 border-b border-gray-200 rounded-t-lg',
    },
    previousButton: {
      className: CALENDAR_BUTTON,
    },
    title: { className: 'leading-8 mx-auto space-x-1' },
    monthTitle: {
      className: CALENDAR_TITLE_BUTTON,
    },
    yearTitle: {
      className: CALENDAR_TITLE_BUTTON,
    },
    nextButton: {
      className: CALENDAR_BUTTON,
    },
    table: {
      className: 'border-collapse w-full my-2',
    },
    tableHeaderCell: { className: 'p-2' },
    weekday: { className: 'text-primary' },
    day: { className: 'p-2' },
    dayLabel: ({ context }) => ({
      className: mergeClassNames(
        'size-8 rounded-full transition-shadow duration-200 border-transparent border flex items-center justify-center mx-auto overflow-hidden relative focus:outline-none focus:outline-offset-0 focus:shadow-sm',
        context.disabled && 'opacity-40 cursor-not-allowed',
        !context.disabled && 'cursor-pointer',
        !context.selected &&
          !context.disabled &&
          'text-primary bg-transprent hover:bg-blue hover:text-white',
        context.selected && !context.disabled && 'text-white bg-blue',
      ),
    }),
    monthPicker: { className: 'my-2' },
    month: ({ context }) => ({
      className: mergeClassNames(
        'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative p-2 transition-shadow duration-200 rounded-lg focus:outline-none focus:outline-offset-0 focus:shadow-sm',
        !context.selected &&
          !context.disabled &&
          'text-primary bg-transprent hover:bg-blue hover:text-white',
        context.selected && !context.disabled && 'text-white bg-blue',
      ),
    }),
    yearPicker: {
      className: 'my-2',
    },
    year: ({ context }) => ({
      className: mergeClassNames(
        'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative p-2 transition-shadow duration-200 rounded-lg focus:outline-none focus:outline-offset-0 focus:shadow-sm',
        !context.selected &&
          !context.disabled &&
          'text-primary bg-transprent hover:bg-blue hover:text-white',
        context.selected && !context.disabled && 'text-white bg-blue',
      ),
    }),
    separatorContainer: { className: 'flex items-center flex-col px-2' },
    separator: { className: 'text-xl' },
    groupContainer: { className: 'flex' },
    group: {
      className:
        'flex-1 border-l border-gray-200 pr-0.5 pl-0.5 pt-0 pb-0 first:pl-0 first:border-l-0',
    },
    transition: TRANSITIONS.overlay,
  },
  dropdown: {
    root: (base) => ({
      className: mergeClassNames(
        'cursor-pointer inline-flex relative bg-white transition-colors duration-200 ease-in-out rounded-md w-full md:w-40 focus:shadow-sm border-solid mx-2 border-[1.5px] hover:border-blue border-blue',
        base.props.disabled && 'opacity-60 select-none pointer-events-none cursor-default',
        base.props.pt?.root ? base.props.pt.root : '',
      ),
    }),
    input: ({ props }) => ({
      className: mergeClassNames(
        'cursor-pointer block flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative bg-transparent border-0 text-primary p-2 transition duration-200 bg-transparent rounded appearance-none text-sm focus:outline-none focus:shadow-none',
        props.showClear && 'pr-7',
      ),
    }),
    trigger: {
      className:
        'flex items-center justify-center shrink-0 bg-transparent text-primary w-12 rounded-tr-lg rounded-br-lg',
    },
    wrapper: {
      className: 'max-h-[200px] overflow-auto bg-white text-primary border-0 rounded-md shadow-lg',
    },
    list: 'list-none m-0',
    item: ({ context }) => ({
      className: mergeClassNames(
        'cursor-pointer text-sm font-normal overflow-hidden relative whitespace-nowrap m-0 p-3 border-0 transition-shadow duration-200 rounded-none hover:text-white hover:bg-blue',
        !context.focused && !context.selected && 'text-primary',
        context.focused && !context.selected && 'text-white bg-blue',
        context.focused && context.selected && 'text-white bg-blue',
        !context.focused && context.selected && 'text-white bg-blue',
        context.disabled && 'opacity-40 select-none pointer-events-none cursor-not-allowed',
      ),
    }),
    itemgroup: {
      className: 'm-0 p-3 text-primary bg-white font-bold cursor-auto',
    },
    header: {
      className:
        'p-3 border-b border-gray-300 text-primary bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg',
    },
    filtercontainer: 'relative',
    filterinput: {
      className:
        'pr-7 -mr-7 w-full text-base text-primary bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none hover:border-blue focus:outline-none focus:outline-offset-0 focus:shadow-sm',
    },
    filtericon: '-mt-2 absolute top-1/2',
    clearicon: 'text-primary right-12 -mt-2 absolute top-1/2',
    transition: TRANSITIONS.overlay,
  },
  inputnumber: {
    root: 'w-full inline-flex',
    input: {
      root: ({ props }) => ({
        className: mergeClassNames(
          'border-blue',
          props.showButtons &&
            props.buttonLayout === 'stacked' &&
            'rounded-tr-none rounded-br-none',
          'bg-white hover:bg-white focus:bg-white',
        ),
      }),
    },
    buttongroup: ({ props }) => ({
      className: mergeClassNames(
        props.showButtons && props.buttonLayout === 'stacked' && 'flex flex-col',
      ),
    }),
    incrementbutton: ({ props }) => ({
      className: mergeClassNames(
        'flex !items-center !justify-center',
        props.showButtons &&
          props.buttonLayout === 'stacked' &&
          'rounded-br-none rounded-bl-none rounded-bl-none !p-0 flex-1 w-[3rem]',
      ),
    }),
    decrementbutton: ({ props }) => ({
      className: mergeClassNames(
        'flex !items-center !justify-center',
        props.showButtons &&
          props.buttonLayout === 'stacked' &&
          'rounded-tr-none rounded-tl-none rounded-tl-none !p-0 flex-1 w-[3rem]',
      ),
    }),
  },
  inputtext: {
    root: ({ props, context }) => ({
      className: mergeClassNames(
        'm-0 text-primary p-2 text-sm bg-transparent border-[1.5px] transition-colors duration-200 appearance-none rounded-lg bg-white hover:bg-white focus:bg-white border-blue',
        context.disabled && 'opacity-40 select-none pointer-events-none cursor-default',
        !context.disabled &&
          'hover:border-blue focus:outline-none focus:outline-offset-0 focus:shadow-sm',
        props.size === 'small' && 'text-xs p-1',
        props.size === 'large' && 'text-lg p-4',
      ),
    }),
  },
  inputtextarea: {
    root: ({ props, context }) => ({
      className: mergeClassNames(
        'm-0 text-primary p-2 text-sm bg-transparent border-[1.5px] transition-colors duration-200 appearance-none rounded-lg bg-white hover:bg-white focus:bg-white border-blue relative',
        context.disabled && 'opacity-40 select-none pointer-events-none cursor-default',
        !context.disabled &&
          'hover:border-blue focus:outline-none focus:outline-offset-0 focus:shadow-sm',
        props.size === 'small' && 'text-xs p-1',
        props.size === 'large' && 'text-lg p-4',
      ),
    }),
  },
  paginator: {
    root: {
      className: 'flex items-center justify-center flex-wrap bg-transparent text-sm border-0 my-2',
    },
    firstpagebutton: ({ context }) => ({
      className: mergeClassNames(
        DEFAULT_BUTTON,
        DEFAULT_BUTTON_CONTAINER,
        context.disabled && BUTTON_DISABLED,
        !context.disabled && BUTTON_ENABLED,
      ),
    }),
    prevpagebutton: ({ context }) => ({
      className: mergeClassNames(
        DEFAULT_BUTTON,
        DEFAULT_BUTTON_CONTAINER,
        context.disabled && BUTTON_DISABLED,
        !context.disabled && BUTTON_ENABLED,
      ),
    }),
    nextpagebutton: ({ context }) => ({
      className: mergeClassNames(
        DEFAULT_BUTTON,
        DEFAULT_BUTTON_CONTAINER,
        context.disabled && BUTTON_DISABLED,
        !context.disabled && BUTTON_ENABLED,
      ),
    }),
    lastpagebutton: ({ context }) => ({
      className: mergeClassNames(
        DEFAULT_BUTTON,
        DEFAULT_BUTTON_CONTAINER,
        context.disabled && BUTTON_DISABLED,
        !context.disabled && BUTTON_ENABLED,
      ),
    }),
    pagebutton: ({ context }) => ({
      className: mergeClassNames(
        DEFAULT_BUTTON,
        DEFAULT_BUTTON_CONTAINER,
        context.active && 'bg-blue border-blue text-white pointer-events-none',
      ),
    }),
    rowperpagedropdown: {
      root: ({ props, state }) => ({
        className: mergeClassNames(
          'h-12 mx-2 flex-col flex inline-flex relative cursor-pointer user-none bg-white border rounded-md transition duration-200',
          state.focused && !props.disabled && 'shadow-sm border-blue',
          !state.focused && 'border-gray-200',
        ),
      }),
      input: {
        className: mergeClassNames(
          'text-base text-blue p-3 m-0 rounded-md appearance-none',
          'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0',
        ),
      },
      trigger: {
        className: 'flex items-center justify-center shrink-0 text-blue w-12 rounded-r-md',
      },
      panel: {
        className: 'bg-white text-blue border-0 rounded-md shadow-sm',
      },
      wrapper: 'overflow-auto',
      list: 'm-0 p-0 py-3 list-none',
      item: ({ context }) => ({
        className: mergeClassNames(
          'relative font-normal cursor-pointer space-nowrap overflow-hidden',
          'm-0 py-3 px-5 border-none text-blue rounded-none',
          'transition duration-200 hover:text-white hover:bg-blue',
          !context.focused && context.selected && 'text-white bg-blue',
          context.focused && context.selected && 'bg-blue text-white',
          context.focused && !context.selected && 'text-primary',
        ),
      }),
    },
  },
  multiselect: {
    root: ({ props }) => ({
      className: mergeClassNames(
        'cursor-pointer flex items-center justify-between w-full bg-white rounded-lg transition-colors rounded-lg border bg-white px-3 py-2 shadow-sm',
        'w-full md:w-80',
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
        },
      ),
    }),
    header: 'flex items-center justify-between bg-blue p-3 rounded-t-lg text-white',
    list: 'list-none m-0 bg-white',
    wrapper: 'overflow-auto border-none rounded-lg shadow-sm',
    item: ({ context }) => ({
      className: mergeClassNames(
        'cursor-pointer font-normal relative',
        'p-3 mb-2 border-0 transition-colors',
        context.focused && context.selected && 'bg-blue-50 text-blue-700 ',
        !context.focused && context.selected && 'bg-blue-50 text-blue-700',
      ),
    }),
    panel: 'bg-white border-none rounded-lg shadow-lg',
    checkboxContainer: {
      className: mergeClassNames(
        'inline-flex cursor-pointer select-none align-bottom relative mr-2',
      ),
    },
  },
  checkbox: {
    box: 'hidden',
  },
  datatable: {
    root: ({ props }) => ({
      className: mergeClassNames(
        'relative text-sm p-0',
        props.scrollable && props.scrollHeight === 'flex' && 'flex flex-col h-full',
      ),
    }),
    loadingoverlay: {
      className:
        'fixed w-full h-full t-0 l-0 bg-gray-100/40 transition duration-200 absolute flex items-center justify-center z-10',
    },
    loadingicon: 'size-8',
    wrapper: ({ props }) => ({
      className: mergeClassNames(
        props.scrollable && 'relative',
        props.scrollable && props.scrollHeight === 'flex' && 'flex flex-col h-full',
      ),
    }),
    header: ({ props }) => ({
      className: mergeClassNames(
        'bg-blue text-white border-0 font-bold p-4',
        props.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0',
      ),
    }),
    table: 'w-full border-spacing-0',
    thead: ({ context }) => ({
      className: mergeClassNames(context.scrollable && 'bg-blue sticky top-0 z-[2]'),
    }),
    tbody: ({ props, context }) => ({
      className: mergeClassNames(props.frozenRow && context.scrollable && 'sticky z-[1]'),
    }),
    tfoot: ({ context }) => ({
      className: mergeClassNames(context.scrollable && 'bg-blue bottom-0 z-[1]'),
    }),
    footer: {
      className: 'bg-blue text-white border-t-0 border-b border-x-0 border-gray-200 font-bold p-4',
    },
    column: {
      headercell: ({ context, props }) => ({
        className: mergeClassNames(
          BASE_CELL,
          'bg-blue text-white transition duration-200 p-3 cursor-default',
          context?.size === 'small' && 'p-2',
          context?.size === 'large' && 'p-4',
          (props?.frozen || props?.frozen === '') && 'sticky z-[1]',
          context?.showGridlines && 'border-x border-y',
          context.resizable && 'overflow-hidden space-nowrap border-y relative bg-clip-padding',
          props.sortable && 'cursor-pointer',
        ),
      }),
      headercontent: 'flex items-center text-white group',
      headertitle: 'font-medium',
      bodycell: ({ props, context }) => ({
        className: mergeClassNames(
          BASE_CELL,
          'p-4',
          context?.size === 'small' && 'p-2',
          context?.size === 'large' && 'p-4',
          (props?.frozen || props?.frozen === '') && 'sticky bg-inherit',
          context.showGridlines && 'border-x border-y',
        ),
      }),
      footercell: ({ context }) => ({
        className: mergeClassNames(
          BASE_CELL,
          'font-semibold p-3',
          'bg-blue text-white',
          'transition duration-200',
          context?.size === 'small' && 'p-2',
          context?.size === 'large' && 'p-4',
          context.showGridlines && 'border-x border-y',
        ),
      }),
      sorticon: ({ context }) => ({
        className: mergeClassNames(
          'ml-2 group-hover:text-white text-blue',
          context.sorted && 'text-white',
        ),
      }),
      sortbadge: {
        className:
          'flex items-center justify-center align-middle rounded-[50%] w-[1.143rem] leading-[1.143rem] ml-2 text-blue bg-blue-50',
      },
      columnfilter: 'inline-flex items-center ml-auto',
      filteroverlay: {
        className: 'bg-white text-blue border-0 rounded-md min-w-[12.5rem] shadow-lg mt-1',
      },
      filtermatchmodedropdown: {
        root: 'min-[0px]:flex mb-2 bg-white border-0 rounded-md',
      },
      filterrowitems: 'm-0 p-0 py-3 list-none ',
      filterrowitem: ({ context }) => ({
        className: mergeClassNames(
          'm-0 py-3 px-5 bg-transparent transition duration-200',
          context?.highlighted ? 'text-blue-700 bg-blue-100' : 'text-primary bg-transparent',
        ),
      }),
      filteroperator: {
        className:
          'px-5 py-3 border-b border-solid border-blue text-slate-700 bg-slate-50 rounded-t-md',
      },
      filteroperatordropdown: {
        root: 'min-[0px]:flex',
      },
      filterconstraint: 'p-5',
      filteraddrule: 'py-3 px-5',
      filteraddrulebutton: {
        root: 'justify-center w-full min-[0px]:text-sm',
        label: 'flex-auto grow-0',
        icon: 'mr-2',
      },
      filterremovebutton: {
        root: 'ml-2',
        label: 'grow-0',
      },
      filterbuttonbar: 'hidden', // Hide since we aren't showing any button options
      filterclearbutton: {
        root: 'w-auto min-[0px]:text-sm border-blue-500 text-blue-500 px-4 py-3',
      },
      filterapplybutton: {
        root: 'w-auto min-[0px]:text-sm px-4 py-3',
      },
      filtermenubutton: {
        className:
          'inline-flex justify-center items-center no-underline overflow-hidden relative ml-2',
      },
      headerfilterclearbutton: ({ context }) => ({
        className: mergeClassNames(
          'inline-flex justify-center items-center cursor-pointer no-underline overflow-hidden relative text-left bg-transparent m-0 p-0 border-none select-none ml-2',
          !context.hidden && 'invisible',
        ),
      }),
      columnresizer:
        'block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent',
      rowreordericon: 'cursor-move',
      roweditorinitbutton: {
        className: mergeClassNames(BASE_ICON_BUTTON, EDIT_ICON_BUTTON),
      },
      roweditorsavebutton: {
        className: mergeClassNames(BASE_ICON_BUTTON, SAVE_ICON_BUTTON),
      },
      roweditorcancelbutton: {
        className: mergeClassNames(BASE_ICON_BUTTON, CANCEL_ICON_BUTTON),
      },
      radioButton: {
        className: 'relative inline-flex cursor-pointer select-none align-bottom size-6',
      },
      radioButtonInput: {
        className:
          'w-full h-full top-0 left-0 absolute appearance-none select-none p-0 m-0 opacity-0 z-[1] rounded-[50%] outline-none cursor-pointer peer',
      },
      radioButtonBox: ({ context }) => ({
        className: joinClassNames(
          'flex items-center justify-center',
          'h-6 w-6 rounded-full border-2 text-primary transition duration-200 ease-in-out',
          context.checked
            ? 'border-blue-500 bg-blue-500 peer-hover:bg-blue-700 peer-hover:border-blue-700'
            : 'border-gray-200 bg-white peer-hover:border-blue-500',
          {
            'hover:border-blue-500 focus:shadow-input-focus focus:outline-none focus:outline-offset-0':
              !context.disabled,
            'cursor-default opacity-60': context.disabled,
          },
        ),
      }),
      radioButtonIcon: ({ context }) => ({
        className: joinClassNames(
          'transform rounded-full',
          'block h-3 w-3 bg-white transition duration-200',
          {
            'backface-hidden scale-10 invisible': context.checked === false,
            'visible scale-100 transform': context.checked === true,
          },
        ),
      }),
      headercheckboxwrapper: {
        className: joinClassNames(
          'cursor-pointer inline-flex relative select-none align-bottom',
          'w-6 h-6',
        ),
      },
      headercheckbox: ({ context }) => ({
        className: joinClassNames(
          'flex items-center justify-center',
          'border-2 w-6 h-6 text-primary rounded-lg transition-colors duration-200',
          context.checked ? 'border-blue-500 bg-blue-500' : 'border-gray-200 bg-white',
          {
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-sm ':
              !context.disabled,
            'cursor-default opacity-60': context.disabled,
          },
        ),
      }),
      headercheckboxicon: 'w-4 h-4 transition-all duration-200 text-white text-base',
      checkboxwrapper: {
        className: joinClassNames(
          'cursor-pointer inline-flex relative select-none align-bottom',
          'w-6 h-6',
        ),
      },
      checkbox: ({ context }) => ({
        className: joinClassNames(
          'flex items-center justify-center',
          'border-2 w-6 h-6 text-primary rounded-lg transition-colors duration-200',
          context.checked ? 'border-blue-500 bg-blue-500' : 'border-gray-200 bg-white',
          {
            'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-sm':
              !context.disabled,
            'cursor-default opacity-60': context.disabled,
          },
        ),
      }),
      checkboxicon: 'w-4 h-4 transition-all duration-200 text-white text-base',
      transition: TRANSITIONS.overlay,
    },
    bodyrow: ({ context }) => ({
      className: mergeClassNames(
        'transition duration-200 focus:outline focus:outline-[0.15rem] focus:outline-blue focus:outline-offset-[-0.15rem] text-white',
        context.selected ? 'bg-blue-50 text-blue-700' : 'bg-white text-primary',
        context.stripedRows && context.index % 2 === 0 && 'bg-white text-primary',
        context.stripedRows && context.index % 2 !== 0 && 'bg-blue-50/50 text-primary',
        context.selectable && 'cursor-pointer',
        context.selectable && !context.selected && 'hover:bg-blue hover:text-white',
      ),
    }),
    rowexpansion: 'bg-white text-blue',
    rowgroupheader: {
      className: 'sticky z-[1] bg-white text-blue transition duration-200',
    },
    rowgroupfooter: {
      className: 'sticky z-[1] bg-white text-primary transition duration-200',
    },
    rowgrouptoggler: {
      className: mergeClassNames(
        BASE_ICON_BUTTON,
        'text-left m-0 p-0 cursor-pointer select-none size-8 text-primary border-0 bg-transparent rounded-[50%] transition duration-200',
      ),
    },
    rowgrouptogglericon: 'inline-block w-4 h-4',
    resizehelper: 'absolute hidden w-px z-10 bg-blue-500',
  },
}

export { CustomTheme }
