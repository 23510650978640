import PropTypes from 'prop-types'
import React from 'react'

import { Button } from '../Button'

const StepComponent = ({
  nextLabel = 'Approve',
  backLabel = 'Go Back',
  skipLabel = 'Skip',
  children,
  loadingNext = false,
  loadingBack = false,
  onBack = null,
  onNext = null,
  onSkip = null,
  enabledNext = true,
  enabledSkip = true,
}) => (
  <div className="flex size-full flex-col">
    {children}

    <div className="flex w-full justify-end gap-4 py-4 pb-24">
      {onBack && (
        <Button
          loading={loadingBack}
          onClick={onBack}
          type="button"
          label={backLabel}
          background="bg-gray"
        />
      )}

      {onNext && (
        <Button
          disabled={!enabledNext}
          loading={loadingNext}
          onClick={onNext}
          label={nextLabel}
          type="button"
        />
      )}

      {onSkip && (
        <Button
          disabled={!enabledSkip}
          ing={loadingNext}
          onClick={onSkip}
          label={skipLabel}
          type="button"
        />
      )}
    </div>
  </div>
)

export default StepComponent

StepComponent.propTypes = {
  nextLabel: PropTypes.string,
  backLabel: PropTypes.string,
  skipLabel: PropTypes.string,
  enabledNext: PropTypes.bool,
  enabledSkip: PropTypes.bool,
  children: PropTypes.node,
  loadingNext: PropTypes.bool,
  loadingBack: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onSkip: PropTypes.func,
}
