import { createContext } from 'react'
import { action, observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  users: [],
  lastUpdated: null,
}

const TYPES = {
  users: observable,
  lastUpdated: observable,
}

export class UserStore extends BaseStore {
  setUsers = action((users) => {
    this.users = users
    this.lastUpdated = new Date()
  })
}

export const users = new UserStore(INITIAL_STATE, TYPES)
export const UserStoreContext = createContext(users)
