import * as Sentry from '@sentry/browser'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { action, computed, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

// Service & Utils
// eslint-disable-next-line import/no-cycle
import { getUser, updateUser } from '../services/user.service'
import { getErrorMessage } from '../utils/helpers'

const INITIAL_STATE = {
  accessToken: '',
  refreshToken: '',
  user: {},
  loading: false,
  lastUpdated: null,
  error: null,
}

const TYPES = {
  accessToken: observable,
  refreshToken: observable,
  user: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
  isAuthenticated: computed,
}

export class UserStore extends BaseStore {
  get isAuthenticated() {
    return this.accessToken !== ''
  }

  get isAdminUser() {
    if (this.user?.id) {
      return this.user.role === 'Admin'
    }
    return null
  }

  get isLimitedUser() {
    if (this.user?.id) {
      return this.user.role === 'Limited User'
    }
    return null
  }

  /**
   * Updates the stored user data.
   * @param {object} payload
   */
  updateUser = action(async (payload) => {
    this.loading = true
    this.error = null

    try {
      const currentUser = await updateUser({ ...user, ...payload })
      this.user = currentUser

      Sentry.setUser({
        ...currentUser,
      })

      this.lastUpdated = new Date()
      this.loading = false
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  /**
   * Gets updated user data with the specified `id`.
   * @param {string} id
   */
  getUpdatedUser = action(async (id) => {
    this.loading = true
    this.error = null

    let userId = id || this.user?.id
    if (!userId) {
      if (this.accessToken) {
        const decoded = jwt_decode(this.accessToken)
        userId = decoded.user_id
      } else {
        this.loading = false
        return
      }
    }

    try {
      const currentUser = await getUser({ id: userId })
      this.user = currentUser

      Sentry.setUser({
        ...currentUser,
      })

      this.lastUpdated = new Date()
      this.loading = false
    } catch (err) {
      this.error = getErrorMessage(err)
      this.loading = false
    }
  })

  setCurrentTokens = action(({ access, refresh }) => {
    this.accessToken = access
    this.refreshToken = refresh
  })

  setCurrentUser = action((user) => {
    this.user = {
      ...this.user,
      ...user,
    }
  })
}

export const user = new UserStore(INITIAL_STATE, TYPES)
export const UserStoreContext = createContext(user)
