import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

// Components
import { ClientHeader } from '../../components/ClientHeader'
import { CustomLink } from '../../components/CustomLink'
import { DataTable } from '../../components/DataTable'
import { NoDataPrompt } from '../../components/NoDataPrompt'
import { StateContainer } from '../../components/StateContainer'
import { Tooltip } from '../../components/Tooltip'
import ActivityLogPreview from './ActivityLogPreview'

// Store
import { DashboardStoreContext } from '../../stores/DashboardStore'
import { TaskStoreContext } from '../../stores/TaskStore'

// Services
import { getActivityLogPreview } from '../../services/activityLog.service'
import { getClient } from '../../services/clients.service'
import { getReports } from '../../services/reports.service'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import { PageContainer } from '../../components/PageContainer'
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

/**
 *
 * Client Dashboard
 *
 */
const ClientDashboard = observer(() => {
  // Context
  const { canModifyData } = useContext(ClientDashboardStoreContext)
  const { reports, setReports } = useContext(DashboardStoreContext)
  const { resetRefresh, triggerRefresh } = useContext(TaskStoreContext)
  const { clientId } = useParams()
  const navigate = useNavigate()

  // State
  const [loadingReports, setLoadingReports] = useState(true)
  const [loadingClient, setLoadingClient] = useState(true)
  const [error, setError] = useState(null)
  const [updatedReports, setUpdatedReports] = useState(reports)
  const [client, setClient] = useState(null)
  const [activityLogPreview, setActivityLogPreview] = useState([])
  const [activityLogError, setActivityLogError] = useState(null)

  // Pagination and Sorting
  const { pagination, setTotalRecords } = usePagination()
  const { sorting } = useSorting('created_at')
  const { perPage, currentPage } = pagination
  const { sortedColumn } = sorting

  // Constants
  const REPORTS_BASE_URL = `/clients/${clientId}/reports/?expand=generated_by`

  /**
   * Gets the updated client and report list.
   */
  const getUpdatedData = async () => {
    const [updatedClient, activityLogList] = await Promise.all([
      getClient(clientId, setError, setLoadingClient),
      getActivityLogPreview(clientId, setActivityLogError),
      getUpdatedReportList(`${REPORTS_BASE_URL}&limit=${perPage}`),
    ])

    setClient(updatedClient)
    setActivityLogPreview(activityLogList)
  }

  useEffect(() => {
    if (clientId) {
      getUpdatedData()
    }
  }, [clientId])

  /**
   * Gets the updated list of reports; updates pagination.
   * @param {string} url
   * @returns list of results
   */
  const getUpdatedReportList = async (url) => {
    const response = await getReports(url, setError, setLoadingReports, () => {})

    if (response) {
      setTotalRecords(response.count)
      setUpdatedReports(response.results)
      setReports(response.results)
    }
  }

  /**
   * When the visibility or row count changes, get the updated list of reports.
   */
  useEffect(() => {
    if (clientId) {
      getUpdatedReportList(`${REPORTS_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}`)
    }
  }, [perPage, clientId, sortedColumn])

  useEffect(() => {
    if (triggerRefresh) {
      resetRefresh()
      getUpdatedData()
    }
  }, [triggerRefresh])

  /**
   * Renders the error list for the report.
   * @param {object} report
   */
  const getFileErrorList = (report) => {
    const errorList = []
    if (report?.outputFileStatus === 'Failed') {
      errorList.push(`Output File: ${report?.outputFileStatusMessage}`)
    }
    if (report?.verifierFileStatus === 'Failed') {
      errorList.push(`Verifier File: ${report?.verifierFileStatusMessage}`)
    }
    if (report?.gipsFileStatus === 'Failed') {
      errorList.push(`GIPS Report: ${report?.gipsFileStatusMessage}`)
    }
    if (report?.qcOutputFileStatus === 'Failed') {
      errorList.push(`QC Output File: ${report?.qcOutputFileStatusMessage}`)
    }

    return (
      <ul className="list-disc">
        {errorList.map((e) => (
          <li key={e} style={{ marginLeft: '15px' }}>
            {e}
          </li>
        ))}
      </ul>
    )
  }

  /**
   * Renders the status of the report, with a tooltip if the report failed.
   * @param {string} status
   * @param {string} statusMessage
   */
  const renderStatusCell = (row) => {
    const { status, statusMessage } = row

    // Failed or Partially Failed statuses display a badge and a tooltip
    if (status === 'Failed' || status === 'Partially Failed') {
      return (
        <div className="flex flex-row items-center gap-2">
          <span className="inline-flex rounded-full bg-red-50 px-2 text-center text-xs font-semibold leading-5 text-red-600">
            {status}
          </span>

          <Tooltip
            content={
              <div className="w-[280px] rounded-lg bg-white p-4 shadow-lg ring-1 ring-black/5">
                <span className="text-sm font-medium">
                  {statusMessage || 'No failure message provided'}
                </span>
                <span>{getFileErrorList(row)}</span>
              </div>
            }
            placement="bottom-end"
          >
            <ChatBubbleBottomCenterTextIcon className="h-5 text-black sm:h-6" />
          </Tooltip>
        </div>
      )
    }

    // All other statuses display a badge with a color based on the status
    let extraClass = 'bg-gray-100 text-grey-800'
    if (status === 'Pending') {
      extraClass = 'bg-yellow-100 text-yellow-800'
    } else if (
      status === 'Processing Data' ||
      status === 'Ready for Report Generation' ||
      status === 'Preparing Reports'
    ) {
      extraClass = 'bg-blue-100 text-blue-800'
    } else if (status === 'Complete') {
      extraClass = 'bg-green-100 text-green-800'
    }

    return (
      <span
        className={`inline-flex rounded-full px-2 text-center text-xs font-semibold leading-5 ${extraClass}`}
      >
        {status}
      </span>
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />
          <div className="flex w-full flex-col gap-y-8 overflow-y-auto px-4 pb-12 pt-6 sm:gap-x-8 sm:px-6 md:flex-row lg:px-8">
            <div className="md:w-2/3">
              <div className="pb-5 sm:flex sm:items-center sm:justify-between">
                <h2 className="text-xl font-semibold leading-6 text-gray-900">Report History</h2>
                <div className="mt-3 sm:ml-4 sm:mt-0">
                  {client?.latestSuccessfulImport !== null && updatedReports.length > 0 && (
                    <CustomLink
                      disabled={!canModifyData}
                      className="bg-green-50 text-green-700 hover:bg-green-100"
                      to={`/clients/${client?.id}/new-report`}
                    >
                      Create New Report
                    </CustomLink>
                  )}
                </div>
              </div>

              {(() => {
                if (client?.latestSuccessfulImport === null) {
                  // No portfolio data uploaded
                  return (
                    <NoDataPrompt
                      path={`/clients/${client?.id}/new-import`}
                      title="No Portfolio Data Available"
                      subtitle={`Please ${
                        client?.pendingDataImport ? 'finish creating' : 'create'
                      } portfolio data before generating a report.`}
                      linkText={client?.pendingDataImport ? 'View Pending Import' : 'Import Data'}
                    />
                  )
                }

                if (client?.latestSuccessfulImport !== null && updatedReports?.length === 0) {
                  // Data uploaded but no reports generated yet
                  return (
                    <NoDataPrompt
                      path={`/clients/${client?.id}/new-report`}
                      title="No Reports"
                      subtitle="Data has been uploaded. Get started by creating your first report."
                      linkText="Create New Report"
                    />
                  )
                }

                // Show DataTable if there are reports
                return (
                  <DataTable
                    columns={[
                      {
                        field: 'createdAt',
                        header: 'Created At',
                        body: (row) => (
                          <Link to={`/clients/${clientId}/reports/${row.id}/`}>
                            {dayjs(row.createdAt).format('MM/DD/YYYY HH:mm A')}
                          </Link>
                        ),
                        sortable: true,
                        sortBy: 'created_at',
                        style: { minWidth: '130px' },
                      },
                      {
                        field: 'generatedBy',
                        header: 'Generated By',
                        body: (row) =>
                          `${row.generatedBy.firstName} ${row.generatedBy.lastName} (${row.generatedBy.email})`,
                        sortable: true,
                        sortBy: 'generated_by__first_name,generated_by__last_name',
                        style: { minWidth: '130px' },
                      },
                      {
                        field: 'status',
                        header: 'Status',
                        body: (row) => renderStatusCell(row),
                        sortable: true,
                        width: '220px',
                        center: 'true',
                        style: { minWidth: '130px' },
                      },
                    ]}
                    data={updatedReports}
                    loading={loadingReports}
                    perPage={perPage}
                    pagination={pagination}
                    sorting={sorting}
                    page={currentPage}
                    onRowClick={(row) => navigate(`/clients/${clientId}/reports/${row.data.id}`)}
                  />
                )
              })()}
            </div>

            <ActivityLogPreview activity={activityLogPreview} error={activityLogError} />
          </div>
        </div>
      </StateContainer>
    </PageContainer>
  )
})

export default ClientDashboard
