/** @type {import('tailwindcss').Config} */

import tailwindForms from '@tailwindcss/forms'

export default {
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/primereact/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    fontFamily: {
      poppins: ['Poppins', 'sans-serif'],
    },
    extend: {
      animation: {
        'spin-slow': 'spin 2s linear infinite',
      },
      colors: {
        gray: {
          50: '#f5f5f6',
          100: '#e6e6e7',
          200: '#cfd0d2',
          300: '#adafb3',
          400: '#8a8c91',
          500: '#696b71',
          600: '#5a5c60',
          700: '#4d4e51',
          800: '#434447',
          900: '#3b3b3e',
          950: '#252527',
          light: '#cfd0d2',
          DEFAULT: '#8a8c91',
          dark: '#3b3b3e',
        },
        purple: {
          100: '#F2ECFF',
          200: '#E0D0FF',
          300: '#D5C1FB',
          400: '#C2A3FF',
          500: '#B189FF',
          600: '#A071FC',
          700: '#8C56F6',
          800: '#8048ED',
          900: '#6F3AD8',
          light: '#A071FC',
          DEFAULT: '#8C56F6',
          dark: '#4E259D',
        },
        teal: {
          100: '#F1FFFC',
          200: '#E9FFFB',
          300: '#DCFFF8',
          400: '#CCFFF5',
          500: '#B5FBED',
          600: '#93F7E2',
          700: '#6EE5CD',
          800: '#53DCC0',
          900: '#46C5AB',
          light: '#93F7E2',
          DEFAULT: '#6EE5CD',
          dark: '#2AA48B',
        },
        blue: {
          50: '#f1f8fe',
          100: '#e2f1fc',
          200: '#c4dcec',
          300: '#83caf6',
          400: '#42afee',
          500: '#1996de',
          600: '#0c77bd',
          700: '#0b5f99',
          800: '#0d5484',
          900: '#114469',
          950: '#0b2b46',
          light: '#3576A3',
          DEFAULT: '#114469',
          dark: '#0b2b46',
        },
        dark: {
          100: '#ECE9EC',
          200: '#C6C2C6',
          300: '#B3ADB3',
          400: '#8B868B',
          500: '#746E74',
          600: '#625C62',
          700: '#423C42',
          800: '#2F2A2F',
          900: '#211D21',
          light: '#625C62',
          DEFAULT: '#0D0B0D',
          dark: '#0D0B0D',
        },
        green: {
          50: '#eefbf5',
          100: '#d6f5e5',
          200: '#b0eacf',
          300: '#7cd9b4',
          400: '#46c194',
          500: '#24ab7e',
          600: '#158663',
          700: '#116b51',
          800: '#105542',
          900: '#0e4637',
          950: '#072720',
          light: '#b0eacf',
          DEFAULT: '#24ab7e',
          dark: '#072720',
        },
        pink: {
          DEFAULT: '#FFBABA',
        },
        seafoam: {
          hover: '#59E0D199',
          DEFAULT: '#59E0D1',
        },
        sand: '#FEF9F1',
        red: {
          50: '#fff2f1',
          100: '#ffe2e0',
          200: '#ffcbc7',
          300: '#ffa7a0',
          400: '#ff7569',
          500: '#f94839',
          600: '#e72b1b',
          700: '#c22113',
          800: '#a01f14',
          900: '#852017',
          950: '#480c07',
          light: '#ffcbc7',
          DEFAULT: '#f94839',
          dark: '#480c07',
        },
        white: '#ffffff',
        warning: {
          light: '#FFFBEB',
          DEFAULT: '#B45309',
          dark: '#92400E',
          icon: '#FBBF24',
        },
        error: {
          light: '#FEF2F2',
          DEFAULT: '#B91C1C',
          dark: '#991B1B',
          icon: '#F87171',
        },
        info: {
          light: '#bee2f9',
          DEFAULT: '#0f5483',
          icon: '#0c2b45',
        },
        success: {
          light: '#b0eacf',
          DEFAULT: '#24ab7e',
        },
        background: {
          DEFAULT: '#FFFFFF',
        },
      },
      fontSize: {
        base: 14,
      },
      screens: {
        tall: { raw: '(min-height: 700px)' },
        short: { raw: '(max-height: 700px)' },
      },
    },
  },
  // eslint-disable-next-line global-require
  plugins: [tailwindForms],
  variants: {
    extend: {
      backgroundColor: ['responsive', 'hover', 'focus'],
      textColor: ['responsive', 'hover', 'focus'],
    },
  },
  // Add this configuration
  layers: [
    'tailwind-base',
    'primereact',
    'tailwind-components',
    'tailwind-variants',
    'tailwind-utils',
  ],
}
