import React from 'react'
import PropTypes from 'prop-types'

import { twMerge as mergeClassNames } from 'tailwind-merge'

const PageContainer = ({ children, withPadding }) => (
  <div
    className={mergeClassNames(
      'flex size-full flex-col overflow-y-auto rounded-tl bg-background',
      withPadding ? 'px-4 pb-12 pt-6 sm:px-6 lg:px-8' : '',
    )}
  >
    {children}
  </div>
)

export default PageContainer

PageContainer.propTypes = {
  children: PropTypes.node,
  withPadding: PropTypes.bool,
}
