import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

// Components
import { Button } from '../../components/Button'
import { ClientHeader } from '../../components/ClientHeader'
import { CustomLink } from '../../components/CustomLink'
import {
  DateEditor,
  PercentageEditor,
  PriceEditor,
  StatusEditor,
  TextAreaEditor,
  TextEditor,
  renderSelectEditor,
  renderSelectFilter,
  renderStatusTag,
} from '../../components/CustomEditor'
import { DataTable, DEFAULT_FILTER_OPTIONS } from '../../components/DataTable'

import { PageContainer } from '../../components/PageContainer'
import { NoDataPrompt } from '../../components/NoDataPrompt'
import { StateContainer } from '../../components/StateContainer'
import { Toggle } from '../../components/Toggle'
import { Tooltip } from '../../components/Tooltip'

// Utils
import { formatCurrency } from '../../utils/formatters'

// Services
import {
  getClient,
  getClientPortfolioDataAccounts,
  getClientPortfolioDataComposites,
  getClientPortfolioDataPeriods,
  getClientPortfolioDataPortfolios,
} from '../../services/clients.service'
import {
  deletePortfolioData,
  getPortfolioData,
  updatePortfolioData,
} from '../../services/portfolio.service'

// Store
import { ClientDashboardStoreContext } from '../../stores/ClientDashboardStore'

// Utils
import {
  configureFiltersFromSearchParams,
  configureFilterQuery,
  hasAnyFilterSelected,
  toast,
} from '../../utils/helpers'

// Hooks
import { usePagination, useSorting } from '../../hooks/DataTableManagement'
import AddPortfolioDataModal from './AddPortfolioDataModal'

const DEFAULT_FILTERS = {
  account: { value: [] },
  composite: { value: [] },
  period: { value: [] },
  portfolio: { value: [] },
}

const Portfolio = () => {
  // Context
  const { clientId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { canModifyData, client, setClient } = useContext(ClientDashboardStoreContext)

  // Pagination
  const { pagination, setTotalRecords } = usePagination(50)
  const { perPage, currentPage } = pagination

  // Sorting
  const { sorting } = useSorting('period')
  const { sortedColumn } = sorting

  // State
  const [loadingPortfolioData, setLoadingPortfolioData] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [showAddRowModal, setShowAddRowModal] = useState(false)
  const [portfolioData, setPortfolioData] = useState([])
  const [loadingClient, setLoadingClient] = useState(true)
  const [error, setError] = useState(null)
  const [columns, setColumns] = useState([])
  const [dataEditable, setDataEditable] = useState(false)
  const [filters, setFilters] = useState(
    configureFiltersFromSearchParams(searchParams, DEFAULT_FILTERS),
  )
  const [accounts, setAccounts] = useState([])
  const [composites, setComposites] = useState([])
  const [periods, setPeriods] = useState([])
  const [portfolios, setPortfolios] = useState([])

  const dataTable = useRef()

  const PORTFOLIO_DATA_BASE_URL = `/clients/${clientId}/portfolio-data?expand=composite,account,portfolio`

  const handleSuccess = (m) => toast(m, 'success')
  const handleErrors = (m) => toast(m, 'error')

  useEffect(() => {
    const getClientData = async () => {
      const updatedClient = await getClient(clientId, setError, setLoadingClient)
      if (updatedClient) {
        setClient(updatedClient)
      }

      const [clientAccounts, clientComposites, clientPeriods, clientPortfolios] =
        await Promise.all([
          getClientPortfolioDataAccounts(clientId, handleErrors),
          getClientPortfolioDataComposites(clientId, handleErrors),
          getClientPortfolioDataPeriods(clientId, handleErrors),
          updatedClient.hasAnyHouseholdData &&
            getClientPortfolioDataPortfolios(clientId, handleErrors),
        ])

      const updatedAccounts = _.map(clientAccounts, (d) => ({
        ...d,
        label: `${d.name} (${d.number})`,
        value: d.id,
        id: d.id,
      }))

      const updatedComposites = _.map(clientComposites, (d) => ({
        ...d,
        label: d.name,
        value: d.id,
        id: d.id,
      }))

      const updatedPeriods = _.map(clientPeriods, (d) => ({
        label: dayjs(d).format('MM/DD/YYYY'),
        value: d,
        id: d,
      }))

      let updatedPortfolios = []
      if (updatedClient.hasAnyHouseholdData && clientPortfolios) {
        updatedPortfolios = _.map(clientPortfolios, (d) => ({
          ...d,
          label: `${d.name} (${d.number})`,
          value: d.id,
          id: d.id,
        }))
      }

      const DEFAULT_COLUMNS = [
        {
          field: 'composite.id',
          header: 'Composite',
          body: (rowData) => rowData.composite.name,
          sortField: 'composite__name',
          sortable: true,
          style: { minWidth: '250px' },
          filterField: 'composite',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              updatedComposites,
              options.rowData.composite.id,
              'Select a Composite',
              options,
            ),
          filterElement: (options) =>
            renderSelectFilter(
              updatedComposites,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  composite: {
                    value: selected,
                  },
                }))
              },
              'Filter by Composite',
              options,
            ),
        },
        {
          field: 'account.id',
          header: 'Account',
          body: (rowData) => `${rowData.account.name} (${rowData.account.number})`,
          sortField: 'account__name',
          sortable: true,
          style: { minWidth: '230px' },
          filterField: 'account',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              updatedAccounts,
              options.rowData.account.id,
              'Select an Account',
              options,
            ),
          filterElement: (options) =>
            renderSelectFilter(
              updatedAccounts,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  account: {
                    value: selected,
                  },
                }))
              },
              'Filter by Account',
              options,
            ),
        },
        {
          field: 'period',
          header: 'Period',
          sortable: true,
          body: (row) => dayjs(row.period).format('MM/DD/YYYY'),
          editor: DateEditor,
          style: { minWidth: '150px' },
          filterField: 'period',
          ...DEFAULT_FILTER_OPTIONS,
          filterElement: (options) =>
            renderSelectFilter(
              updatedPeriods,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  period: {
                    value: selected,
                  },
                }))
              },
              'Filter by Period',
              options,
            ),
        },
        {
          field: 'beginningValue',
          header: 'Beginning Value',
          sortable: true,
          editor: PriceEditor,
          body: (rowData) => formatCurrency(rowData.beginningValue),
          style: { minWidth: '200px' },
        },
        {
          field: 'endingValue',
          header: 'Ending Value',
          sortable: true,
          editor: PriceEditor,
          body: (rowData) => formatCurrency(rowData.endingValue),
          style: { minWidth: '200px' },
        },
        {
          field: 'grossReturn',
          header: 'Gross Return',
          sortable: true,
          editor: PercentageEditor,
          body: (rowData) =>
            rowData.grossReturn !== null ? `${(rowData.grossReturn * 100).toFixed(2)}%` : '-', // Display as percentage
          style: { minWidth: '180px' },
        },
        {
          field: 'netReturn',
          header: 'Net Return',
          sortable: true,
          editor: PercentageEditor,
          body: (rowData) =>
            rowData.netReturn !== null ? `${(rowData.netReturn * 100).toFixed(2)}%` : '-', // Display as percentage
          style: { minWidth: '180px' },
        },
        {
          field: 'isIncluded',
          header: 'Included',
          sortable: true,
          editor: StatusEditor,
          body: (rowData) => renderStatusTag(rowData.isIncluded),
          style: { minWidth: '160px' },
        },
        {
          field: 'isFeePaying',
          header: 'Fee Paying',
          sortable: true,
          editor: StatusEditor,
          body: (rowData) => renderStatusTag(rowData.isFeePaying),
          style: { minWidth: '160px' },
        },
        {
          field: 'hasBundledFee',
          header: 'Bundled Fee',
          sortable: true,
          editor: StatusEditor,
          body: (rowData) => renderStatusTag(rowData.hasBundledFee),
          style: { minWidth: '160px' },
        },
        {
          field: 'comments',
          header: 'Comments',
          editor: TextAreaEditor,
          style: { minWidth: '300px' },
        },
      ]

      let updatedColumns = DEFAULT_COLUMNS

      // Add columns for Portfolios to the second and third column if household data is available
      if (client?.hasAnyHouseholdData) {
        const HOUSEHOLD_COLUMNS = _.cloneDeep(DEFAULT_COLUMNS)
        HOUSEHOLD_COLUMNS.splice(1, 0, {
          field: 'portfolio.id',
          header: 'Portfolio',
          body: (rowData) => `${rowData.portfolio?.name} (${rowData.portfolio?.number})`,
          sortField: 'portfolio__name',
          sortable: true,
          style: { minWidth: '230px' },
          filterField: 'portfolio',
          ...DEFAULT_FILTER_OPTIONS,
          editor: (options) =>
            renderSelectEditor(
              updatedPortfolios,
              options.rowData.portfolio?.id,
              'Select a Portfolio',
              options,
            ),
          filterElement: (options) =>
            renderSelectFilter(
              updatedPortfolios,
              (selected) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  portfolio: {
                    value: selected,
                  },
                }))
              },
              'Filter by Portfolio',
              options,
            ),
        })

        updatedColumns = HOUSEHOLD_COLUMNS
      }

      // Check for additional columns on the client and dynamically update the columns for display
      if (client.portfolioDataAdditionalColumns) {
        const additionalColumns = client.portfolioDataAdditionalColumns.map((column) => ({
          field: `editableAdditionalData.${column}`,
          body: (row) => (row.additionalData ? row.additionalData[column] : ''),
          header: column,
          editor: TextEditor,
          style: { minWidth: '200px' },
        }))

        updatedColumns = [...updatedColumns, ...additionalColumns]
      }

      setAccounts(updatedAccounts)
      setComposites(updatedComposites)
      setPeriods(updatedPeriods)
      setPortfolios(updatedPortfolios)
      setColumns(updatedColumns)
    }

    getClientData()
  }, [clientId])

  /**
   * Gets the updated list of portfolio data; updates pagination.
   * @param {string} url
   */
  const getUpdatedPortfolioData = async (url) => {
    const response = await getPortfolioData(url, handleErrors, setLoadingPortfolioData)

    if (response) {
      setTotalRecords(response.count)
      setPortfolioData(
        _.map(response.results, (d) => ({
          ...d,
          editableAdditionalData: d.additionalData ? { ...d.additionalData } : null,
        })),
      )
    }
  }

  const debounceGetUpdatedPortfolioData = _.debounce(getUpdatedPortfolioData, 500)

  /**
   * When the filter, sort, current page, or row count changes, get the updated list of data.
   */
  useEffect(() => {
    if (clientId) {
      const queryFilters = configureFilterQuery({
        account: filters.account || DEFAULT_FILTERS.account,
        composite: filters.composite || DEFAULT_FILTERS.composite,
        period: filters.period || DEFAULT_FILTERS.period,
        portfolio: filters.portfolio || DEFAULT_FILTERS.portfolio,
      })

      debounceGetUpdatedPortfolioData(
        `${PORTFOLIO_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}&${queryFilters}`,
      )
    }
  }, [perPage, clientId, sortedColumn, currentPage, filters])

  /**
   * Handles row edit completion by updating the local `portfolioData` with new data and
   * submitting it to the backend. Converts `period` to ISO string if it's a Date object.
   * Sends the updated row to `updatePortfolioData` and updates the UI on success.
   * @param {object} event
   */
  const onRowEditComplete = async ({ newData, index }) => {
    const oldPortfolioData = [...portfolioData]
    const updatedData = [...portfolioData]

    if (newData.period instanceof Date) {
      const [date] = newData.period.toISOString().split('T')
      // eslint-disable-next-line no-param-reassign
      newData.period = date
    }

    // Update the composite, account and portfolio (if applicable) to update the stored values
    updatedData[index] = { ...newData, additionalData: newData.editableAdditionalData }
    updatedData[index].composite = composites.find((c) => c.id === newData.composite.id)
    updatedData[index].account = accounts.find((a) => a.id === newData.account.id)
    if (newData.portfolio) {
      updatedData[index].portfolio = portfolios.find((p) => p.id === newData.portfolio.id)
    }

    // Optimistically update the data to prevent showing internal account values
    setPortfolioData(updatedData)

    await updatePortfolioData(
      clientId,
      newData.id,
      {
        account: newData.account.id,
        composite: newData.composite.id,
        beginningValue: newData.beginningValue,
        endingValue: newData.endingValue,
        comments: newData.comments,
        grossReturn: newData.grossReturn,
        hasBundledFee: newData.hasBundledFee,
        isFeePaying: newData.isFeePaying,
        isIncluded: newData.isIncluded,
        netReturn: newData.netReturn,
        period: newData.period,
        portfolio: newData.portfolio?.id || null,
        additionalData: newData.editableAdditionalData,
      },
      (m) => {
        // Reset back to previous value
        setPortfolioData(oldPortfolioData)
        handleErrors(m)
      },
      () => {},
      (m) => handleSuccess(m),
    )
  }

  return (
    <PageContainer>
      <StateContainer error={error} loading={loadingClient}>
        <div className="size-full">
          <ClientHeader client={client} />

          <div className="flex h-[calc(100vh-150px)] w-full flex-col bg-white px-4 pb-12 pt-6 sm:px-6 lg:px-8">
            <div className="flex size-full flex-col space-y-6">
              <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center">
                <div className="flex flex-row items-center gap-2">
                  <h3 className="text-xl font-semibold leading-6 text-gray-900">Portfolio Data</h3>

                  {client?.latestSuccessfulImport !== null && (
                    <Tooltip
                      content={
                        <div className="w-[280px] rounded-lg bg-white p-2 shadow-lg ring-1 ring-black/5">
                          <span className="text-xs font-medium">
                            {client?.portfolioDataStatus === 'Import in Progress'
                              ? 'Cannot edit while import is in progress.'
                              : 'Cannot edit while running CTV6.'}
                          </span>
                        </div>
                      }
                      display={!canModifyData}
                      placement="bottom-end"
                    >
                      <Toggle
                        label="Enable Editing"
                        disabled={!canModifyData}
                        id="viewMyClients"
                        name="viewMyClients"
                        onChange={() => {
                          if (dataEditable) {
                            // Hack to click on all cancel buttons for rows that are in edit mode
                            const cancelButtons = document.querySelectorAll(
                              "button[name='row-cancel']",
                            )
                            cancelButtons.forEach((button) => button.click())
                          }

                          setDataEditable(!dataEditable)
                        }}
                        checked={dataEditable}
                      />
                    </Tooltip>
                  )}
                </div>

                {client?.latestSuccessfulImport !== null && (
                  <div className="mt-3 flex flex-row gap-2 sm:ml-4 sm:mt-0">
                    {hasAnyFilterSelected(filters) && (
                      <Button
                        label="Clear Filters"
                        background="bg-gray"
                        onClick={() => {
                          // Clear search params if there are any
                          if (_.keys(Object.fromEntries(searchParams)).length > 0) {
                            navigate(`/clients/${clientId}/portfolio-data`)
                          }

                          setFilters(DEFAULT_FILTERS)
                          getUpdatedPortfolioData(
                            `${PORTFOLIO_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                          )
                        }}
                      />
                    )}

                    {dataEditable && (
                      <Button label="Add Row" onClick={() => setShowAddRowModal(true)} />
                    )}

                    <CustomLink to={`/clients/${clientId}/new-import`}>
                      {client?.portfolioDataStatus === 'Import in Progress'
                        ? 'View Import'
                        : 'Import Data'}
                    </CustomLink>
                  </div>
                )}
              </div>

              {client?.latestSuccessfulImport === null ? (
                <NoDataPrompt
                  path={`/clients/${clientId}/new-import`}
                  title="No Portfolio Data Available"
                  subtitle={`Please ${
                    client?.pendingDataImport ? 'finish creating' : 'create'
                  } portfolio data before generating a report.`}
                  linkText={client?.pendingDataImport ? 'View Pending Import' : 'Import Data'}
                />
              ) : (
                <DataTable
                  data={portfolioData}
                  columns={columns}
                  loading={loadingPortfolioData}
                  rowsPerPageOptions={[50, 100, 500, 1000]}
                  pagination={pagination}
                  sorting={sorting}
                  loadingDelete={loadingDelete}
                  onRowEditComplete={onRowEditComplete}
                  onRowDeleteConfirm={(row) => {
                    deletePortfolioData(clientId, row.id, handleErrors, setLoadingDelete, () => {
                      handleSuccess('Portfolio data row deleted.')
                      getUpdatedPortfolioData(
                        `${PORTFOLIO_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
                      )
                    })
                  }}
                  areRowsDeleteable={dataEditable}
                  areRowsEditable={dataEditable}
                  hoverEffect={false}
                  rowDeleteConfirm={(row) => ({
                    title: `Delete Portfolio Data Row`,
                    type: 'warning',
                    message: (
                      <div className="flex flex-col">
                        <span className="">Are you sure you want to delete the row for:</span>
                        <div className="mt-1.5 flex gap-1">
                          Composite:
                          <span className="font-semibold">{row.composite.name}</span>
                        </div>

                        <div className="flex gap-1">
                          Account:
                          <span className="font-semibold">
                            {row.account.name} ({row.account.number})
                          </span>
                        </div>

                        <div className="flex gap-1">
                          Period:
                          <span className="font-semibold">{row.period}</span>
                        </div>
                      </div>
                    ),
                  })}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  ref={dataTable}
                />
              )}
            </div>
          </div>
        </div>
      </StateContainer>

      {showAddRowModal && (
        <AddPortfolioDataModal
          accounts={accounts}
          client={client}
          composites={composites}
          closeModal={() => setShowAddRowModal(false)}
          onSuccess={() => {
            setShowAddRowModal(false)
            getUpdatedPortfolioData(
              `${PORTFOLIO_DATA_BASE_URL}&order_by=${sortedColumn}&limit=${perPage}&page=${currentPage}`,
            )
          }}
          periods={periods}
          portfolios={portfolios}
        />
      )}
    </PageContainer>
  )
}

export default Portfolio
